import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import Helmet from 'react-helmet';
import utils from '../utils/utils';
import { useItemFirstLevelCategories } from '../shop/apiHooks';

const CtaGroupsPage = ({ intl, match, history, currentUserLoading }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.category]);

  const {
    isLoading: categoriesLoading,
    isError: isCategoriesError,
    error: categoriesError,
    categories,
  } = useItemFirstLevelCategories({ currentUserLoading });

  const redirectToNewShopPage = () => {
    history.push({
      pathname: '/shop',
    });
  };

  useEffect(() => {
    if (categories?.length) {
      if (match.params.category) {
        const matchingFirstLevelCategory = categories.find((cat) =>
          cat.secondLevelCategories.some((secondCat) => secondCat.oldCategoryUrl === match.params.category)
        );

        const matchingSecondLevelCategory = matchingFirstLevelCategory?.secondLevelCategories.find(
          (secondCat) => secondCat.oldCategoryUrl === match.params.category
        );

        if (matchingFirstLevelCategory && matchingSecondLevelCategory) {
          history.push({
            pathname: `/shop/${utils.convertToUrlString(matchingFirstLevelCategory.name)}/${utils.convertToUrlString(
              matchingSecondLevelCategory.name
            )}`,
          });
          return;
        }
      }

      redirectToNewShopPage();
    }
    // eslint-disable-next-line
  }, [history, categories]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'cta.groups.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'cta.groups.helmet.description' })} />
      </Helmet>

      <div className="container p-t-6">
        <Loader show={categoriesLoading} />
        {isCategoriesError ? <ErrorMessage error={utils.getErrorMessageFromResponse(categoriesError)} /> : ''}

        <h2>
          <FormattedMessage id="cta.groups.new.shop.url" />
        </h2>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentUserLoading: state.currentUser.loading,
  };
}

export default injectIntl(connect(mapStateToProps)(CtaGroupsPage));
