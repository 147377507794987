import React from 'react';
import mastercard from '../assets/icons/payment/card-mastercard.svg';
import visa from '../assets/icons/payment/card-visa.svg';
import other from '../assets/icons/payment/card-other.svg';
import { FormattedMessage } from 'react-intl';

const PaymentMethodCard = ({ paymentMethod, hidePrimaryNotice }) => {
  const icon =
    paymentMethod.brand === 'mastercard' ? (
      <img src={mastercard} alt="mastercard" />
    ) : paymentMethod.brand === 'visa' ? (
      <img src={visa} alt="visa" />
    ) : (
      <img src={other} alt="other card" />
    );

  return (
    <div className="payment-method m-b-1">
      <strong>
        {icon} •••• {paymentMethod.last4}
      </strong>{' '}
      {paymentMethod.isPrimary && !hidePrimaryNotice ? (
        <small className="text-success">
          <FormattedMessage id="payment.method.primary" />
        </small>
      ) : (
        ''
      )}
    </div>
  );
};

export default PaymentMethodCard;
