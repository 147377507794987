import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import image from '../landing/assets/blog/viljad.png';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const BlogFruitsFromSpain = ({ intl }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paragraphs = [
    {
      description: 'fruits.from.spain.p.1',
    },
    {
      description: 'fruits.from.spain.p.2',
    },
    {
      description: 'fruits.from.spain.p.3',
    },
    {
      description: 'fruits.from.spain.p.4',
    },
    {
      description: 'fruits.from.spain.p.5',
    },
  ];

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'fruits.from.spain.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'fruits.from.spain.helmet.description' })} />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="fruits.from.spain.title" />
          </h1>

          <div className="row">
            <div className="col-10 col-sm-8 m-b-3">
              <img src={image} alt="" className="img-fluid" />
            </div>
          </div>

          {paragraphs.map((paragraph, index) => (
            <div key={index}>
              {paragraph.title ? (
                <div className="text-primary">
                  <strong>
                    <FormattedMessage id={paragraph.title} />
                  </strong>
                  <br />
                </div>
              ) : (
                ''
              )}
              <p>
                <FormattedMessage id={paragraph.description} />
              </p>
            </div>
          ))}

          <div className="text-center">
            <Link to="/shop/fruits_vegetables/fruit" className="btn btn-primary">
              <FormattedMessage id="fruits.from.spain.cta" />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

BlogFruitsFromSpain.propTypes = {};

BlogFruitsFromSpain.defaultProps = {};

export default injectIntl(BlogFruitsFromSpain);
