import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMinusCircle, faPlusCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import {
  addItemToCart,
  createPriceQuote,
  removeItemFromCart,
  removeMarketplaceFromCart,
  resetCart,
} from '../cart/cartActions';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import { getDeliveryDayMarketplaces } from '../deliveryDayMarketplaces/deliveryDayMarketplacesActions';
import classNames from 'classnames';
import PREFERRED_PRICE_DISPLAY from '../components/constants/preferredPriceDisplay';
import ReactTooltip from 'react-tooltip';
import CartPriceQuoteRows from '../cart/cartPriceQuoteRows';

const NavbarRightCartPopup = ({
  cart,
  addItemToCart,
  removeItemFromCart,
  resetCart,
  deliveryDayMarketplaces,
  getDeliveryDayMarketplaces,
  removeMarketplaceFromCart,
  createPriceQuote,
  priceQuote,
  currentUser,
  intl,
  couponCode,
  address,
}) => {
  useEffect(() => {
    refreshDeliveryDaysIfNeeded();

    async function refreshDeliveryDaysIfNeeded() {
      const marketplaceIdsForDeliveryDayFetch = [];

      for (const cartOrder of cart) {
        const matchingDeliveryDay = deliveryDayMarketplaces.find(
          (ddm) => ddm.marketplaceId === cartOrder.marketplace.id
        );

        if (!matchingDeliveryDay || !matchingDeliveryDay.deliveryDayMarketplaces.length) {
          marketplaceIdsForDeliveryDayFetch.push(cartOrder.marketplace.id);
        }
      }

      if (!marketplaceIdsForDeliveryDayFetch.length) {
        return;
      }

      getDeliveryDayMarketplaces({ marketplaceIds: marketplaceIdsForDeliveryDayFetch.join(',') }, true)
        .then((ddms) => {
          for (const cartOrder of cart) {
            if (!ddms.some((dd) => dd.marketplaceId === cartOrder.marketplace.id)) {
              removeMarketplaceFromCart(cartOrder.marketplace);
            }
          }
        })
        .catch((error) => {
          resetCart();
        });
    }

    // don't add deliveryDayMarketplaces as a dependency below bc it'll cause an infinite loop on error
    // eslint-disable-next-line
  }, [cart, getDeliveryDayMarketplaces, removeMarketplaceFromCart]);

  useEffect(() => {
    const deliveryDays = utils.getDeliveryDaysFromCart(cart, deliveryDayMarketplaces);
    const totalSum = utils.getCartTotalPrice(cart);

    createPriceQuote({ totalSum, deliveryDays, couponCode, addressId: address?.id });
    // TODO figure out how to avoid multiple firing of price quote on ddm change
    // eslint-disable-next-line
  }, [cart, currentUser, couponCode, deliveryDayMarketplaces, address]);

  const totalSum = priceQuote ? priceQuote.finalSum : '-';

  const navButtonClasses = classNames({
    'btn-loading': !priceQuote,
    'btn btn-small btn-primary navbar-button-link m-r-0 navbar__right-link-element': true,
  });

  const { formatMessage } = useIntl();

  return cart && cart.length ? (
    <li className="navbar__right-link m-hover-cart-popup list-inline-item">
      <Link to="/ostukorv" className={navButtonClasses}>
        <FontAwesomeIcon icon={faShoppingCart} />{' '}
        <FormattedMessage
          id={'navbar.links.cart'}
          values={{
            amount: totalSum,
          }}
        />
      </Link>
      <div className="navbar__right-link-popup m-cart-popup">
        <h3 className="m-b-3">
          <FormattedMessage id="navbar.links.cart.popup.header" />
        </h3>
        {cart.map((cartOrder, index) => {
          return (
            <div key={index} className="m-b-3">
              <h4 className="m-b-2">{cartOrder.marketplace.name}</h4>
              {cartOrder.orderItems.map((orderItem, index) => {
                const itemImage = orderItem.item.images.length ? (
                  <img
                    className="navbar__right-link-cart-popup-item-image"
                    src={orderItem.item.images[0].thumbnailImage}
                    alt=""
                  />
                ) : (
                  ''
                );

                const showUnitTooltip =
                  orderItem.item.priceFields.unitPrice &&
                  orderItem.item.priceFields.preferredPriceDisplay === PREFERRED_PRICE_DISPLAY.unitPrice;

                return (
                  <div className="row m-b-1" key={index}>
                    <div className="col-7">
                      {itemImage} {utils.getItemName(orderItem.item, formatMessage)}
                    </div>
                    <div className="col-2 p-a-0">
                      <span
                        className="navbar__right-link-cart-popup-item-control m-l-1"
                        onClick={() => removeItemFromCart(orderItem.item, cartOrder.marketplace.id)}
                      >
                        <FontAwesomeIcon size="xs" icon={faMinusCircle} />
                      </span>{' '}
                      {orderItem.quantity}{' '}
                      <span
                        className="navbar__right-link-cart-popup-item-control"
                        onClick={() => addItemToCart(orderItem.item, cartOrder.marketplace)}
                      >
                        <FontAwesomeIcon size="xs" icon={faPlusCircle} />
                      </span>
                    </div>
                    <div className="col-3 text-right">
                      <strong>{utils.getRoundedPrice(orderItem.item.priceFields.price * orderItem.quantity)}€</strong>{' '}
                      {showUnitTooltip ? (
                        <span
                          className="text-primary"
                          data-tip={intl.formatMessage({ id: 'cart.item.price.unit.explanation' })}
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                          <ReactTooltip place="top" type="dark" effect="float" />
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

        <hr />

        <CartPriceQuoteRows />
        {priceQuote ? (
          <Link to="/ostukorv" className="btn btn-primary btn-block">
            <FormattedMessage
              id={'navbar.links.cart.popup.cta'}
              values={{
                amount: totalSum,
              }}
            />
          </Link>
        ) : (
          ''
        )}

        <div className="navbar__right-link-popup-arrow" />
      </div>
    </li>
  ) : (
    <></>
  );
};

NavbarRightCartPopup.propTypes = {};

NavbarRightCartPopup.defaultProps = {};

const mapDispatchToProps = {
  resetCart,
  addItemToCart,
  removeItemFromCart,
  createPriceQuote,
  getDeliveryDayMarketplaces,
  removeMarketplaceFromCart,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    priceQuote: state.cart.priceQuote,
    cart: state.cart.cart,
    address: state.cart.address,
    couponCode: state.cart.couponCode,
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NavbarRightCartPopup));
