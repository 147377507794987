import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const OrderInvoicePaymentStatus = ({ invoicePaymentStatus }) => {
  return (
    <div>
      <small>
        <FontAwesomeIcon icon={faReceipt} />{' '}
        <FormattedMessage id={`my.orders.order.invoice.payment.status.${invoicePaymentStatus}`} />
      </small>
    </div>
  );
};

export default OrderInvoicePaymentStatus;
