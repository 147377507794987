import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loader from './loader';
import CtaGroupItems from '../ctaGroup/ctaGroupItems';
import { getMarketplaceItems } from '../ctaGroup/marketplaceItemActions';

const ExpandedMarketplacePreview = ({ marketplace, marketplaceItems, loading, getMarketplaceItems }) => {
  const imageStyle = {
    backgroundImage: 'url(' + marketplace.image.thumbnailImage + ')',
  };

  const marketplaceItem = marketplaceItems.find((mi) => mi.marketplaceId === marketplace.id);

  const fetchMoreItems = () => {
    if (marketplaceItem && !marketplaceItem.hasAllItems) {
      getMarketplaceItems({ marketplaceId: marketplace.id }, true);
    }
  };

  return (
    <div className="cta-group__marketplace m-b-6">
      <Link to={`/tootja/${marketplace.id}`}>
        <div className="cta-group__marketplace-top m-b-6">
          <div className="cta-group__marketplace-top-image m-b-1" style={imageStyle} />
          <div className="cta-group__marketplace-top-content">
            <h3 className="cta-group__marketplace-top-content-title m-b-2">{marketplace.name}</h3>
          </div>
        </div>
      </Link>

      <Loader show={loading} />

      {marketplaceItem ? (
        <CtaGroupItems items={marketplaceItem.items} fetchMoreItems={fetchMoreItems} expandedMarketplace={true} />
      ) : (
        ''
      )}

      <div>
        <Link to={`/tootja/${marketplace.id}`} className="btn btn-primary btn-small">
          <FormattedMessage
            id="cta.group.marketplace.item.collections.cta"
            values={{
              marketplaceName: marketplace.name,
            }}
          />
        </Link>
      </div>

      <hr className="m-t-6 m-b-6" />
    </div>
  );
};

ExpandedMarketplacePreview.propTypes = {
  marketplace: PropTypes.object.isRequired,
};

ExpandedMarketplacePreview.defaultProps = {};

function mapStateToProps(state) {
  return {
    loading: state.marketplaceItems.loading,
    error: state.marketplaceItems.error,
    marketplaceItems: state.marketplaceItems.marketplaceItems,
  };
}

const mapDispatchToProps = {
  getMarketplaceItems: getMarketplaceItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExpandedMarketplacePreview));
