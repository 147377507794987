import React, {useEffect} from 'react';
import landingCircleBottomLeft from "./assets/landing-1-circle-bottom-left.svg";
import landingCircleTopLeft from "./assets/landing-1-circle-top-left.svg";
import landingCirclePurple from "./assets/landing-1-circle-purple.svg";
import landingCircleGreen from "./assets/landing-1-circle-green.svg";
import landingCircleBottomRight from "./assets/landing-1-circle-bottom-right.svg";
import {FormattedMessage} from "react-intl";

const WinterSwim = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="ios">
      <div className="container-fluid ios__first">
        <img className="ios__first-circle-bottom-left" src={landingCircleBottomLeft} alt=""/>
        <img className="ios__first-circle-top-left" src={landingCircleTopLeft} alt=""/>
        <img className="ios__first-circle-purple" src={landingCirclePurple} alt=""/>
        <img className="ios__first-circle-green" src={landingCircleGreen} alt=""/>
        <img className="ios__first-circle-bottom-right" src={landingCircleBottomRight} alt=""/>

        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1  col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 text-center">
            <div className="ios__first-content">
              <h1>
                <FormattedMessage id={'winter.swim.title'}/>
              </h1>
              <p className="text-large">
                <FormattedMessage id={'winter.swim.description'}/>
              </p>
              <p>
                <FormattedMessage id={'winter.swim.payment'}/>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 m-b-4 text-center">
          <div className="ios__form">
            <iframe
              title="winter-swim-form"
              src="https://docs.google.com/forms/d/e/1FAIpQLSdSc45VdL5f6Hf025CkQQnE-k-U06uGFBds47DqwUTxABG7_A/viewform?embedded=true"
              width="100%" height="900px" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
            </iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

WinterSwim.propTypes = {};

WinterSwim.defaultProps = {};

export default WinterSwim;
