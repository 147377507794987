import React, { useEffect } from 'react';
import { Circle, GoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';

const containerStyle = {
  width: '100%',
  height: '500px',
  borderRadius: '20px',
};

const center = {
  lat: 58.6942,
  lng: 24.9077,
};

const DEFAULT_MAP_ZOOM = 7.8;

const LandingMap = ({ serviceAreas }) => {
  const [map, setMap] = React.useState(null);

  useEffect(() => {
    if (map) {
      setDefaultMapZoom();
    }
    // eslint-disable-next-line
  }, [map, serviceAreas]);

  const setDefaultMapZoom = () => {
    if (serviceAreas?.length && map) {
      const bounds = new window.google.maps.LatLngBounds();
      serviceAreas.forEach((serviceArea) =>
        serviceArea.distancePoints.forEach((dp) => {
          bounds.extend({
            lat: Number(dp.coordinates.lat),
            lng: Number(dp.coordinates.lon),
          });
        })
      );
      map.fitBounds(bounds);
    } else {
      map.panTo(center);
      map.setZoom(DEFAULT_MAP_ZOOM);
    }
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  let circles = [];

  if (serviceAreas) {
    serviceAreas.forEach((serviceArea) => {
      circles = circles.concat(
        serviceArea.distancePoints.map((dp) => {
          const options = {
            strokeOpacity: 0,
            strokeWeight: 0,
            fillColor: '#FF957D',
            fillOpacity: 0.4,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            radius: dp.radius * 1000,
            zIndex: 1,
          };

          return (
            <Circle
              key={dp.id}
              options={options}
              center={{
                lat: Number(dp.coordinates.lat),
                lng: Number(dp.coordinates.lon),
              }}
            />
          );
        })
      );
    });
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={DEFAULT_MAP_ZOOM}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      <>{circles}</>
    </GoogleMap>
  );
};

LandingMap.propTypes = {
  serviceAreas: PropTypes.array,
};

LandingMap.defaultProps = {
  serviceAreas: [],
};

export default LandingMap;
