import React, { useState } from 'react';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import Form from 'react-jsonschema-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login } from '../user/currentUserActions';
import apiCalls from '../utils/apiCalls';
import classNames from 'classnames';
import utils from '../utils/utils';
import EVENT_NAME from '../components/constants/eventName';

function SignupForm({ history, login, onSuccess, redirectUrl, errorMessage, loading, intl }) {
  const { formatMessage } = useIntl();

  const [signupData, setSignupData] = useState({
    email: '',
    password: '',
  });

  const [signupError, setSignupError] = useState(null);

  const onSubmit = (data) => {
    apiCalls
      .register(data.formData)
      .then((response) => {
        utils.trackEvent(EVENT_NAME.register, {
          id: response.data.user.id,
        });
        return login(
          {
            username: signupData.email,
            password: signupData.password,
          },
          formatMessage
        );
      })
      .then((response) => {
        history.push({
          pathname: redirectUrl,
        });
        localStorage.setItem('hasLoggedIn', true);
        onSuccess();
      })
      .catch((error) => {
        setSignupError(utils.getErrorMessageFromResponse(error, true));
        window.scrollTo(0, 0);
      });
  };

  const error =
    errorMessage || signupError ? (
      <div className="row m-t-5 m-b-5">
        <div className="col-12">
          <div className="alert alert-error">{errorMessage || signupError}</div>
        </div>
      </div>
    ) : (
      ''
    );

  const schema = {
    type: 'object',
    required: [],
    properties: {
      email: {
        type: 'string',
        title: intl.formatMessage({ id: 'sign.up.form.field.email.label' }),
      },
      screenName: {
        type: 'string',
        title: intl.formatMessage({ id: 'sign.up.form.field.screen.name.label' }),
      },
      password: {
        type: 'string',
        title: intl.formatMessage({ id: 'sign.up.form.field.password.label' }),
      },
    },
  };

  const uiSchema = {
    password: {
      'ui:widget': 'password',
    },
  };

  const buttonClasses = classNames({
    'btn-loading': loading,
    'btn btn-primary': true,
  });

  return (
    <div className="signup-form">
      {error}

      <Form
        schema={schema}
        uiSchema={uiSchema}
        noHtml5Validate={true}
        onChange={(event) => setSignupData(event.formData)}
        formData={signupData}
        onSubmit={onSubmit}
        showErrorList={false}
      >
        <div className="row">
          <div className="col-12 text-center">
            <button type="submit" className={buttonClasses}>
              <FormattedMessage id={'sign.up.form.button'} />
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

SignupForm.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  communityId: PropTypes.number,
  onSuccess: PropTypes.func,
};

SignupForm.defaultProps = {};

const mapDispatchToProps = {
  login,
};

function mapStateToProps(state) {
  return {
    authenticationToken: state.currentUser.authenticationToken,
    loading: state.currentUser.authLoading,
    errorMessage: state.currentUser.authError,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SignupForm)));
