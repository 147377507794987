import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';

import GeneralForm from '../components/generalForm';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import GooglePlacesField from '../components/customFields/googlePlacesField';
import ContactDetailsField from '../components/customFields/contactDetails';
import { useServiceAreas } from './apiHooks';
import { getCurrentUser } from '../user/currentUserActions';
import BusinessProfileCustomInvoiceFields from '../components/customFields/businessProfileCustomInvoiceFields';

// TODO add admin edit for custom fields
const BusinessProfileForm = ({ match, action, intl, history, getCurrentUser, selectedServiceCountry }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [businessProfile, setBusinessProfile] = useState({});

  useEffect(() => {
    if (action === 'edit') {
      setError(null);
      setLoading(true);
      apiCalls
        .getBusinessProfiles({
          businessProfileId: match.params.businessProfileId,
        })
        .then((result) => {
          const businessProfile = result.data.businessProfiles[0];
          setBusinessProfile({
            ...businessProfile,
            invoiceEmails: businessProfile.invoiceEmails.join(', '),
            transactionalEmails: businessProfile.transactionalEmails.join(', '),
            promotionEmails: businessProfile.promotionEmails.join(', '),
            differentDeliveryAddress: !!businessProfile.deliveryAddress,
            addCustomInvoiceFields: !!businessProfile.customInvoiceFields?.length,
          });
          setLoading(false);
        })
        .catch((errors) => {
          setError(utils.getErrorMessageFromResponse(errors));
          setLoading(false);
        });
    }
  }, [match.params.businessProfileId, action]);

  const { serviceAreas } = useServiceAreas({ country: selectedServiceCountry?.countryAlpha2Code });

  const serviceAreaOptions = serviceAreas
    ? serviceAreas.map((area) => {
        return {
          value: area.id,
          label: `${area.name}`,
        };
      })
    : [];

  const formSchema = {
    type: 'object',
    required: [],
    properties: {
      companyName: {
        type: 'string',
        title: intl.formatMessage({ id: 'business.profile.form.field.company.name.label' }),
      },
      registryCode: {
        type: 'string',
        title: intl.formatMessage({ id: 'business.profile.form.field.registry.code.label' }),
      },
      vatNumber: {
        type: 'string',
        title: intl.formatMessage({ id: 'business.profile.form.field.vat.number.label' }),
      },
      contactDetails: {
        business: true,
        type: 'object',
        default: {
          name: '',
          email: '',
          phoneNumber: '',
        },
      },
      address: {
        title: intl.formatMessage({ id: 'business.profile.form.field.address.label' }),
        type: 'object',
        required: [],
        properties: {
          serviceAreaId: {
            type: 'string',
            options: serviceAreaOptions,
          },
          unit: {
            type: 'string',
            default: '',
          },
          street: {
            type: 'string',
            default: '',
          },
          city: {
            type: 'string',
            default: '',
          },
          country: {
            type: 'string',
            default: '',
          },
          postCode: {
            type: 'string',
            default: '',
          },
          coordinates: {
            type: 'object',
            required: [],
            properties: {
              lat: {
                type: 'string',
              },
              lon: {
                type: 'string',
              },
            },
          },
        },
      },
      differentDeliveryAddress: {
        type: 'boolean',
        title: intl.formatMessage({ id: 'business.profile.form.field.different.delivery.address.label' }),
      },
      addCustomInvoiceFields: {
        type: 'boolean',
        title: intl.formatMessage({ id: 'business.profile.form.field.add.custom.invoice.fields.label' }),
      },
    },
  };

  if (businessProfile.addCustomInvoiceFields) {
    formSchema.properties.customInvoiceFields = {
      type: 'array',
    };
  }

  if (businessProfile.differentDeliveryAddress) {
    formSchema.properties.deliveryAddress = {
      title: intl.formatMessage({ id: 'business.profile.form.field.delivery.address.label' }),
      type: 'object',
      required: [],
      properties: {
        serviceAreaId: {
          type: 'string',
          options: serviceAreaOptions,
        },
        unit: {
          type: 'string',
          default: '',
        },
        street: {
          type: 'string',
          default: '',
        },
        city: {
          type: 'string',
          default: '',
        },
        country: {
          type: 'string',
          default: '',
        },
        postCode: {
          type: 'string',
          default: '',
        },
        coordinates: {
          type: 'object',
          required: [],
          properties: {
            lat: {
              type: 'string',
            },
            lon: {
              type: 'string',
            },
          },
        },
      },
    };
  }

  const uiSchema = {
    address: {
      'ui:field': 'googlePlacesField',
    },
    deliveryAddress: {
      'ui:field': 'googlePlacesField',
    },
    contactDetails: {
      'ui:field': 'contactDetailsField',
    },
    customInvoiceFields: {
      'ui:field': 'customInvoiceFields',
    },
    addCustomInvoiceFields: {
      'ui:help': intl.formatMessage({ id: 'business.profile.form.field.add.custom.invoice.fields.help' }),
    },
  };

  function onFormSubmit() {
    setLoading(true);

    if (action === 'edit') {
      return apiCalls
        .editBusinessProfile({ businessProfile })
        .then((result) => {
          setLoading(false);
          history.push({
            pathname: '/business-accounts',
          });
        })
        .catch((error) => {
          setLoading(false);
          setError(utils.getErrorMessageFromResponse(error, true));
          window.scrollTo(0, 0);
        });
    } else {
      return apiCalls
        .createBusinessProfile({ businessProfile })
        .then((result) => {
          setLoading(false);
          getCurrentUser();
          history.push({
            pathname: '/subscription',
          });
        })
        .catch((error) => {
          setLoading(false);
          setError(utils.getErrorMessageFromResponse(error, true));
          window.scrollTo(0, 0);
        });
    }
  }

  const buttonCopy = action === 'edit' ? 'business.profile.edit.form.submit' : 'business.profile.create.form.submit';

  const formFields = {
    googlePlacesField: GooglePlacesField,
    contactDetailsField: ContactDetailsField,
    customInvoiceFields: BusinessProfileCustomInvoiceFields,
  };

  const header =
    action === 'edit' && !loading
      ? intl.formatMessage({ id: 'business.profile.edit.header' }, { companyName: businessProfile.companyName })
      : intl.formatMessage({ id: 'business.profile.create.header' });

  return (
    <div className="row">
      <Helmet>
        <title>{header}</title>
      </Helmet>
      <div className="container">
        <div className="row p-t-6 m-b-6">
          <div className="col-12 col-lg-10 offset-lg-1 admin__form">
            <GeneralForm
              title={header}
              onChange={(event) => setBusinessProfile(event.formData)}
              onSubmit={onFormSubmit}
              formSchema={formSchema}
              formUiSchema={uiSchema}
              formFields={formFields}
              formData={businessProfile}
              buttonCopy={buttonCopy}
              errorMessage={error}
              loading={loading}
              hideImageUpload={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BusinessProfileForm.propTypes = {
  currentUser: PropTypes.object,
  action: PropTypes.string.isRequired,
};

BusinessProfileForm.defaultProps = {
  action: 'create',
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

const mapDispatchToProps = {
  getCurrentUser,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BusinessProfileForm));
