import React, {useEffect} from 'react';
import {injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";

const TermsAndConditionsPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({id: 'producer.helmet.title'})}</title>
        <meta name="description" content={intl.formatMessage({id: 'producer.helmet.description'})}/>
      </Helmet>
      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>Membo O&Uuml; kasutustingimused</h1>
          <p>Viimati muudetud <strong>11.10.2021</strong></p>
          <p>Kasutades Membo O&Uuml; (registrikood 16093334, &bdquo;<strong>Membo</strong>&ldquo;) poolt pakutavat Apple
            App Store&rsquo;is ning Google Play Store&rsquo;is ja veebist https://membo.ee k&auml;ttesaadavat Toodete
            tellimise ja kojuveo Teenuse Platvormi (Teenuse kasutaja &bdquo;
            <strong>Kasutaja</strong>&ldquo; v&otilde;i &bdquo;<strong>Teie</strong>&ldquo;), n&otilde;ustute
            j&auml;rgnevate Tingimustega. Palun tutvuge Tingimustega Platvormi ja Teenuse kasutamise eelselt hoolikalt
            ning loobuge Platvormi ja Teenuse kasutamisest, kui Te tingimustega ei n&otilde;ustu.</p>
          <h3>M&otilde;isted</h3>
          <p>&bdquo;<strong>Toode</strong>&ldquo; on Tootja poolt toodetud, valmistatud ja pakendatud toode, mida on
            Kasutajal v&otilde;imalik Platvormi vahendusel Tootjalt tellida koos Membo poolt pakutava kojuveo teenusega.
          </p>
          <p>&bdquo;<strong>Kasutaja</strong>&rdquo; v&otilde;i &bdquo;<strong>Teie</strong>&rdquo; t&auml;hendab
            isikut, kes Platvormi vahendusel Teenust kasutab.</p>
          <p>&bdquo;<strong>Platvorm</strong>&ldquo; on Membo poolt arendatud ja hallatav Apple App Store&rsquo;is ning
            Google Play Store&rsquo;is k&auml;ttesaadavToodete tellimise ja kojuveo teenuse (Teenus) platvorm ning
            veebileht https://membo.ee, mille vahendusel on (i) Platvormiga liitunud Tootjatel v&otilde;imalik turustada
            Tooteid ning s&otilde;lmida Toodete m&uuml;&uuml;giks Kasutajatega M&uuml;&uuml;gilepinguid ja (ii)
            Kasutajatel v&otilde;imalik esitada Tootjatel Toodete Tellimusi ja s&otilde;lmida Tootjatega
            M&uuml;&uuml;gilepinguid, kusjuures Tellimus toimetatakse Kasutajale koju (siin ja edaspidi t&auml;hendab
            viide koju toimetamisele ka viiteid mujale Kasutaja m&auml;rgitud kohta toimetamist) Membo v&otilde;i
            viimase poolt kasutatavate kolmandatest isikutest teenusepakkujate poolt.</p>
          <p>&bdquo;<strong>Membo</strong>&rdquo; on Membo O&Uuml; (registrikood 16093334, registreeritud aadress Harju
            maakond, Anija vald, Kaunissaare k&uuml;la, Volberi, 74309, e-mail <a
              href="mailto:info@membo.ee">info@membo.ee</a>).</p>
          <p>&bdquo;<strong>M&uuml;&uuml;gileping</strong>&rdquo; on Platvormi vahendusel Kasutaja ja Tootja vahel
            s&otilde;lmitud leping Tootja poolt toodetavate Toodete ostmiseks Kasutaja poolt, kusjuures Tootja agendina
            tegutseb M&uuml;&uuml;gilepingu s&otilde;lmimisel Membo, kellel on &otilde;igus Tootja nimel vastu
            v&otilde;tta ka M&uuml;&uuml;gilepingu kohaseid makseid.</p>
          <p>&bdquo;<strong>Tootja</strong>&ldquo; on isik, mis on s&otilde;lminud Memboga lepingu, pakkudes Platvormi
            vahendusel Tooteid, milliste ostmiseks esitab Kasutaja Tellimuse ning s&otilde;lmivad M&uuml;&uuml;gilepingu
            Tootja (mida esindab agendina Membo) ja Kasutaja.</p>
          <p>&bdquo;<strong>Teenus</strong>&rdquo; on Platvormi vahendusel k&auml;ttesaadav Tootja poolt toodetavate
            Toodete tellimise ja Tellimuste kojuveo teenus.</p>
          <p>&bdquo;<strong>Tellimus</strong>&ldquo; on Platvormi vahendusel Kasutaja poolt Tootjale esitatud Toote
            (Toodete) tellimus, millise j&otilde;ustumise hetkel s&otilde;lmitakse M&uuml;&uuml;gileping ja Tellimuse
            kojuveo leping.</p>
          <p>&bdquo;<strong>Tingimused</strong>&ldquo; k&auml;esolevad Teenuse ja Platvormi kasutamise tingimused.</p>
          <ol>
            <li>
              <h3>Teenuse kirjeldus, Tellimuse esitamine, M&uuml;&uuml;gilepingu s&otilde;lmimine, Tellimuse kojuveo
                teenuse lepingu s&otilde;lmimine</h3>
              <ol>
                <li>Membo poolt pakutav Teenus on info&uuml;hiskonna teenus, mis v&otilde;imaldab Kasutajal
                  Platvormi vahendusel (ning &uuml;ksnes Platvormi vahendusel) esitada Tootja(te)le Toote (Toodete)
                  Tellimusi ja s&otilde;lmida Kasutaja valitud Tootjaga Toote (Toodete) M&uuml;&uuml;gileping koos
                  Membo poolt pakutava Tellimuse kojuveo teenusega. Kasutajal on v&otilde;imalik Tellimusele ise
                  j&auml;rele tulla v&otilde;i lasta Tellimus toimetada Kasutajale Kasutaja valitud aadressil Membo
                  v&otilde;i viimase nimetatud kolmandate isikute poolt k&auml;tte.
                </li>
                <li>Tellimuse esitamisel kohustub Kasutaja sisestama Platvormil n&otilde;utud informatsiooni,
                  millist Tootjal ning Membol on vaja vastavalt M&uuml;&uuml;gilepingu ning Tellimuse kojuveo
                  teenuse lepingu s&otilde;lmimiseks ning nende lepingute t&auml;itmiseks. Selline Tellimuses
                  n&otilde;utud informatsioon on tellitav(ad) Toode (Tooted) ja nende kogus, Kasutaja kontaktandmed
                  (nimi, e-post, telefoninumber) ja Tellimuse kohaletoimetamise aadress. Kasutaja kohustub sisestama
                  ka Platvormil n&otilde;utud maksevahendi andmed Tellimuse ning kojuveo eest tasumiseks.&nbsp;</li>
                <li>Membo on Tootjate agent ning &uuml;ksnes vahendab Tootja ja Kasutaja vahelise
                  M&uuml;&uuml;gilepingu s&otilde;lmimist ning ei ole M&uuml;&uuml;gilepingu pool. Tootjad on
                  volitanud Membot v&otilde;tma Tootjate nimel vastu M&uuml;&uuml;gilepingu alusel teostatavaid
                  makseid. Kui Kasutajal on Toodete v&otilde;i M&uuml;&uuml;gilepinguga seonduvaid k&uuml;simusi (nt
                  allergeenide v&otilde;i ka muus osas), kohustub Kasutaja &uuml;hendust v&otilde;tma otse Tootjaga,
                  kusjuures Membo v&otilde;ib, aga ei ole kohustatud osutama suhtlusel kaasabi. Membo avaldab
                  Platvormil Tootjate kontaktandmed.
                </li>
                <li>Membo pakub Tellimuste kojuveo teenust, kusjuures Membo v&otilde;ib Tellimuste kojuveo teenuse
                  osutamiseks kasutada kolmandatest isikutest teenusepakkujaid.
                </li>
                <li>Platvormil on k&auml;ttesaadav Tootjate poolt koostatud ja avaldamiseks esitatud informatsioon
                  Toodete koostise, hindade ja muu olulise osas. Kasutajal on Platvormi vahendusel v&otilde;imalik
                  v&auml;lja valida sobilik Tootja, sobilik(ud) Toode (Tooted) ja nende kogus ning esitada Tootjale
                  Platvormi kaudu Tellimus. Tellimus j&otilde;ustub selle esitamisel Membo Platvormi vahendusel
                  (Kasutajale kuvatakse Tootja poolne Membo vahendatud Tellimuse j&otilde;ustumise kinnitus) ning
                  Tellimuse j&otilde;ustumise hetkel loetakse, et Tootja ja Kasutaja on s&otilde;lminud
                  M&uuml;&uuml;gilepingu ning Membo ja Kasutaja on s&otilde;lminud Tellimuse kojuveo lepingu.
                  Kasutajal tekib maksekohustus Tellimuse ja kojuveo teenuse eest Tellimuse j&otilde;ustumise
                  hetkel.
                </li>
                <li>Tellimuse alusel s&otilde;lmitud M&uuml;&uuml;gilepingu j&auml;rgse(d) Toote(d) m&uuml;&uuml;b
                  Tootja ning Membo ei vastuta M&uuml;&uuml;gilepingu t&auml;itmise ega n&otilde;uetekohase
                  t&auml;itmise eest. Membo osutab &uuml;ksnes Tellimuse kojuveo teenust.&nbsp;</li>
                <li>Tellimused veetakse Kasutajale koju Platvormil m&auml;rgitud aegadel, millistega Kasutajal on
                  v&otilde;imalik Tellimuse esitamise eelselt tutvuda. Membol on ainu&otilde;igus m&auml;&auml;rata
                  ja Platvormil kuvada, kui tihti ning millistel aegadel Tellimusi t&auml;idetakse. P&auml;rast
                  Tellimuse j&otilde;ustumist t&auml;idetakse Tellimus vastavalt Tellimuse tingimustele ning neid ei
                  ole v&otilde;imalik Membol muuta, kui Kasutajaga ei lepita kokku teisiti.
                </li>
                <li>Tellimuse j&otilde;ustumise j&auml;rgselt ei ole Kasutajal &otilde;igust
                  Tellimust &uuml;hepoolselt t&uuml;histada ei M&uuml;&uuml;gilepingu ega ka Tellimuse kojuveo
                  teenuse osas. Tulenevalt M&uuml;&uuml;gilepingu, Tellimuse kojuveo teenuse ja Teenuse kui terviku
                  olemusest puudub tarbijal &otilde;igus p&otilde;hjust avaldamata taganeda Tellimuse alusel
                  s&otilde;lmitud lepingutest 14 p&auml;eva jooksul.
                </li>
                <li>Kui Tellimuses sisaldub alkohol, peab Kasutaja Tellimust kohale toimetava kulleri
                  n&otilde;udmisel isikut t&otilde;endava dokumendi alusel t&otilde;endama, et Kasutaja on
                  v&auml;hemalt 18-aastane. Kui alkoholi tellinud Kasutaja on alla 18-aasta vanune v&otilde;i ei
                  t&otilde;enda enda vanust isikut t&otilde;endava dokumendi alusel, keeldub kuller Kasutajale
                  Tellimuses sisaldunud alkoholi &uuml;le andmast ning vastavas osas tagastatakse Kasutajale
                  M&uuml;&uuml;gilepingu j&auml;rgne tasu viie t&ouml;&ouml;p&auml;eva jooksul.&nbsp;</li>
              </ol>
            </li>
            <li>
              <h3>Maksed, arveldamine</h3>
              <ol>
                <li>Kasutaja on viivitamatult p&auml;rast Tellimuse j&otilde;ustumist kohustatud tasuma Tellimuse
                  kinnituses m&auml;rgitud hinna, milline koosneb M&uuml;&uuml;gilepingu j&auml;rgsest Toote (Toodete)
                  hinnast ning Membo Tellimuse kojuveo teenuse hinnast. Tellimuse kinnituses m&auml;rgitud hinnad
                  sisaldavad k&auml;ibemaksu ja on l&otilde;plikud tasumisele kuuluvad summad. Kasutaja on kohustatud
                  makse teostama, kasutades Platvormil kirjeldatud maksemeetodeid. Membol on Tootja
                  agendina &otilde;igus v&otilde;tta Tootja nimel vastu M&uuml;&uuml;gilepingu j&auml;rgseid makseid.
                  Kui Kasutaja on Membole M&uuml;&uuml;gilepingu j&auml;rgse summa tasunud, siis loetakse, et Kasutaja
                  on t&auml;itnud M&uuml;&uuml;gilepingust tuleneva maksekohustuse Tootja ees. Kui Kasutaja ei ole
                  tasunud Tellimuse kinnituses m&auml;rgitud tasumisele kuuluvat summat, on Membol &otilde;igus keelduda
                  Kasutajale Toote (Toodete) kohale toimetamisest ja &uuml;le andmisest.
                </li>
                <li>Makse teostamiseks peab Kasutaja esitama Membole kehtiva maksevahendi andmed. Membo kasutab Kasutaja
                  maksete t&ouml;&ouml;tlemiseks kolmandast isikust teenusepakkujat ning Membo s&auml;ilitab Kasutaja
                  maksevahendi andmetest vaid kaardi viimased neli numbrit, br&auml;ndi, kehtivuskuup&auml;eva ja
                  p&auml;ritoluriigi.
                </li>
                <li>M&uuml;&uuml;gilepingu j&auml;rgne hind m&auml;&auml;ratakse Platvormil avaldatud Toote hinna
                  (t&uuml;ki kohta) j&auml;rgi ning tasumisele kuuluv summa s&otilde;ltub Tellimuse sisust. Kojuveo
                  teenuse hind m&auml;&auml;ratakse s&otilde;ltuvalt veoteekonna pikkusest ja muudest asjakohastest
                  kriteeriumidest ning avaldatakse Kasutajale Tellimuse esitamise eelselt. Tellimuse ja kojuveo teenuse
                  koguhind ning koguhinna komponendid kuvatakse Kasutajale Tellimuse esitamise eelselt. Platvormil
                  avaldatud hinnad on Kasutaja poolt makstavad l&otilde;plikud hinnad, mis sisaldavad k&auml;ibemaksu ja
                  muid tasumisele kuuluvaid makseid.
                </li>
                <li>Membo v&auml;ljastab Kasutaja e-postiaadressile Tootja agendina Tootja nimel M&uuml;&uuml;gilepingu
                  kohase arve ning Membo nimel Tellimuse kohaletoimetamise teenuse arve.
                </li>
              </ol>
            </li>
            <li>
              <h3>Tellimuste kojuvedu</h3>
              <ol>
                <li>Tellimused toimetatakse Membo v&otilde;i Membo poolt kasutatavate teenusepakkujate poolt Kasutaja
                  aadressile (linna/valla, t&auml;nava, maja ja korteri t&auml;psusega), mille Kasutaja on Tellimust
                  esitades sisestanud. Kasutajal on soovi korral v&otilde;imalik ka Tellimusele ise j&auml;rele tulla.
                </li>
                <li>Kasutaja peab olema Tellimuse t&auml;itmise p&auml;eval ning eriti Tellimuse t&auml;itmise
                  hinnangulisel ajal Tellimuse esitamisel antud telefoninumbril k&auml;ttesaadav. Kasutaja peab
                  Tellimuse kojuveo hinnangulisel ajal viibima Tellimuses sisestatud kojuveo aadressil. Kui Kasutaja ei
                  viibi Tellimuses sisestatud aadressil k&uuml;mne minuti jooksul alates kulleri saabumise hetkest ning
                  ei vasta kulleri v&auml;hemalt kahele katsele Kasutajaga telefonitsi kontakteeruda, on
                  Membol &otilde;igus Tellimus t&auml;ies ulatuses t&uuml;histada (st nii M&uuml;&uuml;gilepingu osas
                  kui ka Tellimuse kojuveo teenuse osas) ja n&otilde;uda Kasutajalt leppetrahvina Tellimuse ja kojuveo
                  t&auml;ishinda.
                </li>
              </ol>
            </li>
            <li>
              <h3>Tellimuste kohaletoimetamise aegade hinnangulisus</h3>
              <ol>
                <li>Membo poolt Kasutajale avaldatud Tellimuse kohaletoimetamise ajad on hinnangulised. Membo annab
                  endast parima, kuid ei garanteeri, et Tellimused toimetatakse kohale Kasutajale avaldatud ajaks.
                  Avaldatud aegasid v&otilde;ivad m&otilde;jutada erinevad tegurid nagu liiklus v&otilde;i
                  ilmastikutingimused.
                </li>
              </ol>
            </li>
            <li>
              <h3>Intellektuaalse omandi &otilde;igused</h3>
              <ol>
                <li>Kasutajal on piiratud, mitteeksklusiivne, edasi v&otilde;&otilde;randamise &otilde;iguseta ja edasi
                  litsentseerimise &otilde;iguseta &otilde;igus kasutada Teenust ja Platvormi koosk&otilde;las pakutava
                  Teenuse ja Platvormi sisu ja Tingimustega.&nbsp;</li>
                <li>Membo Teenuse, Platvormi, sellega seonduvate dokumentide ja mis tahes muude intellektuaalse omandi
                  (selle k&otilde;ige laiemas t&auml;henduses) &otilde;igustega kaitstud esemete intellektuaalse
                  omandi &otilde;igused kuuluvad kas Membole v&otilde;i vastavatele litsentsiandjatele ning Teenuse
                  kasutamisel Kasutaja poolt ei toimu vastavate &otilde;iguste loovutamist Kasutajale v&otilde;i
                  mistahes muule kolmandale isikule. Kasutajal on keelatud Teenust kasutada v&otilde;i mis tahes muul
                  moel tegutseda viisil, mis rikub eeltoodud intellektuaalse omandi &otilde;iguste
                  omajate &otilde;iguseid.
                </li>
              </ol>
            </li>
            <li>
              <h3>Vastutuse piirangud</h3>
              <ol>
                <li>Membo ei anna Platvormi osas kinnitusi ega garantiisid ning ei kinnita ega taga, et Platvorm oleks
                  sobilik kasutamiseks mistahes eesm&auml;rgil. Membo pakub Platvormi nagu on ehk &bdquo;<em>as
                    is</em>&ldquo; p&otilde;him&otilde;ttel. Membo vastutab &uuml;ksnes Membo rikkumiste eest (st
                  ulatuses, mis on piiratud Platvormi pakkumise ning Tellimuse kojuveo teenusega) ning &uuml;ksnes
                  juhul, kui Membo rikkumine on p&otilde;hjustatud raskest hooletusest v&otilde;i on
                  tahtlik. &Uuml;ksnes Tootja (ja mitte Membo) vastutab M&uuml;&uuml;gilepingu ja sellest tulenevate
                  kohustuste n&otilde;uetekohase t&auml;itmise eest.
                </li>
                <li>Platvormil sisalduv Tootjaid ja Tooteid puudutav informatsioon on Membole edastatud Tootjate poolt.
                  Membol ei ole kohustust kontrollida Tootjate poolt avaldatud informatsiooni korrektsust. Membo ei
                  vastuta Tootjate poolt edastatud sisu ega teabe eest, sellest tuleneva ega sellega seonduva mistahes
                  kahju eest.
                </li>
              </ol>
            </li>
            <li>
              <h3>&Otilde;iguste ja kohustuste &uuml;leandmine</h3>
              <ol>
                <li>Membol on &otilde;igus k&otilde;ik Teenuse osutamisega seonduvad &otilde;igused ja
                  kohustused &uuml;le anda &uuml;hepoolselt Kasutaja n&otilde;usolekuta. Kasutajal ei ole &otilde;igust
                  Teenuse tarbimisega seonduvatest lepingutest tulenevaid &otilde;iguseid ja kohustusi &uuml;hepoolselt
                  n&otilde;usolekuta &uuml;le anda.
                </li>
              </ol>
            </li>
            <li>
              <h3>Isikuandmete t&ouml;&ouml;tlemine</h3>
              <ol>
                <li>Kasutaja isikuandmeid t&ouml;&ouml;deldakse koosk&otilde;las Membo isikuandmete t&ouml;&ouml;tlemise
                  p&otilde;him&otilde;tetega.
                </li>
              </ol>
            </li>
            <li>
              <h3>Muudatused</h3>
              <ol>
                <li>Membol on Tingimusi &otilde;igus &uuml;hepoolselt muuta. Muudatused ei m&otilde;juta muudatuste
                  tegemise eelselt j&otilde;ustunud Tellimusi. Tingimuste muutmisest teavitab Membo Kasutajaid Platvormi
                  vahendusel.&nbsp;</li>
              </ol>
            </li>
            <li>
              <h3>Kohalduv &otilde;igus, kohtualluvus, kaebuste ja vaidluste lahendamine</h3>
              <ol>
                <li>Teenuse osutamisele ja Tingimustele kohaldatakse Eesti &otilde;igust.&nbsp;Teenust
                  osutatakse &uuml;ksnes Eesti Vabariigi piires.
                </li>
                <li>Membo ei taga, et Teenust osutatakse eksimusteta, kuid Membo eesm&auml;rk on osutada kvaliteetset
                  Teenust. Kasutaja kaebuste korral p&uuml;&uuml;takse lahkhelid lahendada l&auml;bir&auml;&auml;kimiste
                  teel.&nbsp;</li>
                <li>Kui Kasutaja hinnangul esineb Tellimuses puudus, siis v&otilde;ib Kasutaja sellest teada anda kas
                  Membole <a href="mailto:info@membo.ee">info@membo.ee</a>) v&otilde;i v&otilde;tta &uuml;hendust otse
                  Tootjaga (kontaktandmed on k&auml;ttesaadavad Platvormil), kirjeldades puudust v&otilde;imalikult
                  t&auml;pselt. Kasutaja peab arvestama, et Kasutaja peab suutma kirjeldada ja t&otilde;endada kaebuse
                  aluseks olevaid asjaolusid (nt fotod). Kasutaja peab puudusest teavitama esimesel v&otilde;imalusel,
                  aga mitte hiljem kui &uuml;he kuu jooksul puudusest teada saamisest v&otilde;i teada saama pidamisest.
                  M&uuml;&uuml;gilepingu mittet&auml;itmise v&otilde;i mittekohase t&auml;itmise eest
                  vastutab &uuml;ksnes Tootja ning mitte &uuml;helgi juhul Membo.
                </li>
                <li>Teenuse osutamisest ja Tingimustest tulenevad vaidlused lahendatakse l&auml;bir&auml;&auml;kimiste
                  eba&otilde;nnestumisel esimese astme kohtuna Harju Maakohtus, kui kehtiv seadus ei n&auml;e ette
                  teistsugust kohtualluvust. Tarbijal on vaidluse lahendamiseks &otilde;igus p&ouml;&ouml;rduda ka
                  Tarbijakaitse ja Tehnilise J&auml;relevalve Ameti juures tegutsevasse <a
                    href="https://komisjon.ee/et">Tarbijavaidluste komisjoni</a>.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

TermsAndConditionsPage.propTypes = {};

TermsAndConditionsPage.defaultProps = {};

export default injectIntl(TermsAndConditionsPage);
