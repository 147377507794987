import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const FirstOrderCoupon = () => {
  return '';
  /*
  return (
    <div className={'first-order-coupon'}>
      <h4 className="m-b-1">
        ❗️ <FormattedMessage id="membo.vacation" />
      </h4>
      <p className="first-order-coupon__countdown m-b-0">
        <small>
          <FormattedMessage id="membo.vacation.description" />
        </small>
      </p>
    </div>
  );
   */
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FirstOrderCoupon));
