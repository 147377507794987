import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const BusinessProfileCustomInvoiceFields = ({ formData, onChange }) => {
  const [customInvoiceFields, setCustomInvoiceFields] = useState([]);

  useEffect(() => {
    setCustomInvoiceFields(formData || []);
  }, [formData]);

  const onFieldChange = (index, fieldName, newValue) => {
    const newCustomInvoiceFields = [...customInvoiceFields];
    newCustomInvoiceFields[index][fieldName] = newValue;
    setCustomInvoiceFields(newCustomInvoiceFields);
    onChange(newCustomInvoiceFields);
  };

  const addCustomInvoiceField = () => {
    const newCustomInvoiceFields = [...customInvoiceFields, { label: '', value: '' }];
    setCustomInvoiceFields(newCustomInvoiceFields);
    onChange(newCustomInvoiceFields);
  };

  const removeCustomInvoiceField = (index) => {
    const newCustomInvoiceFields = [...customInvoiceFields];
    newCustomInvoiceFields.splice(index, 1);
    setCustomInvoiceFields(newCustomInvoiceFields);
    onChange(newCustomInvoiceFields);
  };

  return (
    <div className="form-block">
      <div className="m-b-2">
        <h5 className="m-b-2">
          <FormattedMessage id="business.profile.form.custom.invoice.fields.header" />
        </h5>
      </div>

      <div className="m-b-3">
        {customInvoiceFields.map((customInvoiceField, index) => {
          return (
            <div className="m-b-2" key={index}>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      <FormattedMessage id={'business.profile.form.field.custom.invoice.fields.label.label'} />
                    </label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={customInvoiceField.label}
                      onChange={(e) => onFieldChange(index, 'label', e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6">
                  <div className="form-group">
                    <label className="control-label">
                      <FormattedMessage id={'business.profile.form.field.custom.invoice.fields.value.label'} />
                    </label>
                    <input
                      className="form-control"
                      placeholder=""
                      type="text"
                      value={customInvoiceField.value}
                      onChange={(e) => onFieldChange(index, 'value', e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="text-right">
                <button onClick={() => removeCustomInvoiceField(index)} className="btn btn-default btn-small">
                  <FormattedMessage id={'business.profile.form.field.custom.invoice.fields.remove'} />
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="btn btn-normal-link m-b-1" onClick={addCustomInvoiceField}>
        <small>
          <FormattedMessage id={'business.profile.form.field.custom.invoice.fields.add'} />
        </small>
      </div>
    </div>
  );
};

export default BusinessProfileCustomInvoiceFields;
