import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Footer from '../components/footer';
import fruitBox from './assets/kast-uus.jpg';
import billing from './assets/billing.jpg';
import { Link } from 'react-router-dom';
import rockHector from '../landing/assets/v2/rock-hector.png';
import rockFruits from '../landing/assets/v2/rock-fruits.png';
import rockBaby from '../landing/assets/v2/rock-baby.png';

const Business = ({ intl }) => {
  const mainCtaLink = '/subscription';

  return (
    <div className="landing">
      <Helmet>
        <title>{intl.formatMessage({ id: 'office.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'office.first.description' })} />
      </Helmet>

      <div className="container-fluid landing__first m-office text-center">
        <div className="row row-eq-height">
          <div className="col-12 col-md-6 landing__first-right order-md-2 m-office" />
          <div className="col-12 col-md-6 landing__first-left align-self-center">
            <div className="landing__first-left-content text-left">
              <h1 className="landing__first-left-content-title">
                <FormattedMessage id={'office.first.title'} />
              </h1>
              <p className="landing__first-left-content-description">
                <FormattedMessage id={'office.first.description'} />
              </p>

              <div className="landing__first-left-content-cta">
                <Link to={mainCtaLink} className="btn btn-secondary">
                  <FormattedMessage id={'landing.first.cta'} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container m-b-6 landing__numbers">
        <div className="row row-eq-height">
          <div className="col-12 col-md-4 m-b-3">
            <div
              className="landing__numbers-number text-center m-b-6"
              style={{ backgroundImage: `url("${rockHector}")` }}
            />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.direct.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.direct.description'} />
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 m-b-3">
            <div
              className="landing__numbers-number text-center m-b-4"
              style={{ backgroundImage: `url("${rockFruits}")` }}
            />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.fresh.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.fresh.description'} />
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 m-b-3">
            <div className="landing__numbers-number m-b-4" style={{ backgroundImage: `url("${rockBaby}")` }} />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.delivery.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.delivery.description'} />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center">
              <div className="landing__basket-image">
                <img src={fruitBox} alt="Membo weekly order" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>
                  <FormattedMessage id={'office.delivery.title'} />
                </h2>
                <p className="text-large">
                  <FormattedMessage id={'office.delivery.description.p1'} />
                </p>
                <p className="text-large">
                  <FormattedMessage id={'office.delivery.description.p2'} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket m-b-6">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center order-lg-1">
              <div className="landing__basket-image m-left-padding">
                <img src={billing} alt="Membo weekly order" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>
                  <FormattedMessage id={'office.billing.title'} />
                </h2>
                <p className="text-large">
                  <FormattedMessage id={'office.billing.description'} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer hideBadges={true} />
    </div>
  );
};

Business.propTypes = {};

Business.defaultProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default injectIntl(connect(mapStateToProps)(Business));
