import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';

export const ORDER_BATCHES_GET_BEGIN = 'ORDER_BATCHES_GET_BEGIN';
export const ORDER_BATCHES_GET_SUCCESS = 'ORDER_BATCHES_GET_SUCCESS';
export const ORDER_BATCHES_GET_FAILURE = 'ORDER_BATCHES_GET_FAILURE';

export const ORDER_BATCH_SET_SUCCESS = 'ORDER_BATCH_SET_SUCCESS';

export const ORDER_BATCH_CREATE_BEGIN = 'ORDER_BATCH_CREATE_BEGIN';
export const ORDER_BATCH_CREATE_SUCCESS = 'ORDER_BATCH_CREATE_SUCCESS';
export const ORDER_BATCH_CREATE_FAILURE = 'ORDER_BATCH_CREATE_FAILURE';

export const orderBatchCreateBegin = () => ({
  type: ORDER_BATCH_CREATE_BEGIN,
});

export const orderBatchCreateSuccess = (orderBatch) => ({
  type: ORDER_BATCH_CREATE_SUCCESS,
  payload: { orderBatch },
});

export const orderBatchCreateFailure = (error) => ({
  type: ORDER_BATCH_CREATE_FAILURE,
  payload: { error },
});

export const orderBatchesGetBegin = () => ({
  type: ORDER_BATCHES_GET_BEGIN,
});

export const orderBatchesGetSuccess = (orderBatches) => ({
  type: ORDER_BATCHES_GET_SUCCESS,
  payload: { orderBatches },
});

export const orderBatchSetSuccess = (orderBatch) => ({
  type: ORDER_BATCH_SET_SUCCESS,
  payload: { orderBatch },
});

export const orderBatchesGetFailure = (error) => ({
  type: ORDER_BATCHES_GET_FAILURE,
  payload: { error },
});

export const getOrderBatches = (params) => {
  return (dispatch) => {
    dispatch(orderBatchesGetBegin());
    return apiCalls
      .getOrderBatches(params)
      .then((res) => res.data)
      .then((data) => {
        dispatch(orderBatchesGetSuccess(data.orderBatches));
        return data.orderBatches;
      })
      .catch((error) => {
        dispatch(orderBatchesGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};

export const createOrderBatch = (params) => {
  return (dispatch) => {
    dispatch(orderBatchCreateBegin());
    return apiCalls
      .createOrderBatch(params)
      .then((res) => res.data)
      .then((data) => {
        dispatch(orderBatchCreateSuccess(data.orderBatch));
        return data.orderBatch;
      })
      .catch((error) => {
        dispatch(orderBatchCreateFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};
