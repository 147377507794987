import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import Loader from '../components/loader';

function CartPriceQuoteRows({ priceQuote }) {
  const deliveryFees = priceQuote
    ? priceQuote.deliveryFees.map((deliveryFee, index) => (
        <p key={index} className="text-right m-b-0 text-muted">
          <FormattedMessage
            id={'cart.delivery.fee'}
            values={{
              deliveryDate: utils.getFormattedDateFromString(deliveryFee.deliveryDate, DATE_FORMAT.displayWithoutTime),
            }}
          />
          :{' '}
          <strong>
            <span className="text-h4">{deliveryFee.deliveryFee}€</span>
          </strong>
        </p>
      ))
    : '';

  return (
    <>
      {priceQuote && (priceQuote.discountPercent || priceQuote.discountSum) ? (
        <div className="m-b-3">
          <p className="text-right m-b-0 text-muted">
            <FormattedMessage id="cart.total.previous.total" />:{' '}
            <strong>
              <span className="text-h4">{priceQuote.totalSum}€</span>
            </strong>
          </p>
          {priceQuote.discountPercent ? (
            <p className="text-right m-b-0 text-success">
              <FormattedMessage
                id="cart.total.sum.with.discount.percent"
                values={{ percent: priceQuote.discountPercent }}
              />
              :{' '}
              <strong>
                <span className="text-h4">{priceQuote.totalSumWithDiscount}€</span>
              </strong>
            </p>
          ) : (
            ''
          )}
          {priceQuote.discountSum ? (
            <p className="text-right m-b-0 text-success">
              <FormattedMessage id="cart.total.sum.with.discount.sum" values={{ sum: priceQuote.discountSum }} />:{' '}
              <strong>
                <span className="text-h4">{priceQuote.totalSumWithDiscount}€</span>
              </strong>
            </p>
          ) : (
            ''
          )}
          {deliveryFees}
          <p className="text-right m-b-0">
            <FormattedMessage id="cart.total.total.with.delivery" />:{' '}
            <strong>
              <span className="text-h3">{priceQuote.finalSum}€</span>
            </strong>
          </p>
        </div>
      ) : priceQuote ? (
        <div className="m-b-3">
          <p className="text-right m-b-0 text-muted">
            <FormattedMessage id="cart.total.total.without.delivery" />:{' '}
            <strong>
              <span className="text-h4">{priceQuote.totalSum}€</span>
            </strong>
          </p>
          {deliveryFees}
          <p className="text-right m-b-0">
            <FormattedMessage id="cart.total.total.with.delivery" />:{' '}
            <strong>
              <span className="text-h3">{priceQuote.finalSum}€</span>
            </strong>
          </p>
        </div>
      ) : (
        <Loader show={true} />
      )}
    </>
  );
}

CartPriceQuoteRows.propTypes = {
  priceQuote: PropTypes.object,
};

CartPriceQuoteRows.defaultProps = {};

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    priceQuote: state.cart.priceQuote,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartPriceQuoteRows));
