import apiCalls from '../utils/apiCalls';
import utils from "../utils/utils";

export const CTA_GROUPS_GET_BEGIN   = 'CTA_GROUPS_GET_BEGIN';
export const CTA_GROUPS_GET_SUCCESS = 'CTA_GROUPS_GET_SUCCESS';
export const CTA_GROUPS_GET_FAILURE = 'CTA_GROUPS_GET_FAILURE';

export const ctaGroupsGetBegin = () => ({
  type: CTA_GROUPS_GET_BEGIN
});

export const ctaGroupsGetSuccess = ctaGroups => ({
  type: CTA_GROUPS_GET_SUCCESS,
  payload: { ctaGroups }
});

export const ctaGroupsGetFailure = error => ({
  type: CTA_GROUPS_GET_FAILURE,
  payload: { error }
});

export const getCtaGroups = (params) => {
  return dispatch => {
    dispatch(ctaGroupsGetBegin());

    return apiCalls.getCtaGroupsV2(params)
      .then(res => res.data)
      .then(data => {
        dispatch(ctaGroupsGetSuccess(data.ctaGroups));
        return data.ctaGroups;
      })
      .catch(error => {
        dispatch(ctaGroupsGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};