import apiCalls from '../utils/apiCalls';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useSubscription({ subscriptionId }) {
  const { data, ...rest } = useQuery(
    ['subscriptions', { subscriptionId }],
    () =>
      apiCalls
        .getSubscriptions({
          subscriptionId,
        })
        .then(({ data: { subscriptions } }) => subscriptions[0]),
    { enabled: !!subscriptionId }
  );
  return { subscription: data, ...rest };
}

export function useSubscriptions({ userId }) {
  const { data, ...rest } = useQuery(
    ['subscriptions', { userId }],
    () =>
      apiCalls
        .getSubscriptions({
          userId,
        })
        .then(({ data: { subscriptions } }) => subscriptions),
    { enabled: !!userId }
  );
  return { subscriptions: data, ...rest };
}

export function useSubscriptionPortalUrlCreate(onSuccess) {
  const { mutate, ...rest } = useMutation(
    () => apiCalls.getSubscriptionPortalUrl({}).then(({ data: { url } }) => url),
    {
      onSuccess: (url) => {
        onSuccess(url);
      },
    }
  );
  return { createSubscriptionPortalUrl: mutate, ...rest };
}

export function useSubscriptionCreate(onSuccess) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    ({ subscription }) =>
      apiCalls.createSubscription({ subscription }).then(({ data: { subscription } }) => subscription),
    {
      onSuccess: (subscription) => {
        queryClient.invalidateQueries('subscriptions');
        onSuccess(subscription);
      },
    }
  );
  return { createSubscription: mutate, ...rest };
}

export function useSubscriptionEdit(onSuccess) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    ({ subscription }) =>
      apiCalls.editSubscription({ subscription }).then(({ data: { subscription } }) => subscription),
    {
      onSuccess: (subscription) => {
        queryClient.invalidateQueries('subscriptions');
        onSuccess(subscription);
      },
    }
  );
  return { editSubscription: mutate, ...rest };
}

export function useServiceAreas(params) {
  const { data, ...rest } = useQuery(['serviceAreas', params], () =>
    apiCalls.getServiceAreas(params).then(({ data: { serviceAreas } }) => serviceAreas)
  );
  return { serviceAreas: data, ...rest };
}

export function usePaymentMethodDelete(onSuccess) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(({ paymentMethod }) => apiCalls.deletePaymentMethod({ paymentMethod }), {
    onSuccess: () => {
      queryClient.invalidateQueries('paymentMethods');
      onSuccess();
    },
  });
  return { deletePaymentMethod: mutate, ...rest };
}
