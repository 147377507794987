import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const NavbarXs = ({ isXsNavOpen, openLoginSignupModal, logout, currentUser }) => {
  const classes = classNames({
    'navbar-xs d-block d-lg-none d-none': true,
    'm-visible': isXsNavOpen,
  });

  return (
    <div className={classes}>
      <div className="navbar-xs__links">
        <ul className="list-unstyled">
          <li className="navbar-xs__links-link">
            <Link to="/">
              <FormattedMessage id="navbar.links.home" />
            </Link>
          </li>
          <li className="navbar-xs__links-link">
            <Link to="/subscription">
              <FormattedMessage id="navbar.links.subscription" />
            </Link>
          </li>
          <li className="navbar-xs__links-link">
            <Link to="/shop">
              <FormattedMessage id="navbar.links.shop" />
            </Link>
          </li>
          <li className="navbar-xs__links-link">
            <a
              href="https://healthy-promotion-e73.notion.site/Korduma-kippuvad-k-simused-1526e6bb1a904ca6bb8a49ae5ed32a6e"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="navbar.links.faq" />
            </a>
          </li>
          {currentUser ? (
            <>
              <li className="navbar-xs__links-link">
                <NavLink activeClassName="active" to="/muuda-profiili">
                  <FormattedMessage id={'navbar.links.edit.profile'} />
                </NavLink>
              </li>
              <li className="navbar-xs__links-link">
                <NavLink activeClassName="active" to="/business">
                  <FormattedMessage id={'navbar.links.business.customers'} />
                </NavLink>
              </li>
              <li className="navbar-xs__links-link">
                <NavLink activeClassName="active" to="/business-accounts">
                  <FormattedMessage id={'navbar.links.business.profiles'} />
                </NavLink>
              </li>
              <li className="navbar-xs__links-link">
                <button className="btn btn-normal-link" onClick={logout}>
                  <FormattedMessage id={'navbar.links.log.out'} />
                </button>
              </li>
            </>
          ) : (
            <>
              <li className="navbar-xs__links-link">
                <button className="btn btn-normal-link" onClick={() => openLoginSignupModal('login')}>
                  <FormattedMessage id={'navbar.links.log.in'} />
                </button>
              </li>
              <li className="navbar-xs__links-link">
                <button className="btn btn-normal-link" onClick={() => openLoginSignupModal('signup')}>
                  <FormattedMessage id={'navbar.links.sign.up'} />
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

NavbarXs.propTypes = {
  logout: PropTypes.func.isRequired,
  openLoginSignupModal: PropTypes.func.isRequired,
  isXsNavOpen: PropTypes.bool,
  currentUser: PropTypes.object,
};

NavbarXs.defaultProps = {
  isXsNavOpen: false,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default connect(mapStateToProps)(NavbarXs);
