import React from 'react';
import LoginSignupContent from "./loginSignupContent";

function LoginSignupModal({loginSignupModalTab, closeLoginModal}) {
  return (
    <div className="b-modal">
      <div className="login-signup-modal">
        <LoginSignupContent onSuccess={closeLoginModal} defaultTab={loginSignupModalTab}/>
      </div>
    </div>
  );
}

LoginSignupModal.propTypes = {
};

LoginSignupModal.defaultProps = {};

export default LoginSignupModal;