import React, { useState } from 'react';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Form from 'react-jsonschema-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import utils from '../utils/utils';
import { login } from '../user/currentUserActions';
import { Link, withRouter } from 'react-router-dom';

const LoginForm = ({ login, history, onSuccess, redirectUrl, error, intl, loading }) => {
  const { formatMessage } = useIntl();

  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const onSubmit = (data) => {
    setLoginData(data.formData);

    login(data.formData, formatMessage).then((response) => {
      history.push({
        pathname: redirectUrl,
      });
      localStorage.setItem('hasLoggedIn', true);
      onSuccess();
    });
  };

  const errorMessage = error ? (
    <div className="row m-t-5 m-b-5">
      <div className="col-12">
        <div className="alert alert-error">{utils.getErrorMessageFromResponse(error)}</div>
      </div>
    </div>
  ) : (
    ''
  );

  const schema = {
    type: 'object',
    required: [],
    properties: {
      username: {
        type: 'string',
        title: intl.formatMessage({ id: 'login.form.field.email.label' }),
      },
      password: {
        type: 'string',
        title: intl.formatMessage({ id: 'login.form.field.password.label' }),
      },
    },
  };

  const uiSchema = {
    password: {
      'ui:widget': 'password',
    },
  };

  const buttonClasses = classNames({
    'btn-loading': loading,
    'btn btn-primary m-b-3': true,
  });

  return (
    <div className="login-form">
      {errorMessage}

      <Form
        schema={schema}
        uiSchema={uiSchema}
        noHtml5Validate={true}
        onChange={(event) => setLoginData(event.formData)}
        formData={loginData}
        onSubmit={onSubmit}
        showErrorList={false}
      >
        <div className="row">
          <div className="col-12 text-center">
            <button type="submit" className={buttonClasses}>
              <FormattedMessage id={'login.form.button'} />
            </button>
          </div>
        </div>

        <div className="text-center">
          <small>
            <Link className="link" to="/unustasin-salasona" onClick={onSuccess}>
              <FormattedMessage id={'login.form.forgot.password'} />
            </Link>
          </small>
        </div>
      </Form>
    </div>
  );
};

LoginForm.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

LoginForm.defaultProps = {};

const mapDispatchToProps = {
  login,
};

function mapStateToProps(state) {
  return {
    authenticationToken: state.currentUser.authenticationToken,
    loading: state.currentUser.authLoading,
    error: state.currentUser.authError,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
