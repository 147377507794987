import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import GooglePlacesField from '../components/customFields/googlePlacesField';
import GeneralForm from '../components/generalForm';
import { useSubscriptionEdit, useServiceAreas } from './apiHooks';
import CustomSelect from '../components/customFields/customSelect';

function SubscriptionEditModal({ onSuccess, subscription, timeRangeOptions, selectedServiceCountry }) {
  const { formatMessage } = useIntl();

  const [formData, setFormData] = useState(subscription);
  const { serviceAreas, isLoading: serviceAreasLoading } = useServiceAreas({
    country: selectedServiceCountry?.countryAlpha2Code,
  });
  const {
    editSubscription,
    isLoading: editLoading,
    isError: isEditError,
    error: editError,
  } = useSubscriptionEdit((address) => onSuccess(address));

  const serviceAreaOptions = serviceAreas
    ? serviceAreas.map((area) => {
        return {
          value: area.id,
          label: `${area.name}`,
        };
      })
    : [];

  const formSchema = {
    type: 'object',
    required: [],
    properties: {
      timeRange: {
        title: formatMessage({ id: 'subscription.product.time.range.select' }),
        type: 'string',
        default: '',
        options: timeRangeOptions,
      },
      address: {
        showCustomerFields: true,
        hideLegend: true,
        noMarginTop: true,
        type: 'object',
        required: [],
        properties: {
          serviceAreaId: {
            type: 'string',
            options: serviceAreaOptions,
          },
          unit: {
            type: 'string',
            default: '',
          },
          street: {
            type: 'string',
            default: '',
          },
          city: {
            type: 'string',
            default: '',
          },
          country: {
            type: 'string',
            default: '',
          },
          postCode: {
            type: 'string',
            default: '',
          },
          coordinates: {
            type: 'object',
            required: [],
            properties: {
              lat: {
                type: 'string',
              },
              lon: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  };

  const uiSchema = {
    address: {
      'ui:field': 'googlePlacesField',
    },
    timeRange: {
      'ui:field': 'customSelect',
    },
  };

  const formFields = {
    googlePlacesField: GooglePlacesField,
    customSelect: CustomSelect,
  };

  const buttonCopy = 'subscription.edit.submit';

  return (
    <div className="b-modal">
      <div>
        <h2 className="m-b-6">
          <FormattedMessage id="subscription.edit.title" />
        </h2>

        <GeneralForm
          onChange={(event) => setFormData(event.formData)}
          onSubmit={() => {
            editSubscription({ subscription: formData });
          }}
          formSchema={formSchema}
          formUiSchema={uiSchema}
          formFields={formFields}
          formData={formData}
          buttonCopy={buttonCopy}
          errorMessage={isEditError ? utils.getErrorMessageFromResponse(editError) : null}
          loading={editLoading || serviceAreasLoading}
          hideImageUpload={true}
        />
      </div>
    </div>
  );
}

SubscriptionEditModal.propTypes = {
  onSuccess: PropTypes.func,
};

SubscriptionEditModal.defaultProps = {
  onSuccess: () => {},
};

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionEditModal);
