import React from 'react';
import moment from 'moment-timezone';
import DATE_FORMAT from '../components/constants/dateFormat';
import 'moment/locale/et';
import slugify from 'slugify';
import ITEM_MEASUREMENT_UNIT from '../components/constants/itemMeasurementUnit';
import officeBox from '../subscription/assets/kast-kontor.jpg';
import fruitBox from '../subscription/assets/kast-uus.jpg';
import vegBox from '../subscription/assets/kast-kv.jpg';
import orangeBox from '../subscription/assets/kast-apelsin.jpeg';

const getErrorMessageFromResponse = function (error, returnAsString) {
  if (error.response && error.response.data && error.response.data.errors) {
    if (returnAsString) {
      return error.response.data.errors.join('; ');
    } else {
      return error.response.data.errors.map(function (message, index) {
        return (
          <span key={index}>
            {message}
            <br />
          </span>
        );
      });
    }
  } else if (error && utils.isString(error)) {
    if (returnAsString) {
      return error;
    } else {
      return (
        <span>
          {error}
          <br />
        </span>
      );
    }
  } else {
    if (returnAsString) {
      return 'Something went wrong. Please refresh and try again.';
    } else {
      return (
        <span>
          Something went wrong. Please refresh and try again.
          <br />
        </span>
      );
    }
  }
};

const getUserLocale = function () {
  return (
    window.userLanguage ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage
  );
};

const isNormalInteger = (str) => {
  return /^\+?(0|[1-9]\d*)$/.test(str);
};

const userIsAdmin = (currentUser) => {
  return currentUser && currentUser.authorities.indexOf('ROLE_ADMIN') !== -1;
};

const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

const isString = (x) => {
  return Object.prototype.toString.call(x) === '[object String]';
};

const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

const getDateFromString = (stringParam, format = DATE_FORMAT.dataTransfer) => {
  if (stringParam) {
    let date;
    if (format) {
      date = moment.utc(stringParam, format).tz(moment.tz.guess()).locale('et');
    } else {
      date = moment.utc(stringParam).tz(moment.tz.guess()).locale('et');
    }
    if (date && date.isValid()) {
      return date;
    }
  }

  return null;
};

const getNowDate = () => {
  return moment();
};

const getFormattedDateFromString = (stringParam, format) => {
  const date = getDateFromString(stringParam);
  if (!date) {
    return '';
  }

  return getFormattedDateFromMomentObj(date, format);
};

const getFormattedDateRangeFromStrings = (dateFrom, dateTo) => {
  const from = getDateFromString(dateFrom);
  const to = getDateFromString(dateTo);
  if (!from || !to) {
    return '';
  }

  return `${getFormattedDateFromMomentObj(
    from,
    DATE_FORMAT.displayWithTimeAndWeekday
  )} - ${getFormattedDateFromMomentObj(to, DATE_FORMAT.onlyTime)}`;
};

const getFormattedDateFromMomentObj = (date, format) => {
  return date.format(format);
};

const getDeliveryRange = (stringParam, format) => {
  const date = getDateFromString(stringParam);
  if (!date) {
    return '';
  }

  const rangeEnd = date.clone().add(2, 'hours');

  return `${getFormattedDateFromMomentObj(date, format)} - ${getFormattedDateFromMomentObj(rangeEnd, 'HH:mm')}`;
};

const getFormattedTimeRange = (timeRange) => {
  return `${timeRange.fromHour}:${timeRange.fromMinutes < 10 ? '0' : ''}${timeRange.fromMinutes} - ${
    timeRange.toHour
  }:${timeRange.toMinutes < 10 ? '0' : ''}${timeRange.toMinutes}`;
};

const getRoundedPrice = (price) => {
  return Math.round((price + Number.EPSILON) * 100) / 100;
};

const getCartTotalPrice = (cart) => {
  return getRoundedPrice(
    cart.reduce((result, cartOrder) => {
      const itemTotal = cartOrder.orderItems.reduce((result, orderItem) => {
        return orderItem.quantity * orderItem.item.priceFields.price + result;
      }, 0);

      return itemTotal + result;
    }, 0)
  );
};

const getDeliveryDaysFromCart = (cart, deliveryDayMarketplaces) => {
  const deliveryDays = [];
  for (const cartOrder of cart) {
    const matchingDeliveryDay = deliveryDayMarketplaces.find((ddm) => ddm.marketplaceId === cartOrder.marketplace.id);

    if (
      matchingDeliveryDay &&
      matchingDeliveryDay.selectedDeliveryDayMarketplace &&
      !deliveryDays.find((dd) => dd.deliveryDayId === matchingDeliveryDay.selectedDeliveryDayMarketplace.deliveryDayId)
    ) {
      deliveryDays.push({
        deliveryDate: matchingDeliveryDay.selectedDeliveryDayMarketplace.deliveryDate,
        deliveryDayId: matchingDeliveryDay.selectedDeliveryDayMarketplace.deliveryDayId,
      });
    }
  }

  return deliveryDays;
};

const convertToUrlString = (value) => {
  if (!value) {
    return null;
  }

  return slugify(value, {
    lower: true,
  });
};

const trackEvent = (eventName, properties) => {
  if (window.analytics) {
    window.analytics.track(eventName, properties);
  }
};

const getDateDiffInHours = (date1, date2 = moment.now()) => {
  return moment.duration(date1.diff(date2)).asHours();
};

const shouldCheckCutoffTimeForItem = (item) => {
  return item.deliveryCutOffHours && (!item.marketplaces[0].stock || item.marketplaces[0].stock === 0);
};

const getSelectedDdmDiffFromNowInHours = (selectedDeliveryDate) => {
  const nextDeliveryDateMidDay = getDateFromString(selectedDeliveryDate);
  nextDeliveryDateMidDay.set('hour', 12);
  return getDateDiffInHours(nextDeliveryDateMidDay);
};

const getUniqueSelectedDdms = (cart, deliveryDayMarketplaceDtos) => {
  const selectedDeliveryDayMarketplaces = [];
  cart.forEach((cartOrder) => {
    const ddm = deliveryDayMarketplaceDtos.find((ddmDto) => ddmDto.marketplaceId === cartOrder.marketplace.id);
    if (ddm) {
      selectedDeliveryDayMarketplaces.push(ddm.selectedDeliveryDayMarketplace);
    }
  });

  return selectedDeliveryDayMarketplaces.filter(
    (value, index, self) => self.findIndex((ddmDto) => ddmDto.deliveryDayId === value.deliveryDayId) === index
  );
};

const getUniqueDeliveryDayIdsFromCart = (cart, deliveryDayMarketplaceDtos) => {
  const uniqueDdms = getUniqueSelectedDdms(cart, deliveryDayMarketplaceDtos);
  return uniqueDdms.map((ddm) => ddm.deliveryDayId);
};

const shouldOfferLaterDelivery = (cart, deliveryDayMarketplaceDtos, newDeliveryDayId) => {
  const uniqueSelectedDeliveryDays = getUniqueSelectedDdms(cart, deliveryDayMarketplaceDtos);

  return uniqueSelectedDeliveryDays.length === 1 && newDeliveryDayId !== uniqueSelectedDeliveryDays[0].deliveryDayId;
};

const getCartMarketplaceIds = (cart, newMarketplaceId) => {
  const existingCartMarketplaceIds = cart.map((cartOrder) => cartOrder.marketplace.id);

  if (newMarketplaceId) {
    // need to add newMarketplaceId because cart hasn't refreshed yet where this func is called
    return [...existingCartMarketplaceIds, newMarketplaceId];
  } else {
    return existingCartMarketplaceIds;
  }
};

const getItemName = (item, formatMessage) => {
  const measurementUnitKey = item.measurementUnit
    ? Object.keys(ITEM_MEASUREMENT_UNIT).find((imu) => ITEM_MEASUREMENT_UNIT[imu].name === item.measurementUnit)
    : null;

  if (measurementUnitKey && item.standardUnitQuantity) {
    return `${item.name}, ${item.standardUnitQuantity}${formatMessage({
      id: ITEM_MEASUREMENT_UNIT[measurementUnitKey].shorthandTranslation,
    })}`;
  } else {
    return item.name;
  }
};

const getItemMarketplace = (item) => {
  return item.marketplaces.length ? item.marketplaces[0] : null;
};

const groupBy = function (array, key) {
  return array.reduce(function (result, elem) {
    (result[elem[key]] = result[elem[key]] || []).push(elem);
    return result;
  }, {});
};

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

const getSecondCategoryTranslation = (secondLevelCategory, locale, intl) => {
  const categoryTranslation = `item.second.level.category.${secondLevelCategory.name}`;

  return secondLevelCategory.translationEN && locale === 'en'
    ? secondLevelCategory.translationEN
    : secondLevelCategory.translationEE && locale === 'et'
    ? secondLevelCategory.translationEE
    : intl.messages[categoryTranslation]
    ? intl.formatMessage({ id: categoryTranslation })
    : secondLevelCategory.name;
};

const getSubscriptionProducts = ({
  currentUser,
  isDev = false,
  fruitBoxQuantity = 0,
  setFruitBoxQuantity = () => {},
  vegBoxQuantity = 0,
  setVegBoxQuantity = () => {},
  orangeBoxQuantity = 0,
  setOrangeBoxQuantity = () => {},
  officeBoxQuantity = 0,
  setOfficeBoxQuantity = () => {},
}) => {
  return [
    ...(currentUser?.activeBusinessProfile
      ? [
          {
            title: 'subscription.product.office.box.title',
            price: 22,
            setQuantity: setOfficeBoxQuantity,
            quantity: officeBoxQuantity,
            image: officeBox,
            content: [
              'Õun "fuji"',
              'Õun "royal gala"',
              'Õun "Golden"',
              'Kiivi "hayward"',
              'Banaan "Platano"',
              'Apelsin "lane late"',
              'Sidrun',
            ],
            weeklyStripeId: isDev ? 'price_1MoPxoGzShYhIwHpnMlDmhTp' : 'price_1MoQK8GzShYhIwHp8e2PvbMq',
            biWeeklyStripeId: isDev ? 'price_1MoPxoGzShYhIwHpG9znbzkS' : 'price_1MoQK8GzShYhIwHpVNQR3fSm',
          },
        ]
      : []),
    {
      title: 'subscription.product.fruit.box.title',
      price: 22.5,
      setQuantity: setFruitBoxQuantity,
      quantity: fruitBoxQuantity,
      image: fruitBox,
      content: [
        'Mahe nispero',
        'Mahe kiivi “hayward”',
        'Mahe platano',
        'Mahe apelsin “lane late”',
        'Mahe punane greip',
        'Mahe õun “royal” ja “golden”',
      ],
      weeklyStripeId: isDev ? 'price_1MhYgUGzShYhIwHpQwcUEAmX' : 'price_1Miet4GzShYhIwHpcErwPNtb',
      biWeeklyStripeId: isDev ? 'price_1MhYgnGzShYhIwHpHA2pNVBJ' : 'price_1Miet4GzShYhIwHpGdbxF9GI',
    },
    {
      title: 'subscription.product.veg.box.title',
      price: 22.5,
      setQuantity: setVegBoxQuantity,
      quantity: vegBoxQuantity,
      image: vegBox,
      content: [
        'Mahe avokaado “hass”',
        'Mahe tomat “rama”',
        'Mahe kurk',
        'Mahe romanesco brokkoli',
        'Mahe mini roomasalat',
        'Mahe baklažaan',
      ],
      weeklyStripeId: isDev ? 'price_1MnjLwGzShYhIwHpF8vrIdD0' : 'price_1MnlLeGzShYhIwHp6kRUthAF',
      biWeeklyStripeId: isDev ? 'price_1MnjLwGzShYhIwHpzdl2uS90' : 'price_1MnlLeGzShYhIwHpkF9b1lwf',
    },
    {
      title: 'subscription.product.orange.box.title',
      price: 12.5,
      setQuantity: setOrangeBoxQuantity,
      quantity: orangeBoxQuantity,
      image: orangeBox,
      weeklyStripeId: isDev ? 'price_1MnkMSGzShYhIwHp8s5RvrYn' : 'price_1MnlMOGzShYhIwHpkBzyrXtG',
      biWeeklyStripeId: isDev ? 'price_1MnkMSGzShYhIwHp248alU5K' : 'price_1MnlMOGzShYhIwHpSsCyYsgD',
    },
  ];
};

const utils = {
  getErrorMessageFromResponse,
  getUserLocale,
  isNormalInteger,
  userIsAdmin,
  isFunction,
  isValidUrl,
  getDateFromString,
  getNowDate,
  getFormattedDateFromString,
  getFormattedDateFromMomentObj,
  getFormattedDateRangeFromStrings,
  getDeliveryRange,
  getRoundedPrice,
  getDeliveryDaysFromCart,
  getCartTotalPrice,
  convertToUrlString,
  trackEvent,
  getDateDiffInHours,
  getFormattedTimeRange,
  shouldCheckCutoffTimeForItem,
  getSelectedDdmDiffFromNowInHours,
  shouldOfferLaterDelivery,
  getUniqueSelectedDdms,
  getUniqueDeliveryDayIdsFromCart,
  getCartMarketplaceIds,
  isString,
  getItemName,
  getItemMarketplace,
  groupBy,
  shuffleArray,
  getSecondCategoryTranslation,
  getSubscriptionProducts,
};

export default utils;
