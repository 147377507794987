import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import PriceFormatter from '../components/priceFormatter';
import { connect } from 'react-redux';
import CartButton from '../components/cartButton';
import PREFERRED_PRICE_DISPLAY from '../components/constants/preferredPriceDisplay';
import utils from '../utils/utils';
import { useMarketplace } from './apiHooks';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { Link } from 'react-router-dom';

const ItemViewContent = ({ item, onNavigate = () => {} }) => {
  const [activeImage, setActiveImage] = useState(null);
  const [contentTab, setContentTab] = useState(null);

  const {
    marketplace,
    isFetching: marketplacesLoading,
    isError: isMarketplacesError,
    error: marketplacesError,
  } = useMarketplace({
    marketplaceId: item.marketplaces.length ? item.marketplaces[0].marketplaceId : null,
  });

  useEffect(() => {
    setContentTab('description');
    setActiveImage(null);
  }, [item]);

  const itemDescription = {
    __html: item.description,
  };

  const itemIngredients = {
    __html: item.ingredients,
  };

  const showUnitPrice =
    item.priceFields.unitPrice && item.priceFields.preferredPriceDisplay === PREFERRED_PRICE_DISPLAY.unitPrice;

  const { formatMessage } = useIntl();

  return (
    <>
      <div className="row item-view">
        <Helmet>
          <title>{utils.getItemName(item, formatMessage)}</title>
          <meta name="description" content={item.shortDescription} />
        </Helmet>

        <div className="col-12 col-lg-6 col-xl-5">
          <div className="item-view__images">
            <div className={`item-view__images-image m-b-2`}>
              {item.images.length ? (
                <img src={activeImage?.mainImage || item.images[0].mainImage} alt={`${item.name}`} />
              ) : (
                ''
              )}
            </div>
            {item.images.length > 1 ? (
              <div className="item-view__images-thumbs">
                {item.images.map((image, index) => (
                  <div
                    key={image.id}
                    className={`item-view__images-thumbs-thumb ${
                      activeImage?.id === image.id || (!activeImage && index === 0) ? 'm-active' : ''
                    }`}
                    onClick={() => setActiveImage(image)}
                  >
                    <img src={image.thumbnailImage} alt="item" />
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-7">
          <div className="item-view__details">
            {item.marketplaces.length ? (
              <div className="text-muted item-view__details-marketplace m-b-3">
                {item.marketplaces[0].marketplaceName}
              </div>
            ) : (
              ''
            )}
            <h2 className="item-view__details-title m-b-3">{utils.getItemName(item, formatMessage)}</h2>
            <p className="m-b-3 text-large text-semi-bold">
              {item.priceFields.comparedToPrice ? (
                <>
                  <span className="">
                    <s className="m-r-1">
                      <PriceFormatter price={item.priceFields.comparedToPrice} currency={item.priceFields.currency} />
                    </s>
                    <span className="text-discount">
                      <PriceFormatter price={item.priceFields.price} currency={item.priceFields.currency} />
                    </span>
                  </span>
                </>
              ) : (
                <span>
                  <PriceFormatter price={item.priceFields.price} currency={item.priceFields.currency} />
                </span>
              )}{' '}
              {showUnitPrice ? <>/ {item.priceFields.standardUnitQuantity} kg</> : ''}
            </p>

            <div className="item-view__details-button">
              <CartButton item={item} small={true} blockButton={true} />
            </div>

            <hr className="m-t-6 m-b-6" />

            <ul className="item-view__details-links list-inline list-unstyled d-none">
              <li
                className={`list-inline-item item-view__details-links-link ${
                  contentTab === 'description' ? 'm-active' : ''
                }`}
                onClick={() => setContentTab('description')}
              >
                <FormattedMessage id={'item.view.right.links.description'} />
              </li>
              {item.ingredients ? (
                <li
                  className={`list-inline-item item-view__details-links-link ${
                    contentTab === 'ingredients' ? 'm-active' : ''
                  }`}
                  onClick={() => setContentTab('ingredients')}
                >
                  <FormattedMessage id={'item.view.right.links.ingredients'} />
                </li>
              ) : (
                ''
              )}
            </ul>

            {contentTab === 'description' ? (
              <div
                className="item-view__details-description text-rich-text m-b-3"
                dangerouslySetInnerHTML={itemDescription}
              />
            ) : (
              ''
            )}

            {item.ingredients ? (
              <div className="item-view__details-ingredients text-rich-text p-a-3 m-b-6">
                <div className="m-b-1">
                  <small>
                    <strong>
                      <FormattedMessage id={'item.view.right.ingredients.label'} />:
                    </strong>
                  </small>
                </div>
                <div dangerouslySetInnerHTML={itemIngredients} />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <div className="row item-bottom">
        <Loader show={marketplacesLoading} />
        {isMarketplacesError ? <ErrorMessage error={utils.getErrorMessageFromResponse(marketplacesError)} /> : ''}

        <div className="col-12">
          {marketplace ? (
            <div className="row align-items-center">
              <div className="col-12">
                <Link to={`/tootja/${marketplace.id}`}>
                  <h3 className="item-bottom__marketplace-name">{marketplace.name}</h3>
                </Link>
              </div>
              <div className="col-12 col-lg-6 col-xl-4">
                <Link to={`/tootja/${marketplace.id}`}>
                  <div
                    className="item-bottom__marketplace-image m-b-2"
                    style={{
                      backgroundImage: marketplace?.image ? 'url("' + marketplace.image.mainImage + '")' : '',
                    }}
                  ></div>
                </Link>
              </div>
              <div className="col-12 col-lg-6 col-xl-8 align-middle">
                {marketplace.description ? <p>{marketplace.description}</p> : ''}
                <Link to={`/tootja/${marketplace.id}`} className="btn btn-default btn-small">
                  <FormattedMessage id={'item.view.right.producer.view'} />
                </Link>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

ItemViewContent.propTypes = {
  item: PropTypes.object.isRequired,
  marketplace: PropTypes.object,
};

ItemViewContent.defaultProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default connect(mapStateToProps)(ItemViewContent);
