import React, { useEffect, useRef, useState } from 'react';
import { useItems, useMarketplaces } from './apiHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import utils from '../utils/utils';
import { FormattedMessage, useIntl } from 'react-intl';
import CtaGroupItems from '../ctaGroup/ctaGroupItems';
import MarketplacesPreview from '../components/marketplacesPreview';

function SearchPopup({ typedQuery, setTypedQuery, close }) {
  const [query, setQuery] = useState(null); // for triggering actual search
  const queryTimer = useRef(null);
  const { formatMessage } = useIntl();
  const { items, isFetching: itemsFetching, isError: isItemsError, error: itemsError } = useItems({ query });

  const {
    marketplaces,
    isFetching: marketplacesFetching,
    isError: isMarketplacesError,
    error: marketplacesError,
  } = useMarketplaces({ query });

  useEffect(() => {
    clearTimeout(queryTimer.current);
    queryTimer.current = setTimeout(() => {
      setQuery(typedQuery);
      window.scrollTo(0, 0);
    }, 500);
  }, [typedQuery]);

  return (
    <div className="search">
      <div className="sidebar-content p-t-0">
        <div className="row search__header">
          <div className="col-10">
            <div className="d-none d-sm-block">
              <h2>
                <FormattedMessage id="search.title" />
              </h2>
            </div>
            <div className="d-sm-none">
              <div className="form-group field field-string">
                <input
                  onClick={(e) => setTypedQuery(e.target.value)}
                  className="form-control"
                  placeholder={formatMessage({ id: 'navbar.search.placeholder' })}
                  type="text"
                  value={typedQuery}
                  onChange={(e) => setTypedQuery(e.target.value)}
                />
              </div>
            </div>
            {query && query.length < 3 ? (
              <p>
                <FormattedMessage id="search.query.too.small" />
              </p>
            ) : (
              ''
            )}
          </div>
          <div className="col-2">
            <div onClick={() => close()} className="search__close">
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </div>
          </div>
        </div>

        <div>
          <h3 className="m-b-3">
            <FormattedMessage id="search.items.title" />
          </h3>
          <Loader show={itemsFetching} />
          {isItemsError ? <ErrorMessage error={utils.getErrorMessageFromResponse(itemsError)} /> : ''}
        </div>
        <div className="m-b-6">
          {items && items.length ? (
            <CtaGroupItems items={items} fetchMoreItems={() => {}} onNavigate={close} />
          ) : items && !items.length ? (
            <div>
              <FormattedMessage id="search.items.no.items.found" />
            </div>
          ) : (
            ''
          )}
        </div>

        <div>
          <h3 className="m-b-3">
            <FormattedMessage id="search.marketplaces.title" />
          </h3>
          <Loader show={marketplacesFetching} />
          {isMarketplacesError ? <ErrorMessage error={utils.getErrorMessageFromResponse(marketplacesError)} /> : ''}
        </div>
        <div className="m-b-6 search__marketplaces">
          {marketplaces && marketplaces.length ? (
            <MarketplacesPreview marketplaces={marketplaces} />
          ) : marketplaces && !marketplaces.length ? (
            <div>
              <FormattedMessage id="search.marketplaces.no.marketplaces.found" />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

SearchPopup.propTypes = {};

SearchPopup.defaultProps = {};

export default SearchPopup;
