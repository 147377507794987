import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import { useAddressDelete, useAddresses } from './apiHooks';
import ErrorMessage from '../components/errorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faMapMarkerAlt, faPlusCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import AddressModal from './addressModal';
import Loader from '../components/loader';

function UserAddressesSelector({
  useProfileAddress,
  setUseProfileAddress,
  currentUser,
  setSelectedAddress,
  selectedAddress,
  note,
  setNote,
  showNoteOption,
  showSubscriptionCopy,
}) {
  const { formatMessage } = useIntl();

  const { addresses, isLoading: loading, isError, error } = useAddresses({ userId: currentUser.id });
  const { deleteAddress, isLoading: deleteLoading, isError: isDeleteError, error: deleteError } = useAddressDelete();

  const [editAddress, setEditAddress] = useState(null);
  const [showNewAddressForm, setShowNewAddressForm] = useState(false);

  const selectAddress = (newAddress) => {
    if (newAddress.id === selectedAddress?.id) {
      setSelectedAddress(null);
    } else {
      setSelectedAddress(newAddress);
    }
  };

  const activeAddress =
    selectedAddress ||
    (currentUser.activeBusinessProfile
      ? currentUser.activeBusinessProfile.deliveryAddress || currentUser.activeBusinessProfile.address
      : currentUser.address);

  const recipientName =
    selectedAddress || currentUser.activeBusinessProfile ? (
      <p>
        <FormattedMessage
          id={'cart.orders.delivery.recipient.name'}
          values={{
            name: selectedAddress?.customerName || currentUser.activeBusinessProfile.companyName,
          }}
        />
      </p>
    ) : (
      ''
    );

  return (
    <div className="user-addresses-selector">
      {isError || isDeleteError ? <ErrorMessage error={utils.getErrorMessageFromResponse(error || deleteError)} /> : ''}
      <Loader show={loading || deleteLoading} />

      <div>
        <div className="m-b-3">
          {recipientName}
          <p>
            <FormattedMessage
              id={activeAddress.unit ? 'cart.orders.delivery.address.with.unit' : 'cart.orders.delivery.address'}
              values={{
                street: activeAddress.street,
                unit: activeAddress.unit,
              }}
            />
          </p>
          <label className="custom-checkbox text-muted">
            <span>
              <FormattedMessage
                id={
                  showSubscriptionCopy
                    ? 'cart.orders.delivery.address.send.to.other.address'
                    : 'cart.orders.delivery.address.send.to.friend'
                }
              />
            </span>
            <input
              type="checkbox"
              checked={!useProfileAddress}
              onChange={() => {
                if (!useProfileAddress) {
                  setSelectedAddress(null);
                }
                setUseProfileAddress(!useProfileAddress);
              }}
            />
            <div className="custom-checkbox-input" />
          </label>
        </div>
      </div>

      {!useProfileAddress ? (
        <div className="m-t-2 m-b-2">
          {addresses?.length ? (
            <div className="m-b-4">
              {addresses.map((userAddress) => {
                return (
                  <div className="cart-address m-b-3" key={userAddress.id}>
                    <div className="row">
                      <div className="col-12 col-sm-1 p-t-3">
                        <label className="custom-checkbox text-muted">
                          <input
                            type="checkbox"
                            checked={userAddress.id === selectedAddress?.id}
                            onChange={() => selectAddress(userAddress)}
                          />
                          <div className="custom-checkbox-input" />
                        </label>
                      </div>
                      <div className="col-12 col-sm-7" onClick={() => selectAddress(userAddress)}>
                        {userAddress.customerName ? (
                          <h4 className="cart-address__title m-b-1">{userAddress.customerName}</h4>
                        ) : (
                          ''
                        )}
                        <div className="m-b-2">
                          <span className="m-r-1">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </span>{' '}
                          {userAddress.street}
                        </div>
                        <div>
                          <button className="btn btn-primary btn-small d-none">
                            <FormattedMessage id={'cart.orders.delivery.addresses.address.select'} />
                          </button>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 p-t-1 text-right">
                        <button
                          onClick={(e) => {
                            setEditAddress(userAddress);
                            e.stopPropagation();
                          }}
                          className="btn btn-normal-link btn-small"
                        >
                          <FontAwesomeIcon icon={faCog} />{' '}
                          <FormattedMessage id={'cart.orders.delivery.addresses.address.edit'} />
                        </button>
                        <br />
                        <button
                          onClick={(e) => {
                            if (
                              window.confirm(
                                formatMessage({ id: 'cart.orders.delivery.addresses.address.delete.confirm' })
                              )
                            ) {
                              deleteAddress({ address: userAddress });
                            }

                            e.stopPropagation();
                          }}
                          className="btn btn-normal-link btn-small"
                        >
                          <FontAwesomeIcon icon={faTrash} />{' '}
                          <FormattedMessage id={'cart.orders.delivery.addresses.address.delete'} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          )}
          <button onClick={() => setShowNewAddressForm(true)} className="btn btn-default btn-small m-b-2">
            <FontAwesomeIcon icon={faPlusCircle} /> <FormattedMessage id={'cart.orders.delivery.addresses.add.new'} />
          </button>
          {showNoteOption ? (
            <div className="">
              <div className="form-group field field-string">
                <label className="control-label">
                  <FormattedMessage id={'cart.orders.delivery.addresses.note.label'} />
                </label>
                <input
                  className="form-control"
                  placeholder=""
                  autoFocus
                  type="text"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <p className={`help-block ${note && note.length > 80 ? 'text-danger' : ''}`}>
                  {note ? `${note.length}/80` : ''} <FormattedMessage id={'cart.orders.delivery.addresses.note.help'} />
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}

      <Modal show={showNewAddressForm} onHide={() => setShowNewAddressForm(false)}>
        <div onClick={() => setShowNewAddressForm(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <AddressModal
          onSuccess={(address) => {
            selectAddress(address);
            setShowNewAddressForm(false);
          }}
        />
      </Modal>

      <Modal show={!!editAddress} onHide={() => setEditAddress(null)}>
        <div onClick={() => setEditAddress(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {editAddress ? <AddressModal existingAddress={editAddress} onSuccess={() => setEditAddress(null)} /> : ''}
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    address: state.cart.address,
  };
}

export default injectIntl(connect(mapStateToProps)(UserAddressesSelector));
