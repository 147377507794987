import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function SelfPickupConfirmModal({ onSuccess }) {
  return (
    <div className="b-modal">
      <div className="self-pickup-modal">
        <h2 className="m-b-6">
          <FormattedMessage id="self.pickup.modal.title" />
        </h2>

        <p>
          <FormattedMessage id="self.pickup.modal.description" />
        </p>

        <div className="self-pickup-modal__cta">
          <button className="btn btn-primary" onClick={onSuccess}>
            <FormattedMessage id="self.pickup.modal.cta" />
          </button>
        </div>
      </div>
    </div>
  );
}

SelfPickupConfirmModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default injectIntl(connect(mapStateToProps)(SelfPickupConfirmModal));
