import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import ItemViewContent from '../item/itemViewContent';
import Modal from 'react-bootstrap/Modal';
import ItemPreview from '../item/itemPreview';
import { withRouter } from 'react-router-dom';
import utils from '../utils/utils';
import EVENT_NAME from '../components/constants/eventName';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Loader from '../components/loader';

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

  return (
    <span
      onClick={() => {
        scrollPrev();
      }}
      className={`horizontal-scroll-arrow m-left ${isFirstItemVisible ? 'd-none' : ''}`}
    >
      <FontAwesomeIcon size="lg" icon={faArrowCircleLeft} />
    </span>
  );
}

function RightArrow({ fetchMoreItems }) {
  const [itemsFetched, setItemsFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLastItemVisible, scrollNext, initComplete } = React.useContext(VisibilityContext);

  // needed so we won't fetch more items for maketplaces where all items are already visible
  const [canScroll, setCanScroll] = useState(null);

  useEffect(() => {
    if (isLastItemVisible && !itemsFetched && canScroll) {
      setLoading(true);
      fetchMoreItems();
      setItemsFetched(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [isLastItemVisible, canScroll, fetchMoreItems, itemsFetched]);

  useEffect(() => {
    if (initComplete) {
      setCanScroll(!isLastItemVisible);
    }
    // eslint-disable-next-line
  }, [initComplete]);

  return (
    <span
      onClick={() => {
        scrollNext();
      }}
      className={`horizontal-scroll-arrow m-right ${isLastItemVisible ? 'd-none' : ''}`}
    >
      {loading ? <Loader show={true} /> : <FontAwesomeIcon size="lg" icon={faArrowCircleRight} />}
    </span>
  );
}

const CtaGroupItems = ({ items, fetchMoreItems, onNavigate = () => {} }) => {
  const [modalItem, setModalItem] = useState(null);

  const openItemModal = (item) => {
    if (item) {
      utils.trackEvent(EVENT_NAME.itemView, {
        id: item.id,
        name: item.name,
        source: 'ctaGroup',
      });
    }
    setModalItem(item);
  };

  const goToNextItem = () => {
    if (!modalItem || !items) {
      return;
    }

    const currentItemIndex = items.findIndex((item) => item.id === modalItem.id);
    if (currentItemIndex < items.length - 1 && items[currentItemIndex + 1]) {
      openItemModal(items[currentItemIndex + 1]);
    }
  };

  const goToPreviousItem = () => {
    if (!modalItem || !items) {
      return;
    }

    const currentItemIndex = items.findIndex((item) => item.id === modalItem.id);
    if (currentItemIndex > 0 && items[currentItemIndex - 1]) {
      openItemModal(items[currentItemIndex - 1]);
    }
  };

  function handleKeyUpEvent(e) {
    switch (e.key) {
      case 'ArrowRight':
        goToNextItem();
        break;
      case 'ArrowLeft':
        goToPreviousItem();
        break;
      default:
        break;
    }
  }

  const nextArrow =
    modalItem && items.length && items[items.length - 1].id !== modalItem.id ? (
      <div onClick={goToNextItem} className="b-modal-next">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    ) : (
      ''
    );

  const previousArrow =
    modalItem && items.length && items[0].id !== modalItem.id ? (
      <div onClick={goToPreviousItem} className="b-modal-previous">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    ) : (
      ''
    );

  return (
    <div className="cta-group__items m-b-6" onKeyUp={handleKeyUpEvent}>
      <div className="membo-horizontal-scroll">
        {items ? (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={<RightArrow fetchMoreItems={fetchMoreItems} />}
            onWheel={() => {}}
          >
            {items.map((item) => (
              <div itemId={item.id} key={item.id} className="cta-group__items-item">
                <ItemPreview item={item} onItemClick={() => openItemModal(item)} size="small" />
              </div>
            ))}
          </ScrollMenu>
        ) : (
          ''
        )}
        <span className="membo-scroll-fade" />
      </div>

      <Modal show={!!modalItem} onHide={() => openItemModal(null)} size="xl">
        <div onClick={() => openItemModal(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} />
        </div>

        {nextArrow}
        {previousArrow}

        <div className="b-modal m-no-padding">
          {modalItem ? (
            <div className="container-fluid marketplace-view-item-modal-container">
              <ItemViewContent
                item={modalItem}
                onNavigate={() => {
                  openItemModal(null);
                  onNavigate();
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
    </div>
  );
};

CtaGroupItems.propTypes = {
  items: PropTypes.array.isRequired,
  fetchMoreItems: PropTypes.func.isRequired,
};

CtaGroupItems.defaultProps = {};

export default withRouter(CtaGroupItems);
