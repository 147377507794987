import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";
import GeneralForm from "../components/generalForm";
import apiCalls from "../utils/apiCalls";
import utils from "../utils/utils";
import {parse} from "qs";

const PasswordResetPage = ({intl, location}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordFields, setPasswordFields] = useState({password: ''});

  const searchParams = parse(location.search, {ignoreQueryPrefix: true});

  const onSubmit = () => {
    setLoading(true);
    setErrorMessage(null);

    apiCalls.resetPassword({password: passwordFields.password, userId: searchParams.userId, token: searchParams.token})
      .then((response) => {
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const formSchema = {
    "type": "object",
    "required": [],
    "properties": {
      "password": {
        "type": "string",
        "title": intl.formatMessage({id: "password.reset.form.field.password.label"})
      },
    }
  };

  const uiSchema = {
    "password": {
      "ui:widget": "password"
    }
  };

  const content = showSuccess? (
    <>
      <h1>
        <FormattedMessage id="password.reset.success.title"/>
      </h1>

      <p>
        <FormattedMessage id="password.reset.success.description"/>
      </p>
    </>
  ): (
    <>
      <h1>
        <FormattedMessage id="password.reset.title"/>
      </h1>

      <p>
        <FormattedMessage id="password.reset.description"/>
      </p>

      <GeneralForm title={null}
                   onChange={(event) => setPasswordFields(event.formData)}
                   onSubmit={onSubmit}

                   formSchema={formSchema}
                   formUiSchema={uiSchema}
                   formFields={{}}

                   formData={passwordFields}
                   buttonCopy={"password.reset.form.cta"}
                   errorMessage={errorMessage}
                   loading={loading}
                   hideImageUpload={true}/>
    </>
  );

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'password.reset.trigger.helmet.title'}) }</title>
        <meta name="description" content=""/>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          {content}

        </div>
      </div>

      <Footer/>
    </div>
  );
};

PasswordResetPage.propTypes = {};

PasswordResetPage.defaultProps = {};

export default injectIntl(PasswordResetPage);
