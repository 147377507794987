import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import box from './assets/kast-pv-kv.jpg';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import EVENT_NAME from '../components/constants/eventName';
import { getCurrentUser } from '../user/currentUserActions';
import CustomSelect from '../components/customFields/customSelect';
import UserAddressesSelector from '../cart/userAddressesSelector';
import { useSubscriptionCreate, useSubscriptions } from './apiHooks';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import PaymentMethod from '../components/paymentMethod';
import PaymentMethodSelectionModal from '../cart/paymentMethodSelectionModal';
import NewPaymentMethodModal from '../cart/newPaymentMethodModal';
import { getPaymentMethods } from '../paymentMethods/paymentMethodsActions';
import ReactPixel from 'react-facebook-pixel';
import SubscriptionPreview from './subscriptionPreview';
import SubscriptionProduct from './subscriptionProduct';

const SubscriptionPage = ({ currentUser, selectedPaymentMethod, paymentMethods, getPaymentMethods, history }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    // refresh stripe is needed for when new card add requires redirect back to cart
    getPaymentMethods({ userId: currentUser.id, refreshStripe: true });
  }, [getPaymentMethods, currentUser]);

  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  const weeklyDeliveryId = isDev ? 'price_1MhTvWGzShYhIwHpbK2n0v8C' : 'price_1MietPGzShYhIwHpcCuT1qIL';
  const biWeeklyDeliveryId = isDev ? 'price_1MhTvWGzShYhIwHpLZVlKQG9' : 'price_1MietPGzShYhIwHpLKw0PFhp';

  const intervalOptions = [
    {
      value: {
        deliveryStripePriceId: weeklyDeliveryId,
        deliveryItemName: formatMessage({
          id: 'subscription.product.price.interval.select.options.weekly.delivery.name',
        }),
        deliveryDay: 'MONDAY',
        frequency: 'WEEKLY',
      },
      label: formatMessage({ id: 'subscription.product.price.interval.select.options.weekly.monday' }),
    },
    {
      value: {
        deliveryStripePriceId: weeklyDeliveryId,
        deliveryItemName: formatMessage({
          id: 'subscription.product.price.interval.select.options.weekly.delivery.name',
        }),
        deliveryDay: 'FRIDAY',
        frequency: 'WEEKLY',
      },
      label: formatMessage({ id: 'subscription.product.price.interval.select.options.weekly.friday' }),
    },
    {
      value: {
        deliveryStripePriceId: biWeeklyDeliveryId,
        deliveryItemName: formatMessage({
          id: 'subscription.product.price.interval.select.options.bi.weekly.delivery.name',
        }),
        deliveryDay: 'MONDAY',
        frequency: 'BI_WEEKLY',
      },
      label: formatMessage({ id: 'subscription.product.price.interval.select.options.bi.weekly.monday' }),
    },
    {
      value: {
        deliveryStripePriceId: biWeeklyDeliveryId,
        deliveryItemName: formatMessage({
          id: 'subscription.product.price.interval.select.options.bi.weekly.delivery.name',
        }),
        deliveryDay: 'FRIDAY',
        frequency: 'BI_WEEKLY',
      },
      label: formatMessage({ id: 'subscription.product.price.interval.select.options.bi.weekly.friday' }),
    },
  ];

  const timeRangeOptions = [
    {
      value: '12:00-15:00',
      label: '12:00-15:00',
      disabledServiceAreaIds: [2],
    },
    {
      value: '15:00-18:00',
      label: '15:00-18:00',
      disabledServiceAreaIds: [],
    },
    {
      value: '18:00-21:00',
      label: '18:00-21:00',
      disabledServiceAreaIds: [],
    },
  ];

  const [showNewSubscription, setShowNewSubscription] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(intervalOptions[0].value);

  // TODO set selected time range based on selected address area ID
  const [selectedTimeRange, setSelectedTimeRange] = useState(timeRangeOptions[1].value);
  const [showAddressSelection, setShowAddressSelection] = useState(false);

  const [showPaymentMethodSelection, setShowPaymentMethodSelection] = useState(false);
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showSubscriptionSuccessModal, setShowSubscriptionSuccessModal] = useState(false);
  const [fruitBoxQuantity, setFruitBoxQuantity] = useState(1);
  const [officeBoxQuantity, setOfficeBoxQuantity] = useState(0);
  const [vegBoxQuantity, setVegBoxQuantity] = useState(1);
  const [orangeBoxQuantity, setOrangeBoxQuantity] = useState(0);
  const { subscriptions, isLoading: loading, isError, error } = useSubscriptions({ userId: currentUser.id });

  useEffect(() => {
    if (currentUser.activeBusinessProfile) {
      setFruitBoxQuantity(0);
      setVegBoxQuantity(0);
      setOfficeBoxQuantity(1);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const {
    createSubscription,
    isLoading: createLoading,
    isError: isCreateError,
    error: createError,
  } = useSubscriptionCreate((subscription) => {
    ReactPixel.track('Subscription', {
      currency: 'eur',
      amount: subscription.items.reduce((result, subscriptionItem) => {
        return result + subscriptionItem.itemPrice;
      }, 0),
    });
    history.push({
      pathname: `/subscription-confirm/${subscription.id}`,
    });
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    utils.trackEvent(EVENT_NAME.orderSuccess);
    getCurrentUser(); // refreshing current user so coupons get updated
    // eslint-disable-next-line
  }, []);

  const serviceAreaId = selectedAddress?.serviceAreaId || currentUser.serviceAreaId;

  const products = utils.getSubscriptionProducts({
    currentUser,
    isDev,
    fruitBoxQuantity,
    setFruitBoxQuantity,
    vegBoxQuantity,
    setVegBoxQuantity,
    orangeBoxQuantity,
    setOrangeBoxQuantity,
    officeBoxQuantity,
    setOfficeBoxQuantity,
  });

  const totalPrice = products.reduce(
    (result, product) => result + utils.getRoundedPrice(product.price * product.quantity),
    0
  );

  const createSubscriptionFn = () => {
    createSubscription({
      subscription: {
        paymentMethodId: selectedPaymentMethod?.id,
        deliveryDay: selectedInterval.deliveryDay,
        timeRange: selectedTimeRange,
        items: [
          ...products
            .filter((product) => product.quantity > 0)
            .map((product) => ({
              stripePriceId:
                selectedInterval.frequency === 'BI_WEEKLY' ? product.biWeeklyStripeId : product.weeklyStripeId,
              itemName: formatMessage({ id: product.title }),
              quantity: product.quantity,
            })),
          {
            stripePriceId: selectedInterval.deliveryStripePriceId,
            itemName: selectedInterval.deliveryItemName,
            quantity: 1,
          },
        ],
        addressId: selectedAddress?.id,
      },
    });
  };

  return (
    <div className="content-page">
      <Helmet>
        <title>{formatMessage({ id: 'subscription.helmet.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <Loader show={loading} />
            {isError ? <ErrorMessage error={utils.getErrorMessageFromResponse(error)} /> : ''}

            {subscriptions?.length ? (
              <div className="m-b-6">
                <h2>
                  <FormattedMessage id="subscription.my.subscriptions.title" />
                </h2>
                {subscriptions.map((subscription) => (
                  <SubscriptionPreview subscription={subscription} timeRangeOptions={timeRangeOptions} />
                ))}
              </div>
            ) : (
              ''
            )}

            {!subscriptions?.length || showNewSubscription ? (
              <>
                <h2 className="m-b-3">
                  <FormattedMessage id="subscription.product.title" />
                </h2>
                <p>
                  <FormattedMessage id="subscription.product.description" />
                </p>
                <p>
                  <FormattedMessage id="subscription.product.description.p2" />
                </p>

                <div className="row">
                  <div className="col-12 d-none">
                    <img className="img-fluid img-border-radius" src={box} alt="Membo fruit box" />
                  </div>
                  <div className="col-12">
                    {products.map((product) => (
                      <SubscriptionProduct product={product} />
                    ))}

                    <div className="d-none">
                      <div className="form-group m-b-2">
                        <CustomSelect
                          onChange={(newValue) => setSelectedInterval(newValue)}
                          schema={{
                            title: formatMessage({ id: 'subscription.product.price.interval.select' }),
                            options: intervalOptions,
                          }}
                          formData={selectedInterval}
                        />
                      </div>
                      <div className="form-group m-b-3">
                        <CustomSelect
                          onChange={(newValue) => setSelectedTimeRange(newValue)}
                          schema={{
                            title: formatMessage({ id: 'subscription.product.time.range.select' }),
                            options: timeRangeOptions.filter(
                              (tr) => !serviceAreaId || tr.disabledServiceAreaIds.indexOf(serviceAreaId) === -1
                            ),
                          }}
                          formData={selectedTimeRange}
                        />
                      </div>
                      <div className="m-b-3">
                        <UserAddressesSelector
                          setSelectedAddress={setSelectedAddress}
                          selectedAddress={selectedAddress}
                          showNoteOption={false}
                          showSubscriptionCopy={true}
                          useProfileAddress={!showAddressSelection}
                          setUseProfileAddress={(newValue) => setShowAddressSelection(!newValue)}
                        />
                      </div>
                      <div>
                        <div>
                          <label>
                            <FormattedMessage id="subscription.products.price.label" />
                          </label>
                        </div>
                        <div className="m-b-3 d-flex align-items-center">
                          <span className="badge badge-light text-large">{totalPrice}€</span>{' '}
                          <span className="m-l-2 m-r-2">
                            <FormattedMessage id="subscription.product.price.delivery" values={{ amount: 2 }} />
                          </span>
                        </div>
                      </div>
                      <div className="m-b-3">
                        <div>
                          <label>
                            <FormattedMessage id="subscription.product.payment.method.label" />
                          </label>
                        </div>
                        {selectedPaymentMethod ? (
                          <>
                            <div onClick={() => setShowPaymentMethodSelection(true)}>
                              <PaymentMethod paymentMethod={selectedPaymentMethod} hidePrimaryNotice={true} />
                            </div>
                            <div>
                              <button
                                className="btn btn-normal-link"
                                onClick={() => setShowPaymentMethodSelection(true)}
                              >
                                <FormattedMessage id="cart.payment.choose.another" />
                              </button>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        {!selectedPaymentMethod ? (
                          <button onClick={() => setShowNewPaymentModal(true)} className="btn btn-normal-link">
                            <FontAwesomeIcon icon={faCreditCard} /> <FormattedMessage id="cart.payment.add.card" />
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                      {isCreateError ? <ErrorMessage error={utils.getErrorMessageFromResponse(createError)} /> : ''}
                      <div className="m-b-3">
                        <button
                          className={`btn btn-primary ${createLoading ? 'btn-loading' : ''}`}
                          disabled={createLoading}
                          onClick={() => createSubscriptionFn()}
                        >
                          <FormattedMessage id="subscription.product.cta" />
                        </button>
                      </div>
                    </div>

                    <div className="alert alert-warning">
                      <FormattedMessage id="subscription.product.closed.alert" />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <button className="btn btn-default btn-small" onClick={() => setShowNewSubscription(true)}>
                <FormattedMessage id="subscription.cta.create.new" />
              </button>
            )}
          </div>
        </div>
      </div>

      <Footer />

      <Modal show={!!showSubscriptionSuccessModal} onHide={() => setShowSubscriptionSuccessModal(false)}>
        <div onClick={() => setShowSubscriptionSuccessModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <div className="b-modal">
          <h2>
            <FormattedMessage id="subscription.success.modal.title" />
          </h2>
          <p className="m-b-6">
            <FormattedMessage id="subscription.success.modal.description" />
          </p>
          <div className="text-center">
            <button className="btn btn-primary" onClick={() => setShowSubscriptionSuccessModal(false)}>
              <FormattedMessage id="subscription.success.modal.cta" />
            </button>
          </div>
        </div>
      </Modal>

      <Modal show={showPaymentMethodSelection} onHide={() => setShowPaymentMethodSelection(false)}>
        <div onClick={() => setShowPaymentMethodSelection(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <PaymentMethodSelectionModal
          closeModal={() => setShowPaymentMethodSelection(false)}
          openNewMethodModal={() => {
            setShowPaymentMethodSelection(false);
            setShowNewPaymentModal(true);
          }}
        />
      </Modal>

      <Modal show={showNewPaymentModal} onHide={() => setShowNewPaymentModal(false)}>
        <div onClick={() => setShowNewPaymentModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {paymentMethods ? <NewPaymentMethodModal closeModal={() => setShowNewPaymentModal(false)} /> : ''}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  getPaymentMethods,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
    paymentMethods: state.paymentMethods.paymentMethods,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage));
