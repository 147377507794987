import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setSelectedServiceArea } from '../serviceAreas/serviceAreasActions';
import UserAddressForm from '../user/userAddressForm';
import { useServiceAreas } from './apiHooks';

function ServiceAreaSelectionModal({
  closeModal,
  selectedServiceCountry,
  setSelectedServiceArea,
  selectedServiceArea,
  currentUser,
}) {
  const [user, setUser] = useState(currentUser);

  const { serviceAreas } = useServiceAreas({
    country: selectedServiceCountry?.countryAlpha2Code,
  });

  const selectArea = (area) => {
    if (currentUser) {
      const newUser = Object.assign({}, user);
      newUser.serviceAreaId = area.id;
      newUser.serviceArea = area;
      setUser(newUser);
    } else {
      setSelectedServiceArea(area);
      closeModal();
    }
  };

  const selectedArea = user && user.serviceArea ? user.serviceArea : selectedServiceArea;

  return (
    <div className="b-modal">
      <div className="service-area-modal">
        <h2>
          <FormattedMessage id="service.area.modal.title" />
        </h2>
        <p>
          <FormattedMessage id="service.area.modal.description" />
        </p>

        <div className="service-area-modal__choices">
          {serviceAreas?.map((serviceArea, index) => {
            return (
              <div className="row service-area-modal__choices-choice">
                <div className="col-12">
                  <div className="m-b-3" key={index} onClick={() => selectArea(serviceArea)}>
                    <div className="service-area-choice">
                      <h3 className="service-area-choice__title">
                        {serviceArea.image ? (
                          <img className="img-circle" src={serviceArea.image.thumbnailImage} alt="" />
                        ) : (
                          ''
                        )}{' '}
                        {serviceArea.name}
                      </h3>
                      <div className="m-b-1">{serviceArea.description}</div>
                      <div>
                        {selectedArea && selectedArea.id === serviceArea.id ? (
                          <small className="text-primary">
                            <FormattedMessage id="service.area.modal.selected" />
                          </small>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {currentUser ? <UserAddressForm user={user} setUser={setUser} onSuccess={(user) => closeModal()} /> : ''}
      </div>
    </div>
  );
}

ServiceAreaSelectionModal.propTypes = {};

ServiceAreaSelectionModal.defaultProps = {};

const mapDispatchToProps = {
  setSelectedServiceArea,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedServiceArea: state.serviceAreas.selectedServiceArea,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ServiceAreaSelectionModal));
