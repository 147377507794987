import React from 'react';
import { FormattedMessage } from 'react-intl';
import { faReceipt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaymentMethodInvoice = ({ paymentMethod, hidePrimaryNotice }) => {
  return (
    <div className="payment-method m-b-1">
      <strong>
        <span className="m-r-2 text-info">
          <FontAwesomeIcon icon={faReceipt} />
        </span>{' '}
        <FormattedMessage id="payment.method.invoice" />
      </strong>{' '}
      {paymentMethod.isPrimary && !hidePrimaryNotice ? (
        <small className="text-success">
          <FormattedMessage id="payment.method.primary" />
        </small>
      ) : (
        ''
      )}
    </div>
  );
};

export default PaymentMethodInvoice;
