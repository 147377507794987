import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import etMessages from './translate.messages.et.json';
import enMessages from './translate.messages.en.json';
import etCategories from './translate.item.categories.et.json';
import enCategories from './translate.item.categories.en.json';

export const Context = React.createContext({
  locale: 'en',
  selectLanguage: () => {},
});

const local = localStorage.getItem('lang') || 'et';

const etTranslations = {
  ...etMessages,
  ...etCategories,
};

const enTranslations = {
  ...enMessages,
  ...enCategories,
};

const lang = local === 'en' ? enTranslations : etTranslations;

const LocaleWrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(newLocale) {
    setLocale(newLocale);
    if (newLocale === 'en') {
      setMessages(enTranslations);
    } else {
      setMessages(etTranslations);
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default LocaleWrapper;
