import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';

const GiftCardsPage = ({ intl }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'producer.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'producer.helmet.description' })} />
      </Helmet>
      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="gift.cards.title" />
          </h1>

          <p>
            <FormattedMessage id="gift.cards.description" />
          </p>

          <div className="row">
            <iframe
              style={{ border: 'none', padding: 0 }}
              title="giftCardForm"
              width="100%"
              height="600px"
              src="https://notionforms.io/forms/kinkekaardi-vorm"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

GiftCardsPage.propTypes = {};

GiftCardsPage.defaultProps = {};

export default injectIntl(GiftCardsPage);
