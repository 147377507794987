import React from 'react';
import { FormattedMessage } from 'react-intl';
import appStoreDownload from '../landing/assets/apple-app-store-download.svg';
import playStoreDownload from '../landing/assets/google-play-store-download.svg';
import igLogo from '../landing/assets/instagram-logo.svg';
import fbLogo from '../landing/assets/facebook-logo.svg';
import PropTypes from 'prop-types';

const Footer = ({ hideBadges }) => (
  <div className="footer">
    <div className="container">
      {!hideBadges ? (
        <>
          <h2 className="text-center d-none">
            <FormattedMessage id={'landing.fifth.title'} />
          </h2>

          <div className="row footer__badges d-none">
            <div className="col-2 d-none d-lg-block" />
            <div className="col-12 col-sm-6 col-lg-4 text-center footer__badges-badge m-b-6">
              <a
                href="https://apps.apple.com/us/app/id1497468928#?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  window.gtag('event', 'app_store_link_click', {
                    event_label: 'App store click - iOS',
                    event_category: 'app_store_link',
                    placement: 'footer',
                    page_path: window.location.pathname,
                  })
                }
              >
                <img src={appStoreDownload} alt="Download from app store" />
              </a>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 text-center footer__badges-badge">
              <a
                href="https://play.google.com/store/apps/details?id=com.membo.home"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  window.gtag('event', 'app_store_link_click', {
                    event_label: 'App store click - Android',
                    event_category: 'app_store_link',
                    placement: 'footer',
                    page_path: window.location.pathname,
                  })
                }
              >
                <img src={playStoreDownload} alt="Download from play store" />
              </a>
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <p className="text-center m-b-3">
        <strong>info@membo.ee</strong>
      </p>
      <div className="text-center">
        <a href="https://www.instagram.com/membo.ee" target="_blank" rel="noopener noreferrer">
          <img src={igLogo} alt="" className="footer__social m-r-2" />
        </a>{' '}
        <a href="https://www.facebook.com/membo.est" target="_blank" rel="noopener noreferrer">
          <img src={fbLogo} alt="" className="footer__social" />
        </a>
      </div>
    </div>
  </div>
);

Footer.propTypes = {
  hideBadges: PropTypes.bool,
};

Footer.defaultProps = {};

export default Footer;
