import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";

const ProducerPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const howPoints = [
    'producer.how.point.1',
    'producer.how.point.2',
    'producer.how.point.3',
    'producer.how.point.4',
    'producer.how.point.5',
  ];

  const whyPoints = [
    'producer.why.1',
    'producer.why.2',
    'producer.why.3',
    'producer.why.4',
    'producer.why.5',
  ];

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'producer.helmet.title'}) }</title>
        <meta name="description" content={ intl.formatMessage({id: 'producer.helmet.description'}) } />
      </Helmet>
      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="producer.title"/>
          </h1>

          <p>
            <FormattedMessage id="producer.intro"/>
          </p>

          <h3>
            <FormattedMessage id="producer.how.title"/>
          </h3>

          <ul>
            {
              howPoints.map((point, index) => (
                <li key={index}><FormattedMessage id={point}/></li>
              ))
            }
          </ul>

          <h3>
            <FormattedMessage id="producer.for.who.title"/>
          </h3>
          <p>
            <FormattedMessage id="producer.for.who.description"/>
          </p>

          <h3>
            <FormattedMessage id="producer.why.title"/>
          </h3>
          <ul>
            {
              whyPoints.map((point, index) => (
                <li key={index}><FormattedMessage id={point}/></li>
              ))
            }
          </ul>

          <h2>
            <FormattedMessage id="producer.end.title"/>
          </h2>
          <p className="m-b-6">
            <FormattedMessage id="producer.end.description"/>
          </p>

          <iframe
            title="producer-form-iframe"
            src="https://docs.google.com/forms/d/e/1FAIpQLSe5rc2Ufh_VxR_W7nZBUXE4yDzTVuKH8CVroJFkWu9uEM_p9Q/viewform?embedded=true"
            width="100%" height="1550" frameBorder="0" marginHeight="0" marginWidth="0">Loading…
          </iframe>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

ProducerPage.propTypes = {};

ProducerPage.defaultProps = {};

export default injectIntl(ProducerPage);
