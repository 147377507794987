import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const login = (data) => {
  return axios.post(`${API_URL}/api/login`, data);
};

const fbLogin = (data) => {
  return axios.post(`${API_URL}/api/facebookLogin`, data);
};

const refreshAuth = (data) => {
  let formData = new FormData();
  formData.append('grant_type', data.grant_type);
  formData.append('refresh_token', data.refresh_token);

  return axios.post(`${API_URL}/oauth/access_token`, formData);
};

const register = (data) => {
  return axios.post(`${API_URL}/api/users`, data);
};

const uploadImage = (image, imageType, resourceId) => {
  const formData = new FormData();
  formData.append('image', image, image.name);
  formData.append('imageType', imageType);
  if (resourceId) {
    formData.append('resourceId', resourceId);
  }

  return axios.post(`${API_URL}/api/files`, formData);
};

const deleteImage = (image) => {
  return axios.delete(`${API_URL}/api/files/${image.id}`);
};

const getCurrentUserDetails = () => {
  return axios.get(`${API_URL}/api/currentUser`);
};

const editUser = (data) => {
  return axios.put(`${API_URL}/api/users/` + data.user.id, data);
};

const resetPassword = (data) => {
  return axios.put(`${API_URL}/api/password/reset`, data);
};

const triggerPasswordReset = (data) => {
  return axios.put(`${API_URL}/api/password/triggerReset`, data);
};

const getMarketplaces = (params) => {
  if (params && params.marketplaceId) {
    return axios.get(`${API_URL}/api/marketplaces/${params.marketplaceId}`);
  } else {
    return axios.get(`${API_URL}/api/marketplaces/`, { params });
  }
};

const getDeliveryDayMarketplaces = (params) => {
  if (params && params.deliveryDayMarketplaceId) {
    return axios.get(`${API_URL}/api/deliveryDayMarketplaces/${params.deliveryDayMarketplaceId}`);
  } else {
    return axios.get(`${API_URL}/api/deliveryDayMarketplaces`, { params });
  }
};

const getCtaGroups = (params) => {
  if (params && params.ctaGroupId) {
    return axios.get(`${API_URL}/api/v3/ctaGroups/${params.ctaGroupId}`);
  } else {
    return axios.get(`${API_URL}/api/v3/ctaGroups`, { params });
  }
};

const getItems = (params) => {
  if (params && params.itemId) {
    return axios.get(`${API_URL}/api/v2/items/${params.itemId}`);
  } else {
    return axios.get(`${API_URL}/api/v2/items`, { params });
  }
};

const getPayment = (params) => {
  if (params && params.paymentId) {
    return axios.get(`${API_URL}/api/payments/${params.paymentId}`, { params });
  } else {
    return axios.get(`${API_URL}/api/payments`, { params });
  }
};

const getAddresses = (params) => {
  if (params && params.addressId) {
    return axios.get(`${API_URL}/api/addresses/${params.addressId}`);
  } else {
    return axios.get(`${API_URL}/api/addresses/`, { params });
  }
};

const createAddress = ({ address }) => {
  return axios.post(`${API_URL}/api/addresses`, { address });
};

const editAddress = ({ address }) => {
  return axios.put(`${API_URL}/api/addresses/${address.id}`, { address });
};

const deleteAddress = ({ address }) => {
  return axios.delete(`${API_URL}/api/addresses/${address.id}`);
};

const getSubscriptions = (params) => {
  if (params && params.subscriptionId) {
    return axios.get(`${API_URL}/api/subscriptions/${params.subscriptionId}`);
  } else {
    return axios.get(`${API_URL}/api/subscriptions/`, { params });
  }
};

const getSubscriptionPortalUrl = (params) => {
  return axios.get(`${API_URL}/api/stripe/portalUrl`, { params });
};

const createSubscription = ({ subscription }) => {
  return axios.post(`${API_URL}/api/subscriptions`, { subscription });
};

const editSubscription = ({ subscription }) => {
  return axios.put(`${API_URL}/api/subscriptions/${subscription.id}`, { subscription });
};

const getPaymentMethods = (params) => {
  if (params && params.paymentMethodId) {
    return axios.get(`${API_URL}/api/paymentMethods/${params.paymentMethodId}`);
  } else {
    return axios.get(`${API_URL}/api/paymentMethods/`, { params });
  }
};

const getOrderBatches = (params) => {
  if (params && params.orderBatchId) {
    return axios.get(`${API_URL}/api/orderBatches/${params.orderBatchId}`);
  } else {
    return axios.get(`${API_URL}/api/orderBatches/`, { params });
  }
};

const getOrders = (params) => {
  if (params && params.orderId) {
    return axios.get(`${API_URL}/api/orders/${params.orderBatchId}`);
  } else {
    return axios.get(`${API_URL}/api/orders/`, { params });
  }
};

const createPriceQuote = (data) => {
  return axios.post(`${API_URL}/api/priceQuotes/`, data);
};

const createPaymentMethod = (data) => {
  return axios.post(`${API_URL}/api/paymentMethods`, data);
};

const createPayment = (data) => {
  return axios.post(`${API_URL}/api/payments`, data);
};

const deletePaymentMethod = (paymentMethod) => {
  return axios.delete(`${API_URL}/api/paymentMethods/${paymentMethod.id}`);
};

const editPaymentMethod = (paymentMethod) => {
  return axios.put(`${API_URL}/api/paymentMethods/${paymentMethod.id}`, {
    paymentMethod,
  });
};

const createOrderBatch = (data) => {
  return axios.post(`${API_URL}/api/orderBatches`, data);
};

const getServiceAreas = (params) => {
  if (params && params.serviceAreaId) {
    return axios.get(`${API_URL}/api/serviceAreas/${params.serviceAreaId}`);
  } else {
    return axios.get(`${API_URL}/api/serviceAreas`, { params });
  }
};

const getItemCollections = (params) => {
  if (params && params.itemCollectionId) {
    return axios.get(`${API_URL}/api/itemCollections/${params.itemCollectionId}`);
  } else {
    return axios.get(`${API_URL}/api/itemCollections`, { params });
  }
};

const getCoupons = (params) => {
  if (params && params.couponId) {
    return axios.get(`${API_URL}/api/coupons/${params.couponId}`);
  } else {
    return axios.get(`${API_URL}/api/coupons`, { params });
  }
};

const getDeliveryDays = (params) => {
  if (params && params.deliveryDayId) {
    return axios.get(`${API_URL}/api/deliveryDays/${params.deliveryDayId}`);
  } else {
    return axios.get(`${API_URL}/api/deliveryDays`, { params });
  }
};

const getBusinessProfiles = (params) => {
  if (params && params.businessProfileId) {
    return axios.get(`${API_URL}/api/businessProfiles/${params.businessProfileId}`);
  } else {
    return axios.get(`${API_URL}/api/businessProfiles`, { params });
  }
};

const createBusinessProfile = (data) => {
  return axios.post(`${API_URL}/api/businessProfiles`, data);
};

const createSetupIntent = (data) => {
  return axios.post(`${API_URL}/api/setupIntents`, data);
};

const editBusinessProfile = ({ businessProfile }) => {
  return axios.put(`${API_URL}/api/businessProfiles/${businessProfile.id}`, {
    businessProfile,
  });
};

const getItemFirstLevelCategories = (params) => {
  return axios.get(`${API_URL}/api/itemCategories`, { params });
};

const apiCalls = {
  login,
  refreshAuth,
  fbLogin,
  register,
  uploadImage,
  deleteImage,
  getCurrentUserDetails,
  editUser,
  triggerPasswordReset,
  resetPassword,
  getMarketplaces,
  getDeliveryDayMarketplaces,
  getCtaGroupsV2: getCtaGroups,
  getItems,
  getAddresses,
  createAddress,
  editAddress,
  deleteAddress,
  getSubscriptions,
  getSubscriptionPortalUrl,
  createSubscription,
  editSubscription,
  getPaymentMethods,
  getPayment,
  createPayment,
  createPaymentMethod,
  deletePaymentMethod,
  editPaymentMethod,
  createOrderBatch,
  getOrderBatches,
  getOrders,
  createPriceQuote,
  getServiceAreas,
  getItemCollections,
  getCoupons,
  getDeliveryDays,
  getBusinessProfiles,
  createBusinessProfile,
  editBusinessProfile,
  getItemFirstLevelCategories,
  createSetupIntent,
};

export default apiCalls;
