import {
  SERVICE_AREA_SET_SUCCESS,
  SELECTED_SERVICE_AREA_REFRESH_SUCCESS,
  SERVICE_COUNTRY_SET_SUCCESS,
} from './serviceAreasActions';
import SERVICE_COUNTRIES from '../components/constants/serviceCountries';

const initialState = {
  selectedServiceCountry: null,
  selectedServiceArea: null,
  serviceAreas: null,
  loading: false,
  error: null,
};

export default function serviceAreasReducer(state = initialState, action) {
  switch (action.type) {
    case SERVICE_AREA_SET_SUCCESS:
      saveSelectedServiceAreaToLocalStorage(action.payload.serviceArea);

      return {
        ...state,
        selectedServiceArea: action.payload.serviceArea,
      };
    case SERVICE_COUNTRY_SET_SUCCESS:
      saveSelectedServiceCountryToLocalStorage(action.payload.serviceCountry);

      return {
        ...state,
        selectedServiceCountry: action.payload.serviceCountry,
      };
    case SELECTED_SERVICE_AREA_REFRESH_SUCCESS:
      const selectedArea = getSelectedServiceAreaFromLocalStorage();
      const selectedCountry = getSelectedServiceCountryFromLocalStorage();

      return {
        ...state,
        loading: false,
        selectedServiceArea: selectedArea,
        selectedServiceCountry: selectedCountry || SERVICE_COUNTRIES[0],
      };
    default:
      return state;
  }
}

function saveSelectedServiceAreaToLocalStorage(serviceArea) {
  localStorage.setItem('selectedServiceArea', JSON.stringify(serviceArea));
}

function saveSelectedServiceCountryToLocalStorage(serviceCountry) {
  localStorage.setItem('selectedServiceCountryAlpha2Code', serviceCountry.countryAlpha2Code);
}

function getSelectedServiceAreaFromLocalStorage() {
  const serviceArea = localStorage.getItem('selectedServiceArea');

  return JSON.parse(serviceArea);
}

function getSelectedServiceCountryFromLocalStorage() {
  const serviceCountryAlpha2Code = localStorage.getItem('selectedServiceCountryAlpha2Code');

  return serviceCountryAlpha2Code
    ? SERVICE_COUNTRIES.find((c) => c.countryAlpha2Code === serviceCountryAlpha2Code)
    : null;
}
