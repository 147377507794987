import React from 'react';
import PropTypes from 'prop-types';

import LoginSignupContent from './loginSignupContent';
import { connect } from 'react-redux';

class LoginSignupPage extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    // if user is already logged in and the currentUser api call finished, redirect him automatically
    if (this.props.currentUserLoading && !nextProps.currentUserLoading && nextProps.currentUser) {
      if (this.props.location.state && this.props.location.state.redirectUrl) {
        this.props.history.push({
          pathname: this.props.location.state.redirectUrl,
          search: this.props.location.state.search,
        });
        return false;
      } else {
        this.props.history.push({
          pathname: '/',
        });
        return false;
      }
    }

    return true;
  }

  render() {
    const loader = this.props.currentUserLoading ? (
      <div className="text-center m-t-5 m-b-5">
        <div className="b-loader" />
      </div>
    ) : (
      ''
    );

    const redirectUrl = this.props.location.state ? this.props.location.state.redirectUrl : null;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-xl-4 offset-xl-4 login-signup">
            {loader}

            <LoginSignupContent redirectUrl={redirectUrl} />
          </div>
        </div>
      </div>
    );
  }
}

LoginSignupPage.propTypes = {
  currentUser: PropTypes.object,
  currentUserLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    currentUserLoading: state.currentUser.loading,
  };
}

export default connect(mapStateToProps)(LoginSignupPage);
