import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { getMarketplaces } from './marketplacesActions';
import { getDeliveryDayMarketplaces } from '../deliveryDayMarketplaces/deliveryDayMarketplacesActions';
import { setMarketplace, getItems, getItemCollections } from './marketplaceActions';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import EVENT_NAME from '../components/constants/eventName';
import FirstOrderCoupon from '../components/firstOrderCoupon';
import ItemColumns from '../components/itemColumns';
import CtaGroupItems from '../ctaGroup/ctaGroupItems';
import ItemsSidebar from '../shop/itemsSidebar';
import { useItemFirstLevelCategories } from '../shop/apiHooks';
import ItemCategoriesScroll from '../shop/itemCategoriesScroll';

const MarketplaceViewPage = ({
  marketplace,
  loading,
  error,
  deliveryDayMarketplaces,
  setMarketplace,
  getMarketplaces,
  match,
  getItems,
  items,
  getDeliveryDayMarketplaces,
  selectedServiceArea,
  currentUser,
  getItemCollections,
  itemCollections,
  currentUserLoading,
}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [marketplace]);

  useEffect(() => {
    getMarketplaces({ marketplaceId: match.params.marketplaceId })
      .then((marketplaces) => {
        if (marketplaces.length) {
          setMarketplace(marketplaces[0]);
          utils.trackEvent(EVENT_NAME.marketplaceView, {
            id: marketplaces[0].id,
            name: marketplaces[0].name,
          });
          return getItems({ marketplaceId: match.params.marketplaceId });
        }
      })
      .then(() => {
        const areaParam = !currentUser && selectedServiceArea ? { serviceAreaId: selectedServiceArea.id } : {};

        return getDeliveryDayMarketplaces({ marketplaceId: match.params.marketplaceId, ...areaParam });
      })
      .then(() => {
        return getItemCollections({ marketplaceId: match.params.marketplaceId, type: 'MARKETPLACE_COLLECTION' });
      });
    // eslint-disable-next-line
  }, [match.params.marketplaceId]);

  const { categories } = useItemFirstLevelCategories({ currentUserLoading });

  function onCategoryChange(newCategory) {
    if (newCategory === activeCategory) {
      return;
    }

    setActiveCategory(newCategory);
    getItems({ marketplaceId: marketplace.id, category: newCategory });
  }

  function getCategoriesPanel() {
    const marketplaceCategories = marketplace.categories.map((category, index) => {
      const categoryClasses = classNames({
        active: activeCategory === category,
        'marketplace-view__categories-category text-primary btn btn-normal-link': true,
      });

      return (
        <li key={index} className="list-inline-item m-r-2">
          <small>
            <strong>
              <button className={categoryClasses} onClick={() => onCategoryChange(category)}>
                {category}
              </button>
            </strong>
          </small>
        </li>
      );
    });

    const allCategoryClasses = classNames({
      active: !activeCategory,
      'marketplace-view__categories-category btn btn-normal-link': true,
    });

    return (
      <div className="m-b-3 text-center">
        <ul className="list-unstyled list-inline m-b-0">
          <li className="list-inline-item m-r-2">
            <small>
              <strong>
                <button className={allCategoryClasses} onClick={() => onCategoryChange(null)}>
                  <FormattedMessage id={'marketplace.view.categories.all'} />
                </button>
              </strong>
            </small>
          </li>
          {marketplaceCategories}
        </ul>
      </div>
    );
  }

  const marketplaceCategories = marketplace?.categories?.length ? getCategoriesPanel() : '';

  const itemsLoader = loading ? (
    <div className="text-center m-t-5 m-b-5">
      <div className="b-loader" />
    </div>
  ) : (
    ''
  );

  const logoStyles = {
    backgroundImage: marketplace?.image ? 'url("' + marketplace.image.mainImage + '")' : '',
  };

  const deliveryDays = deliveryDayMarketplaces.find((ddm) => ddm.marketplaceId === marketplace?.id);
  const nextDelivery =
    deliveryDays && deliveryDays.deliveryDayMarketplaces.length ? deliveryDays.deliveryDayMarketplaces[0] : undefined;

  return (
    <>
      <FirstOrderCoupon />

      <ItemsSidebar />

      <div className="sidebar-content">
        {categories ? <ItemCategoriesScroll firstLevelCategories={categories} /> : ''}

        <Loader show={loading} />
        <ErrorMessage error={error} />

        {marketplace ? (
          <div className="row">
            <Helmet>
              <title>{marketplace.name}</title>
              <meta name="description" content={marketplace.description} />
            </Helmet>

            <div className="col-sm-12 marketplace-view">
              <div className="row align-items-center">
                <div className="col-12 offset-lg-0 col-sm-5 col-xl-4">
                  <div className="marketplace-view__image" style={logoStyles} />
                </div>
                <div className="col-12 col-sm-7 col-xl-8">
                  <h3 className="">{marketplace.name}</h3>
                  <p className="">{marketplace.description}</p>

                  {nextDelivery ? (
                    <>
                      <div>
                        <small>
                          <FormattedMessage id={'marketplace.view.sidebar.next.delivery'} />:<br />
                        </small>
                        <strong className="text-capitalize">
                          <span role="img" aria-label="delivery truck emoji">
                            🚚
                          </span>{' '}
                          {utils.getFormattedDateFromString(
                            nextDelivery.deliveryDate,
                            DATE_FORMAT.displayWithoutTimeAndDayName
                          )}
                        </strong>
                      </div>
                      <div className="text-muted">
                        <small>
                          <FormattedMessage
                            id={'marketplace.view.sidebar.next.delivery.deadline'}
                            values={{
                              deadline: utils.getFormattedDateFromString(
                                nextDelivery.deadline,
                                DATE_FORMAT.displayWithTimeAndWeekday
                              ),
                            }}
                          />
                        </small>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {!loading && !nextDelivery ? (
                    <p className="alert alert-warning">
                      <small>
                        <FormattedMessage id={'marketplace.view.sidebar.no.delivery.dates'} />
                      </small>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <hr className="m-b-6 m-t-6" />
              <div className="m-b-6">{marketplaceCategories}</div>

              {!activeCategory && itemCollections.length ? (
                <div className="m-b-6">
                  {itemCollections.map((itemCollection, index) => {
                    return (
                      <div key={index}>
                        <h3 className="m-b-3">{itemCollection.name}</h3>

                        <CtaGroupItems items={itemCollection.items} fetchMoreItems={() => {}} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}

              {itemsLoader}

              {items?.length ? <ItemColumns items={items} marketplace={marketplace} /> : ''}
            </div>
          </div>
        ) : (
          ''
        )}

        {categories ? <ItemCategoriesScroll firstLevelCategories={categories} /> : ''}
      </div>
    </>
  );
};

MarketplaceViewPage.propTypes = {
  currentUser: PropTypes.object,
  marketplace: PropTypes.object,
  marketplaces: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,
};

MarketplaceViewPage.defaultProps = {};

function mapStateToProps(state) {
  return {
    currentUserLoading: state.currentUser.loading,
    currentUser: state.currentUser.user,
    selectedServiceArea: state.serviceAreas.selectedServiceArea,
    marketplace: state.marketplaces.marketplace,
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
    cart: state.cart.cart,
    items: state.marketplace.items,
    itemCollections: state.marketplace.itemCollections,
    loading: state.marketplaces.loading || state.deliveryDayMarketplaces.loading,
    error: state.marketplaces.error || state.deliveryDayMarketplaces.error,
  };
}

const mapDispatchToProps = {
  setMarketplace,
  getMarketplaces,
  getDeliveryDayMarketplaces,
  getItems,
  getItemCollections,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketplaceViewPage);
