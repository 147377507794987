import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import mainPic from "./assets/viljad.png";
import Helmet from "react-helmet";

const HowItWorksPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'how.it.works.helmet.title'}) }</title>
        <meta name="description" content={ intl.formatMessage({id: 'how.it.works.helmet.description'}) } />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="how.it.works.title"/>
          </h1>
          <p>
            <FormattedMessage id="how.it.works.intro.p1"/>
          </p>
          <p>
            <FormattedMessage id="how.it.works.intro.p2"/>
          </p>

          <div className="row">
            <div className="col-10 m-b-3">
              <img src={mainPic} alt="" className="img-fluid"/>
            </div>
          </div>

          <p className="m-b-1">
            <strong>
              <FormattedMessage id="how.it.works.fresh.food.title"/>
            </strong>
          </p>
          <p>
            <FormattedMessage id="how.it.works.fresh.food.description"/>
          </p>

          <p className="m-b-1">
            <strong>
              <FormattedMessage id="how.it.works.fruits.vegs.local.title"/>
            </strong>
          </p>
          <p>
            <FormattedMessage id="how.it.works.fruits.vegs.local.description"/>
          </p>

          <p className="m-b-1">
            <strong>
              <FormattedMessage id="how.it.works.fruits.vegs.foreign.title"/>
            </strong>
          </p>
          <p>
            <FormattedMessage id="how.it.works.fruits.vegs.foreign.description"/>
          </p>

          <p className="m-b-1">
            <strong>
              <FormattedMessage id="how.it.works.long.shelf.life.title"/>
            </strong>
          </p>
          <p>
            <FormattedMessage id="how.it.works.long.shelf.life.description"/>
          </p>
          <p>
            <FormattedMessage id="how.it.works.outro.description.p1"/>
          </p>
          <p>
            <FormattedMessage id="how.it.works.outro.description.p2"/>
          </p>
          <p>
            <FormattedMessage id="how.it.works.outro.description.p3"/>
          </p>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

HowItWorksPage.propTypes = {};

HowItWorksPage.defaultProps = {};

export default injectIntl(HowItWorksPage);
