import React from 'react';
import Select from 'react-select';

function CustomSelect({ schema, formData, onChange }) {
  const onValueChange = (option) => {
    if (Array.isArray(option)) {
      const newValue = option.length ? option.map((option) => option.value) : '';
      onChange(newValue);
    } else {
      const newValue = option ? option.value : '';
      onChange(newValue);
    }
  };

  let selectedValue;

  if (Array.isArray(formData)) {
    if (schema.options) {
      selectedValue = formData.map((value) => schema.options.find((option) => option.value === value));
      selectedValue = selectedValue.filter((value) => value !== null && typeof value !== 'undefined');
    }
  } else {
    selectedValue = schema.options && schema.options.find((option) => option.value === formData);
  }

  return (
    <div>
      {schema.title ? <label className="control-label">{schema.title}</label> : ''}
      <Select
        name="form-field-name"
        value={selectedValue}
        options={schema.options}
        onChange={onValueChange}
        isMulti={schema.multi}
      />
    </div>
  );
}

export default CustomSelect;
