import React from 'react';

import { FormattedMessage } from 'react-intl';

class ContactDetailsField extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.formData || {
      name: '',
      email: '',
      phoneNumber: '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.formData);
  }

  onChange(name) {
    return (event) => {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => this.props.onChange(this.state)
      );
    };
  }

  render() {
    return (
      <fieldset>
        <legend>
          <FormattedMessage id={'contact.details.form.field.legend'} />
        </legend>
        <div className="row">
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage id={'contact.details.form.field.name.label'} />
              </label>
              <input
                type="text"
                value={this.state.name}
                onChange={this.onChange('name')}
                className="form-control"
                name="name"
              />
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage id={'contact.details.form.field.email.label'} />
              </label>
              <div className={this.props.schema.business ? 'm-b-1' : ''}>
                <input
                  type="text"
                  value={this.state.email}
                  onChange={this.onChange('email')}
                  className="form-control"
                  name="email"
                />
              </div>
              {this.props.schema.business ? (
                <p className="help-block">
                  <FormattedMessage id={'contact.details.form.field.email.help.business'} />
                </p>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="form-group">
              <label className="control-label">
                <FormattedMessage id={'contact.details.form.field.phone.number.label'} />
              </label>
              <input
                type="text"
                value={this.state.phoneNumber}
                onChange={this.onChange('phoneNumber')}
                className="form-control"
                name="phoneNumber"
              />
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default ContactDetailsField;
