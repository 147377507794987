import React, { useContext, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import utils from '../utils/utils';
import { useItemFirstLevelCategories } from './apiHooks';
import Loader from '../components/loader';
import { FormattedMessage, useIntl } from 'react-intl';
import { matchPath } from 'react-router';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import ServiceAreaSelectionModal from '../marketplace/serviceAreaSelectionModal';
import categorySelectedBg from './assets/category-selected-bg.svg';
import { Context } from '../i18n/localeWrapper';

const ItemsSidebar = ({
  currentUserLoading,
  history,
  match,
  selectedServiceArea,
  currentUser,
  boxShadow = false,
  showOnMobile = false,
}) => {
  const [showServiceAreaSelection, setShowServiceAreaSelection] = useState(false);

  const intl = useIntl();

  const { isLoading: loading, categories } = useItemFirstLevelCategories({
    currentUserLoading,
    business: match.path.includes('/business'),
  });

  // needed because sidebar sits above category sub-paths
  const matchWithCategoryParams = matchPath(history.location.pathname, {
    path: `${match.path}/:firstLevelCategory/:secondLevelCategory?`,
  });

  const shoppingStyle = localStorage.getItem('shoppingStyle');

  const setActiveShoppingStyle = (newShoppingStyle) => {
    if (!newShoppingStyle) {
      localStorage.removeItem('shoppingStyle');
    } else {
      localStorage.setItem('shoppingStyle', newShoppingStyle);
    }

    window.location.reload();
  };

  const selectedArea = currentUser?.serviceArea ? currentUser.serviceArea : selectedServiceArea;

  const categoryPath = match.path.includes('/shop') ? match.path : '/shop';

  const localeContext = useContext(Context);

  return (
    <div
      className={`marketplace-sidebar ${boxShadow ? 'm-box-shadow' : ''} ${!showOnMobile ? 'd-none d-lg-block' : ''}`}
    >
      <Loader show={loading} />

      <div className="m-b-3">
        <small>
          <FormattedMessage id={'marketplace.view.sidebar.shop.service.area'} />{' '}
          {selectedArea ? (
            <strong className="marketplace-sidebar__top-area" onClick={() => setShowServiceAreaSelection(true)}>
              {selectedArea.name} <FontAwesomeIcon icon={faCaretDown} />
            </strong>
          ) : (
            ''
          )}
        </small>
      </div>

      <div className="marketplace-sidebar__categories">
        <div className="m-b-6 d-none d-lg-block">
          <div className="btn-group btn-group-toggle btn-block" data-toggle="buttons">
            <label
              className={`btn btn-normal-link m-underline ${!shoppingStyle ? 'active' : ''}`}
              onClick={() => setActiveShoppingStyle(null)}
            >
              <input type="radio" name="options" id="option1" autoComplete="off" checked />{' '}
              <FormattedMessage id={'marketplace.view.sidebar.shopping.mode.products'} />
            </label>
            <label
              className={`btn btn-normal-link m-underline ${shoppingStyle === 'producers' ? 'active' : ''}`}
              onClick={() => setActiveShoppingStyle('producers')}
            >
              <input type="radio" name="options" id="option2" autoComplete="off" />{' '}
              <FormattedMessage id={'marketplace.view.sidebar.shopping.mode.producers'} />
            </label>
          </div>
        </div>

        {categories
          ? categories.map((category) => {
              const slugifiedFirstLevelCategoryName = utils.convertToUrlString(category.name);
              const isActive = slugifiedFirstLevelCategoryName === matchWithCategoryParams?.params.firstLevelCategory;

              const categoryTranslation = `item.first.level.category.${category.name}`;

              return (
                <div
                  key={category.name}
                  className={`row marketplace-sidebar__categories-category m-b-3 ${isActive ? 'm-active' : ''}`}
                >
                  <div className="col-12">
                    <Link
                      to={
                        isActive && !matchWithCategoryParams?.params.secondLevelCategory
                          ? categoryPath
                          : `${categoryPath}/${slugifiedFirstLevelCategoryName}`
                      }
                      replace={true}
                    >
                      <div className={`marketplace-sidebar__categories-category-name ${isActive ? 'm-b-3' : ''}`}>
                        <img
                          className="marketplace-sidebar__categories-category-name-bg"
                          src={categorySelectedBg}
                          alt=""
                        />
                        <img
                          className="marketplace-sidebar__categories-category-name-icon"
                          src={category.iconUrl}
                          alt=""
                        />
                        {intl.messages[categoryTranslation]
                          ? intl.formatMessage({ id: categoryTranslation })
                          : category.name}
                      </div>
                      <div className="marketplace-sidebar__categories-category-arrow text-muted d-lg-none">
                        <FontAwesomeIcon icon={isActive ? faAngleUp : faAngleDown} />
                      </div>
                    </Link>
                  </div>

                  {isActive && shoppingStyle !== 'producers'
                    ? category.secondLevelCategories.map((secondLevelCategory) => {
                        const slugifiedSecondLevelCategoryName = utils.convertToUrlString(secondLevelCategory.name);
                        const isActive =
                          slugifiedSecondLevelCategoryName === matchWithCategoryParams?.params.secondLevelCategory;

                        const categoryLink = `${categoryPath}/${slugifiedFirstLevelCategoryName}/${slugifiedSecondLevelCategoryName}`;

                        return (
                          <div key={secondLevelCategory.name} className="col-4 col-lg-12">
                            <Link key={secondLevelCategory.name} to={categoryLink}>
                              <div
                                className={`marketplace-sidebar__categories-category-sub-category m-b-3 text-center text-lg-left ${
                                  isActive ? 'm-active' : ''
                                }`}
                              >
                                <img
                                  className="marketplace-sidebar__categories-category-sub-category-image d-lg-none"
                                  src={secondLevelCategory.imageUrl}
                                  alt=""
                                />
                                <div className="marketplace-sidebar__categories-category-sub-category-name">
                                  {utils.getSecondCategoryTranslation(secondLevelCategory, localeContext.locale, intl)}
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    : ''}
                </div>
              );
            })
          : ''}
      </div>

      <Modal show={showServiceAreaSelection} onHide={() => setShowServiceAreaSelection(false)} size="lg">
        <div onClick={() => setShowServiceAreaSelection(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <ServiceAreaSelectionModal closeModal={() => setShowServiceAreaSelection(false)} />
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    currentUserLoading: state.currentUser.loading,
    selectedServiceArea: state.serviceAreas.selectedServiceArea,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

export default withRouter(connect(mapStateToProps)(ItemsSidebar));
