import {
  MARKETPLACE_ITEMS_GET_BEGIN,
  MARKETPLACE_ITEMS_GET_SUCCESS,
  MARKETPLACE_ITEMS_GET_FAILURE,
} from './marketplaceItemActions';

const initialState = {
  marketplaceItems: [],
  loading: false,
  error: null
};

export default function marketplaceItemReducer(state = initialState, action) {
  switch(action.type) {
    case MARKETPLACE_ITEMS_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MARKETPLACE_ITEMS_GET_SUCCESS:
      const newMarketplaceItems = state.marketplaceItems.slice(); // need to make a copy for component to see change
      action.payload.items.forEach(item => {
        const marketplaceId = item.marketplaces.length? item.marketplaces[0].marketplaceId: null;
        if (marketplaceId) {
          const existingMarketplaceItems = newMarketplaceItems.find(mi => mi.marketplaceId === marketplaceId);
          if (existingMarketplaceItems) {
            const existingItem = existingMarketplaceItems.items.find(existingItem => existingItem.id === item.id);
            if (!existingItem) {
              existingMarketplaceItems.items.push(item);
            }
          } else {
            newMarketplaceItems.push({
              marketplaceId,
              items: [item],
              hasAllItems: !!action.payload.setHasAllItems,
            })
          }
        }
      });

      return {
        ...state,
        loading: false,
        marketplaceItems: newMarketplaceItems,
      };
    case MARKETPLACE_ITEMS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: null
      };
    default:
      return state;
  }
}