const DATE_FORMAT = {
	dataTransfer: 'YYYY-MM-DDTHH:mm:ss[Z]', // needs to be [Z] because grails by default returns just Z, not the timezone itself
	display: 'll',
	displayWithTime: 'DD.MM.YYYY - HH:mm',
	displayWithTimeAndWeekday: 'ddd, DD.MM, HH:mm',
	displayWithoutTime: 'ddd, DD.MM',
	displayWithoutTimeAndDayName: 'dddd, DD.MM',
	routeOptimiser: 'MM/DD/YYYY',
	onlyTime: 'HH:mm',
	onlyDate: 'DD.MM.YYYY',
};

export default DATE_FORMAT;