import React from 'react';
import { FormattedMessage } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';

const MaintenancePage = () => {
  return (
    <div className="content-page">
      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1 className="m-b-3">
            ⚙️ <FormattedMessage id="maintenance.title" />
          </h1>
          <p>
            <FormattedMessage id="maintenance.description" />
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MaintenancePage;
