import {
  ITEMS_GET_BEGIN,
  ITEMS_GET_SUCCESS,
  ITEMS_GET_FAILURE,
  MARKETPLACE_SET_SUCCESS,
  ITEM_COLLECTIONS_GET_BEGIN,
  ITEM_COLLECTIONS_GET_SUCCESS,
  ITEM_COLLECTIONS_GET_FAILURE,
} from './marketplaceActions';

const initialState = {
  marketplace: null,
  items: null,
  itemCollections: [],
  loading: false,
  error: null
};

export default function marketplaceReducer(state = initialState, action) {
  switch(action.type) {
    case ITEMS_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ITEMS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.items
      };
    case ITEMS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: null
      };
    case ITEM_COLLECTIONS_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ITEM_COLLECTIONS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        itemCollections: action.payload.itemCollections
      };
    case ITEM_COLLECTIONS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        items: null
      };
    case MARKETPLACE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        marketplace: action.payload.marketplace
      };
    default:
      return state;
  }
}