import React, { useEffect } from 'react';
import utils from '../utils/utils';
import { useCtaGroups } from './apiHooks';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import CtaGroup from './ctaGroup/ctaGroup';

const ShopItemsCategory = ({ match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.firstLevelCategory]);

  const {
    isLoading: loading,
    ctaGroups,
    isError,
    error,
  } = useCtaGroups({ business: match.path.includes('/business') });

  const nonCategoryCtaGroups = ctaGroups?.filter((ctaGroup) => ctaGroup.type !== 'CATEGORY' && ctaGroup.isRoot);

  return (
    <>
      <Loader show={loading} />
      {isError ? <ErrorMessage error={utils.getErrorMessageFromResponse(error)} /> : ''}

      {nonCategoryCtaGroups?.map((ctaGroup) => (
        <CtaGroup ctaGroup={ctaGroup} />
      ))}
    </>
  );
};

export default ShopItemsCategory;
