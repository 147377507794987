import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/index';
import ReactTooltip from 'react-tooltip'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

const SortableItem = SortableElement(({image, deleteImage, classes}) => (
  <li className="list-inline-item b-image-preview-wrap">
    <div className={classes}>
      <img src={image.thumbnailImage} alt="uploaded thumbnail"/>
      <div className="b-image-preview__remove" onClick={() => deleteImage(image)}>
        <FontAwesomeIcon icon={faTimes}/>
      </div>
    </div>
  </li>
));

const SortableList = SortableContainer(({images, deleteImage, classes}) => {
  return (
    <ul className="list-unstyled list-inline">
      {images.map((image, index) => (
        <SortableItem key={index} index={index} image={image} deleteImage={deleteImage} classes={classes}/>
      ))}
    </ul>
  );
});

function ImagePreview({uploadedImages, pendingImages, failedImages, deleteImage, reorderImages, allowDelete}) {
  const deleteConfirm = (image) => {
    if (window.confirm("Are you sure you want to delete this image?")) {
      deleteImage(image);
    }
  };

  const pendingPreviews = pendingImages.map((image, index) => {
    return (
      <div key={index} className="b-image-preview m-loading">
        <div className="b-image-preview__loader">
          <div className="b-image-preview__loader-icon"/>
        </div>
        <div className="b-image-preview__failed">
          <div className="b-image-preview__failed-icon">
            <i className="fa fa-remove fa-2x"/>
          </div>
        </div>
      </div>
    );
  });

  const failedPreviews = failedImages.map((image, index) => {
    return (
      <React.Fragment key={index}>
        <span data-tip={image.errorMessage}>
          <div key={index} className="b-image-preview m-failed">
            <img src={image.preview} alt="failed preview"/>
            <div className="b-image-preview__loader">
              <div className="b-image-preview__loader-icon"/>
            </div>
            <div className="b-image-preview__failed">
              <div className="b-image-preview__failed-icon">
                <i className="fa fa-remove fa-2x"/>
              </div>
            </div>
          </div>
        </span>
        <ReactTooltip place="top" type="dark" effect="float"/>
      </React.Fragment>
    );
  });

  let uploadedImagePreviewClasses = classNames({
    'b-image-preview': true,
    'm-allow-delete': allowDelete
  });

  const uploadedPreviews = (
    <SortableList axis="xy"
                  pressDelay={100} // need to have a small pressDelay, otherwise the delete button isn't clickable
                  onSortEnd={reorderImages}
                  images={uploadedImages}
                  classes={uploadedImagePreviewClasses}
                  deleteImage={deleteConfirm}/>
  );

  return (
    <div className="b-image-previews m-b-2">
      {pendingPreviews}
      {failedPreviews}
      {uploadedPreviews}
    </div>
  );
}

ImagePreview.propTypes = {
  uploadedImages: PropTypes.array.isRequired,
  pendingImages: PropTypes.array.isRequired,
  failedImages: PropTypes.array.isRequired,
  deleteImage: PropTypes.func.isRequired,
  reorderImages: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool,
};

ImagePreview.defaultProps = {
  uploadedImages: [],
  pendingImages: [],
  failedImages: [],
  deleteImage: () => {
  },
  allowDelete: true,
};

export default ImagePreview;