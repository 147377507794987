import React, { useState } from 'react';
import { addItemToCart, removeItemFromCart } from '../cart/cartActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faMinus, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';
import {
  getDeliveryDayMarketplaces,
  setPreferredDeliveryDayId,
  unifyDeliveryDays,
} from '../deliveryDayMarketplaces/deliveryDayMarketplacesActions';
import { Modal } from 'react-bootstrap';
import DeliveryChangeModal from '../cart/deliveryChangeModal';

const CartButton = ({
  item,
  marketplace,
  cart,
  addItemToCart,
  removeItemFromCart,
  getDeliveryDayMarketplaces,
  small,
  neutralColor,
  deliveryDayMarketplaces,
  intl,
  currentUser,
  selectedServiceArea,
  setPreferredDeliveryDayId,
  unifyDeliveryDays,
  primaryAddColor = false,
  blockButton = false,
}) => {
  const marketplaceId = marketplace
    ? marketplace.id
    : item.marketplaces.length
    ? item.marketplaces[0].marketplaceId
    : null;

  const cartOrder = marketplaceId ? cart.find((cartOrder) => cartOrder.marketplace.id === marketplaceId) : undefined;
  const orderItem = cartOrder ? cartOrder.orderItems.find((orderItem) => orderItem.item.id === item.id) : undefined;
  const [loading, setLoading] = useState(false);
  const [showDeliveryChangeModal, setShowDeliveryChangeModal] = useState(false);
  const [previousDeliveryDayMarketplace, setPreviousDeliveryDayMarketplace] = useState(null);
  const [nextPossibleDeliveryDayMarketplace, setNextPossibleDeliveryDayMarketplace] = useState(null);

  const stock = item.marketplaces.length && item.marketplaces[0].stock;

  const triggerError = (errorMessage) => {
    alert(errorMessage);
    setLoading(false);
  };

  const addToCart = () => {
    // eslint-disable-next-line no-unreachable
    setLoading(true);

    if (!marketplaceId) {
      triggerError(intl.formatMessage({ id: 'cart.button.error.no.marketplace.id' }));
      return;
    } else if (orderItem && stock && orderItem.quantity >= stock) {
      return;
    }

    let ddm = deliveryDayMarketplaces.find((ddm) => ddm.marketplaceId === marketplaceId);
    if (ddm) {
      pickNextDeliveryDayAndAddToCart(ddm.deliveryDayMarketplaces, ddm.selectedDeliveryDayMarketplace);
    } else {
      const areaParam = !currentUser && selectedServiceArea ? { serviceAreaId: selectedServiceArea.id } : {};

      getDeliveryDayMarketplaces({ marketplaceId: marketplaceId, ...areaParam })
        .then((ddms) => {
          if (ddms && ddms.length) {
            pickNextDeliveryDayAndAddToCart(ddms, ddms[0]);
          } else {
            triggerError(intl.formatMessage({ id: 'cart.button.error.no.delivery.days' }));
          }
        })
        .catch((error) => {
          triggerError(utils.getErrorMessageFromResponse(error, true));
        });
    }
  };

  const showDeliveryDayChangeModal = (oldDdm, newDdm) => {
    setPreviousDeliveryDayMarketplace(oldDdm);
    setNextPossibleDeliveryDayMarketplace(newDdm);
    setShowDeliveryChangeModal(true);
  };

  const pickNextDeliveryDayAndAddToCart = (ddms, selectedDdm) => {
    if (utils.shouldCheckCutoffTimeForItem(item)) {
      const durationFromDeliveryDate = utils.getSelectedDdmDiffFromNowInHours(selectedDdm.deliveryDate);
      if (durationFromDeliveryDate < item.deliveryCutOffHours) {
        const nextPossible = ddms.find((ddm) => {
          const deliveryDateMidDay = utils.getDateFromString(ddm.deliveryDate);
          deliveryDateMidDay.set('hour', 12);

          return utils.getDateDiffInHours(deliveryDateMidDay) >= item.deliveryCutOffHours;
        });

        if (nextPossible) {
          showDeliveryDayChangeModal(selectedDdm, nextPossible);
          return;
        } else {
          const error = intl.formatMessage({ id: 'cart.button.error.no.delivery.days.for.item.cut.off.hours' });
          alert(error);
          setLoading(false);
          return;
        }
      }
    } else if (utils.shouldOfferLaterDelivery(cart, deliveryDayMarketplaces, selectedDdm.deliveryDayId)) {
      const uniqueDeliveryDayDtos = utils.getUniqueSelectedDdms(cart, deliveryDayMarketplaces);
      showDeliveryDayChangeModal(uniqueDeliveryDayDtos[0], selectedDdm);
      return;
    }

    addToCartWithMarketplace();
  };

  const resetDeliveryDayModal = () => {
    setShowDeliveryChangeModal(false);
    setPreviousDeliveryDayMarketplace(null);
    setNextPossibleDeliveryDayMarketplace(null);
    setLoading(false);
  };

  const changeDeliveryDay = () => {
    setPreferredDeliveryDayId(nextPossibleDeliveryDayMarketplace.deliveryDayId);
    addToCartWithMarketplace();
    resetDeliveryDayModal();
  };

  const addToCartWithMarketplace = () => {
    if (marketplace && marketplace.id === marketplaceId) {
      addItemToCart(item, marketplace);
      unifyDeliveryDays(utils.getCartMarketplaceIds(cart, marketplaceId));
      setLoading(false);
    } else {
      apiCalls
        .getMarketplaces({ marketplaceId })
        .then((response) => {
          addItemToCart(item, response.data.marketplaces[0]);
          unifyDeliveryDays(utils.getCartMarketplaceIds(cart, marketplaceId));
          setLoading(false);
        })
        .catch((error) => {
          const errorString = utils.getErrorMessageFromResponse(error, true);
          alert(errorString);
          setLoading(false);
        });
    }
  };

  const removeFromCart = (e) => {
    removeItemFromCart(item, marketplaceId);
    // TODO calculate closest delivery day for all marketplaces and set accordingly
  };

  const buttonClasses = classNames({
    btn: true,
    'btn-secondary': !primaryAddColor,
    'btn-primary': primaryAddColor,
    'btn-small': small,
    'btn-loading': loading,
    'btn-block': blockButton,
  });

  const cartClasses = classNames({
    'cart-item-status': true,
    'm-small': small,
    'm-neutral': neutralColor,
    'm-block': blockButton,
  });

  return (
    <div className="cart-button" onClick={(e) => e.stopPropagation()}>
      {orderItem ? (
        <div className={cartClasses}>
          <span className="cart-item-status__remove" onClick={removeFromCart}>
            <FontAwesomeIcon icon={faMinus} />
          </span>
          <span className="cart-item-status__quantity text-h4">{orderItem.quantity}</span>
          <span className="cart-item-status__add" onClick={addToCart}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </div>
      ) : (
        <button onClick={addToCart} className={buttonClasses}>
          <FormattedMessage id="cart.add.item" />
        </button>
      )}

      {stock && stock < 5 ? (
        <div className="cart-button__stock text-small">
          <FormattedMessage
            id={'cart.stock.notice'}
            values={{
              stock,
            }}
          />
        </div>
      ) : (
        ''
      )}

      <Modal show={showDeliveryChangeModal} onHide={resetDeliveryDayModal}>
        <div onClick={resetDeliveryDayModal} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <DeliveryChangeModal
          closeModal={resetDeliveryDayModal}
          onSuccess={changeDeliveryDay}
          previousDeliveryDayMarketplace={previousDeliveryDayMarketplace}
          nextPossibleDeliveryDayMarketplace={nextPossibleDeliveryDayMarketplace}
        />
      </Modal>
    </div>
  );
};

CartButton.propTypes = {
  item: PropTypes.object.isRequired,
  marketplace: PropTypes.object,
  cart: PropTypes.array,
  neutralColor: PropTypes.bool,
};

CartButton.defaultProps = {
  small: false,
  neutralColor: false,
};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
    selectedServiceArea: state.serviceAreas.selectedServiceArea,
  };
}

const mapDispatchToProps = {
  addItemToCart,
  removeItemFromCart,
  getDeliveryDayMarketplaces,
  setPreferredDeliveryDayId,
  unifyDeliveryDays,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartButton));
