import { useQuery } from 'react-query';
import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';

export function useItemFirstLevelCategories({ currentUserLoading, business }) {
  const { data, ...rest } = useQuery(
    'itemFirstLevelCategories',
    () => apiCalls.getItemFirstLevelCategories({ business }).then(({ data: { categories } }) => categories),
    { enabled: !currentUserLoading }
  );
  return { categories: data, ...rest };
}

export function useItems({ secondLevelCategory, business }) {
  const { data, ...rest } = useQuery(
    ['items', { secondLevelCategory }],
    () =>
      apiCalls.getItems({ secondLevelCategory, business }).then(({ data: { items } }) => {
        const shuffledItems = utils.shuffleArray(items);
        shuffledItems.sort((a, b) => {
          if (a.ranking && b.ranking) {
            return a.ranking - b.ranking;
          } else if (a.ranking && !b.ranking) {
            return -1;
          } else if (!a.ranking && !b.ranking) {
            return 0;
          } else {
            return 1;
          }
        });

        return shuffledItems;
      }),
    { enabled: !!secondLevelCategory }
  );
  return { items: data, ...rest };
}

export function useCtaGroups({ business }) {
  const { data, ...rest } = useQuery('ctaGroups', () =>
    apiCalls.getCtaGroupsV2({ business }).then(({ data: { ctaGroups } }) => ctaGroups)
  );
  return { ctaGroups: data, ...rest };
}

export function useDeliveryDayMarketplaces({ marketplaceIds }) {
  const { data, ...rest } = useQuery(
    ['deliveryDayMarketplaces', { marketplaceIds }],
    () =>
      apiCalls
        .getDeliveryDayMarketplaces({ marketplaceIds })
        .then(({ data: { deliveryDayMarketplaces } }) => deliveryDayMarketplaces),
    { enabled: !!marketplaceIds }
  );
  return { deliveryDayMarketplaces: data, ...rest };
}

export function useMarketplaces({ itemFirstLevelCategory, itemSecondLevelCategory }) {
  const { data, ...rest } = useQuery(
    ['marketplaces', { itemFirstLevelCategory, itemSecondLevelCategory }],
    () => {
      return apiCalls
        .getMarketplaces({ itemFirstLevelCategory, itemSecondLevelCategory })
        .then(({ data: { marketplaces } }) => marketplaces);
    },
    { enabled: !!itemFirstLevelCategory || !!itemSecondLevelCategory }
  );
  return { marketplaces: data, ...rest };
}

export function useServiceAreas(params) {
  const { data, ...rest } = useQuery(['serviceAreas', params], () =>
    apiCalls.getServiceAreas(params).then(({ data: { serviceAreas } }) => serviceAreas)
  );
  return { serviceAreas: data, ...rest };
}
