import {
  MARKETPLACES_GET_BEGIN,
  MARKETPLACES_GET_SUCCESS,
  MARKETPLACES_GET_FAILURE,
  MARKETPLACE_SET_SUCCESS,
} from './marketplacesActions';

const initialState = {
  marketplace: null,
  marketplaces: null,
  loading: false,
  error: null
};

export default function marketplacesReducer(state = initialState, action) {
  switch(action.type) {
    case MARKETPLACES_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MARKETPLACES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        marketplaces: action.payload.marketplaces
      };
    case MARKETPLACE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        marketplace: action.payload.marketplace
      };
    case MARKETPLACES_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        marketplaces: null
      };
    default:
      return state;
  }
}