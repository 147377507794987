import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SubscriptionEditModal from './subscriptionEditModal';
import { Modal } from 'react-bootstrap';
import { useSubscriptionPortalUrlCreate } from './apiHooks';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';

function SubscriptionPreview({ subscription, timeRangeOptions }) {
  const { formatMessage } = useIntl();

  const [editSubscription, setEditSubscription] = useState(null);

  const {
    createSubscriptionPortalUrl,
    isLoading: portalLoading,
    isError: isPortalError,
    error: portalError,
  } = useSubscriptionPortalUrlCreate((url) => {
    window.location.replace(url);
  });

  const requiresPayment = ['INCOMPLETE', 'PAST_DUE', 'UNPAID'].indexOf(subscription.stripeStatus) !== -1;

  return (
    <div className="block m-b-6">
      <Loader show={portalLoading} />
      {isPortalError ? <ErrorMessage error={utils.getErrorMessageFromResponse(portalError)} /> : ''}

      <h3 className="m-b-3">
        <FormattedMessage
          id="subscription.my.subscriptions.subscription.id"
          values={{
            id: subscription.id,
          }}
        />
      </h3>
      <div className="m-b-4">
        <FormattedMessage
          id="subscription.my.subscriptions.subscription.status"
          values={{
            status: (
              <span
                className={`badge ${
                  subscription.stripeStatus === 'ACTIVE' && subscription.active ? 'badge-success' : 'badge-light'
                }`}
              >
                {formatMessage({
                  id: `subscription.my.subscriptions.subscription.status.${subscription.stripeStatus}`,
                })}
              </span>
            ),
          }}
        />
      </div>
      {subscription.resumeDate ? (
        <div className="alert alert-primary">
          <FormattedMessage
            id="subscription.my.subscriptions.subscription.resume.date"
            values={{
              date: (
                <strong>
                  {utils.getFormattedDateFromString(subscription.resumeDate, DATE_FORMAT.displayWithoutTime)}
                </strong>
              ),
            }}
          />
        </div>
      ) : (
        ''
      )}
      {subscription.cancelDate ? (
        <div className="alert alert-warning">
          <FormattedMessage
            id="subscription.my.subscriptions.subscription.cancel.date"
            values={{
              date: (
                <strong>
                  {utils.getFormattedDateFromString(subscription.cancelDate, DATE_FORMAT.displayWithoutTime)}
                </strong>
              ),
            }}
          />
        </div>
      ) : (
        ''
      )}
      {subscription.stripeBillingCycleAnchor ? (
        <div className="m-b-2 text-muted">
          <small>
            <FormattedMessage
              id="subscription.my.subscriptions.subscription.billing.cycle.anchor"
              values={{
                date: (
                  <strong>
                    {utils.getFormattedDateFromString(
                      subscription.stripeBillingCycleAnchor,
                      DATE_FORMAT.displayWithoutTime
                    )}
                  </strong>
                ),
              }}
            />
          </small>
        </div>
      ) : (
        ''
      )}
      <div className="m-b-6">
        {subscription.items.map((item) => {
          return (
            <div className="block m-gray m-b-3">
              <div className="m-b-2">
                <strong>{item.itemName}</strong>
              </div>
              <div className="m-b-1">
                <FormattedMessage
                  id="subscription.my.subscriptions.subscription.quantity"
                  values={{
                    quantity: <strong>{item.quantity}</strong>,
                  }}
                />
              </div>
              <div className="m-b-1">
                <FormattedMessage
                  id="subscription.my.subscriptions.subscription.price"
                  values={{
                    price: <strong>{utils.getRoundedPrice(item.itemPrice * item.quantity)}€</strong>,
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="m-b-2">
        <FormattedMessage
          id={'subscription.my.subscriptions.subscription.address'}
          values={{
            address: (
              <strong>
                {`${subscription.address.unit ? `Apt ${subscription.address.unit}, ` : ''}${
                  subscription.address.street
                }`}
              </strong>
            ),
          }}
        />
      </div>
      <div className="m-b-6">
        <FormattedMessage
          id="subscription.my.subscriptions.subscription.next.delivery.date"
          values={{
            date: (
              <strong>
                {utils.getFormattedDateFromString(subscription.nextDeliveryDate, DATE_FORMAT.displayWithoutTime)}
              </strong>
            ),
            timeRange: <strong>{subscription.timeRange}</strong>,
          }}
        />
      </div>
      <div>
        {!requiresPayment ? (
          <button
            className="btn btn-small btn-primary m-r-2"
            key={subscription.id}
            onClick={() => setEditSubscription(subscription)}
          >
            <FormattedMessage id={'subscription.my.subscriptions.subscription.cta.edit.address'} />
          </button>
        ) : (
          ''
        )}

        {['INCOMPLETE', 'ACTIVE', 'TRIALING', 'PAST_DUE', 'UNPAID'].indexOf(subscription.stripeStatus) !== -1 ? (
          <button
            className={`btn btn-small m-r-2 ${requiresPayment ? 'btn-primary' : 'btn-default'}`}
            key={subscription.id}
            onClick={() => createSubscriptionPortalUrl()}
          >
            <FormattedMessage
              id={
                requiresPayment
                  ? 'subscription.my.subscriptions.subscription.cta.pay'
                  : 'subscription.my.subscriptions.subscription.cta.pause.cancel'
              }
            />
          </button>
        ) : (
          ''
        )}
      </div>

      <Modal show={!!editSubscription} onHide={() => setEditSubscription(null)}>
        <div onClick={() => setEditSubscription(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {editSubscription ? (
          <SubscriptionEditModal
            subscription={editSubscription}
            onSuccess={() => setEditSubscription(null)}
            timeRangeOptions={timeRangeOptions}
          />
        ) : (
          ''
        )}
      </Modal>
    </div>
  );
}

SubscriptionPreview.propTypes = {
  subscription: PropTypes.object.isRequired,
  timeRangeOptions: PropTypes.array.isRequired,
};

export default SubscriptionPreview;
