import apiCalls from '../utils/apiCalls';
import { useQuery } from 'react-query';

export function useItems({ query }) {
  const { data, ...rest } = useQuery(
    ['items', { query }],
    () => {
      if (query?.length > 2) {
        return apiCalls.getItems({ query }).then(({ data: { items } }) => items);
      } else {
        return Promise.resolve(null);
      }
    },
    { enabled: !!query }
  );
  return { items: data, ...rest };
}

export function useMarketplaces({ query }) {
  const { data, ...rest } = useQuery(
    ['marketplaces', { query }],
    () => {
      if (query?.length > 2) {
        return apiCalls.getMarketplaces({ query }).then(({ data: { marketplaces } }) => marketplaces);
      } else {
        return Promise.resolve(null);
      }
    },
    { enabled: !!query }
  );
  return { marketplaces: data, ...rest };
}
