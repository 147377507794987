import { useQuery, useMutation } from 'react-query';
import apiCalls from '../utils/apiCalls';

export function useBusinessProfiles({ userId }) {
  const { data, ...rest } = useQuery(
    'businessProfiles',
    () => apiCalls.getBusinessProfiles({ userId }).then(({ data: { businessProfiles } }) => businessProfiles),
    {
      enabled: !!userId,
    }
  );
  return { businessProfiles: data, ...rest };
}

export function useUserEdit(onSuccess) {
  const { mutate, ...rest } = useMutation(
    ({ user }) => apiCalls.editUser({ user }).then(({ data: { user } }) => user),
    {
      onSuccess: (user) => {
        onSuccess(user);
        return user;
      },
    }
  );
  return { editUser: mutate, ...rest };
}

export function useServiceAreas(params) {
  const { data, ...rest } = useQuery(['serviceAreas', params], () =>
    apiCalls.getServiceAreas(params).then(({ data: { serviceAreas } }) => serviceAreas)
  );
  return { serviceAreas: data, ...rest };
}
