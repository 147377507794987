import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import GooglePlacesField from '../components/customFields/googlePlacesField';
import GeneralForm from '../components/generalForm';
import { useAddressCreate, useAddressEdit, useServiceAreas } from './apiHooks';
import CustomSelect from '../components/customFields/customSelect';

function AddressModal({ onSuccess, existingAddress, intl, isOrderBatchAddress, selectedServiceCountry }) {
  const [formData, setFormData] = useState(
    existingAddress ? { address: existingAddress, serviceAreaId: existingAddress.serviceAreaId } : { address: {} }
  );

  const { serviceAreas, isLoading: serviceAreasLoading } = useServiceAreas({
    country: selectedServiceCountry?.countryAlpha2Code,
  });

  const { createAddress, isLoading: loading, isError, error } = useAddressCreate((address) => onSuccess(address));

  const {
    editAddress,
    isLoading: editLoading,
    isError: isEditError,
    error: editError,
  } = useAddressEdit((address) => onSuccess(address));

  const serviceAreaOptions = serviceAreas
    ? serviceAreas.map((area) => {
        return {
          value: area.id,
          label: `${area.name}`,
        };
      })
    : [];

  const formSchema = {
    type: 'object',
    required: [],
    properties: {
      serviceAreaId: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.service.area.label' }),
        options: serviceAreaOptions,
      },
      address: {
        showCustomerFields: true,
        hideLegend: true,
        noMarginTop: true,
        type: 'object',
        required: [],
        properties: {
          unit: {
            type: 'string',
            default: '',
          },
          street: {
            type: 'string',
            default: '',
          },
          city: {
            type: 'string',
            default: '',
          },
          country: {
            type: 'string',
            default: '',
          },
          postCode: {
            type: 'string',
            default: '',
          },
          coordinates: {
            type: 'object',
            required: [],
            properties: {
              lat: {
                type: 'string',
              },
              lon: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  };

  const uiSchema = {
    address: {
      'ui:field': 'googlePlacesField',
    },
    serviceAreaId: {
      'ui:field': 'customSelect',
      'ui:help': intl.formatMessage({ id: 'user.form.field.service.area.help' }),
    },
  };

  const formFields = {
    googlePlacesField: GooglePlacesField,
    customSelect: CustomSelect,
  };

  const buttonCopy = existingAddress ? 'cart.address.edit.form.submit' : 'cart.address.create.form.submit';

  return (
    <div className="b-modal">
      <div>
        <h2 className="m-b-6">
          <FormattedMessage id="cart.address.modal.title" />
        </h2>

        <GeneralForm
          onChange={(event) => setFormData(event.formData)}
          onSubmit={() => {
            const address = {
              ...formData.address,
              serviceAreaId: formData.serviceAreaId,
              isOrderBatchAddress: !!isOrderBatchAddress,
            };
            if (existingAddress) {
              editAddress({ address });
            } else {
              createAddress({ address });
            }
          }}
          formSchema={formSchema}
          formUiSchema={uiSchema}
          formFields={formFields}
          formData={formData}
          buttonCopy={buttonCopy}
          errorMessage={isError || isEditError ? utils.getErrorMessageFromResponse(error || editError) : null}
          loading={loading || editLoading || serviceAreasLoading}
          hideImageUpload={true}
        />
      </div>
    </div>
  );
}

AddressModal.propTypes = {
  onSuccess: PropTypes.func,
};

AddressModal.defaultProps = {
  onSuccess: () => {},
};

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddressModal));
