import {
  PAYMENT_METHODS_GET_BEGIN,
  PAYMENT_METHODS_GET_SUCCESS,
  PAYMENT_METHODS_GET_FAILURE,
  SELECTED_PAYMENT_METHOD_SET_SUCCESS,
} from './paymentMethodsActions';

const initialState = {
  selectedPaymentMethod: null,
  paymentMethods: null,
  loading: false,
  error: null
};

export default function paymentMethodsReducer(state = initialState, action) {
  switch(action.type) {
    case PAYMENT_METHODS_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case PAYMENT_METHODS_GET_SUCCESS:
      let selectedPaymentMethod = action.payload.paymentMethods.find(pm => pm.isPrimary);
      if (!selectedPaymentMethod && action.payload.paymentMethods.length) {
        selectedPaymentMethod = action.payload.paymentMethods[0];
      }

      return {
        ...state,
        loading: false,
        paymentMethods: action.payload.paymentMethods,
        selectedPaymentMethod,
      };
    case SELECTED_PAYMENT_METHOD_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPaymentMethod: action.payload.paymentMethod
      };
    case PAYMENT_METHODS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        paymentMethods: null
      };
    default:
      return state;
  }
}