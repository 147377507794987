import { useQuery } from 'react-query';
import apiCalls from '../utils/apiCalls';

export function useMarketplace({ marketplaceId }) {
  const { data, ...rest } = useQuery(
    ['marketplace', { marketplaceId }],
    () => {
      return apiCalls.getMarketplaces({ marketplaceId }).then(({ data: { marketplaces } }) => marketplaces[0]);
    },
    { enabled: !!marketplaceId }
  );
  return { marketplace: data, ...rest };
}
