import React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { useItemFirstLevelCategories } from './apiHooks';
import ItemsSidebar from './itemsSidebar';
import { matchPath, Route } from 'react-router';
import ShopItemsSecondLevelCategory from './shopItemsSecondLevelCategory';
import ShopItemsIndex from './shopItemsIndex';
import CategoryNavigator from '../components/categoryNavigator';
import ShopMarketplaces from './shopMarketplaces';
import FirstOrderCoupon from '../components/firstOrderCoupon';
import ShopItemsFirstLevelCategory from './shopItemsFirstLevelCategory';

const ShopItemsPage = ({ match, currentUserLoading, history }) => {
  const intl = useIntl();

  const matchWithCategoryParams = matchPath(history.location.pathname, {
    path: `${match.path}/:firstLevelCategory/:secondLevelCategory?`,
  });

  const { categories } = useItemFirstLevelCategories({
    currentUserLoading,
    business: match.path.includes('/business'),
  });

  const shoppingStyle = localStorage.getItem('shoppingStyle');

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'shop.items.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'shop.items.helmet.description' })} />
      </Helmet>

      <FirstOrderCoupon />

      <ItemsSidebar showOnMobile={!matchWithCategoryParams?.params.secondLevelCategory} />

      <div className="sidebar-content shop-items">
        {categories && shoppingStyle !== 'producers' ? (
          <div className="m-b-6">
            <CategoryNavigator categories={categories} />
          </div>
        ) : (
          ''
        )}

        <Route exact path={`${match.path}`} render={(props) => <ShopItemsIndex categories={categories} {...props} />} />
        <Route
          exact
          path={`${match.path}/:firstLevelCategory`}
          render={(props) => <ShopItemsFirstLevelCategory categories={categories} {...props} />}
        />
        <Route
          exact
          path={`${match.path}/:firstLevelCategory/:secondLevelCategory`}
          render={(props) =>
            shoppingStyle === 'producers' ? (
              <ShopMarketplaces categories={categories} {...props} />
            ) : (
              <ShopItemsSecondLevelCategory categories={categories} {...props} />
            )
          }
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    currentUserLoading: state.currentUser.loading,
  };
}

export default connect(mapStateToProps)(ShopItemsPage);
