import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import utils from "../utils/utils";
import DATE_FORMAT from "../components/constants/dateFormat";

function DeliveryChangeModal({onSuccess, closeModal, previousDeliveryDayMarketplace, nextPossibleDeliveryDayMarketplace}) {
  const submit = async () => {
    onSuccess();
  };

  const previousDate = previousDeliveryDayMarketplace?
    utils.getDateFromString(previousDeliveryDayMarketplace.deliveryDate): null;
  const nextPossibleDate = nextPossibleDeliveryDayMarketplace?
    utils.getDateFromString(nextPossibleDeliveryDayMarketplace.deliveryDate): null;

  return (
    <div className="b-modal">
      <div className="delivery-change-modal">
        <h2 className="m-b-6">
          <FormattedMessage id="delivery.change.modal.title"/>
        </h2>
        <p className="m-b-3">
          <FormattedMessage id="delivery.change.modal.description"/>
        </p>

        {
          previousDate && nextPossibleDate? (
            <p className="m-b-6">
              <strong className="m-r-3 text-capitalize">
                {utils.getFormattedDateFromMomentObj(previousDate, DATE_FORMAT.displayWithoutTimeAndDayName)}</strong> <FontAwesomeIcon icon={faCaretRight}/> <strong className="m-l-3 text-capitalize">{utils.getFormattedDateFromMomentObj(nextPossibleDate, DATE_FORMAT.displayWithoutTimeAndDayName)}
              </strong>
            </p>
          ): ''
        }

        <div className="coupon-modal__cta m-b-3">
          <button className="btn btn-primary" onClick={submit}>
            <FormattedMessage id="delivery.change.modal.cta.change.all.deliveries"/>
          </button>
        </div>
        <div className="coupon-modal__cta">
          <button className="btn btn-default" onClick={closeModal}>
            <FormattedMessage id="delivery.change.modal.cta.change.cancel"/>
          </button>
        </div>
      </div>
    </div>
  );
}

DeliveryChangeModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  previousDeliveryDayMarketplace: PropTypes.object.isRequired,
  nextPossibleDeliveryDayMarketplace: PropTypes.object.isRequired,
};

DeliveryChangeModal.defaultProps = {
  onSuccess: () => {},
};

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(DeliveryChangeModal));