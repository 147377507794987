import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import HorizontalScroll from './horizontalScroll';

const MarketplacesPreview = ({ marketplaces }) => {
  return (
    <div className="marketplace-previews m-b-6">
      {marketplaces ? (
        <HorizontalScroll
          content={marketplaces.map((marketplace) => {
            const imageStyle = marketplace.image
              ? {
                  backgroundImage: 'url(' + marketplace.image.thumbnailImage + ')',
                }
              : {
                  background: '#ddd',
                };

            return (
              <Link itemId={marketplace.id} key={marketplace.id} to={`/tootja/${marketplace.id}`}>
                <div className="marketplace-previews__marketplace">
                  <div className="marketplace-previews__marketplace-image m-b-1" style={imageStyle} />
                  <div className="marketplace-previews__marketplace-name">{marketplace.name}</div>
                </div>
              </Link>
            );
          })}
        />
      ) : (
        ''
      )}
    </div>
  );
};

MarketplacesPreview.propTypes = {
  marketplaces: PropTypes.array.isRequired,
};

export default withRouter(MarketplacesPreview);
