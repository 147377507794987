import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import ItemViewContent from '../item/itemViewContent';
import Modal from 'react-bootstrap/Modal';
import ItemPreview from '../item/itemPreview';
import utils from '../utils/utils';
import EVENT_NAME from '../components/constants/eventName';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';

const ItemColumns = ({
  items,
  itemsForModal,
  location,
  history,
  marketplace,
  deliveryDayMarketplaces,
  showNextDeliveryDate,
  showMarketplaceName,
  showNavigator = false,
  disableModalListener = false,
}) => {
  const [modalItem, setModalItem] = useState(null);

  const itemsForScroll = itemsForModal || items;

  useEffect(() => {
    if (disableModalListener) {
      return;
    }
    const queryParams = parse(location.search, { ignoreQueryPrefix: true });

    if (queryParams.itemId) {
      const itemForModal = itemsForScroll.find((item) => item.id.toString() === queryParams.itemId);
      if (itemForModal) {
        setModalItem(itemForModal);
      }
    } else {
      setModalItem(null);
    }
  }, [disableModalListener, location.search, itemsForScroll]);

  function openItemModal(item) {
    const oldPath = history.location.pathname;
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    searchParams.itemId = item.id;
    const newQueryString = stringify(searchParams, { ignoreQueryPrefix: true });

    history.replace({
      pathname: oldPath,
      search: newQueryString,
    });

    utils.trackEvent(EVENT_NAME.itemView, {
      id: item.id,
      name: item.name,
      source: 'itemColumn',
    });
  }

  function closeItemModal() {
    const oldPath = history.location.pathname;
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    delete searchParams.itemId;
    const newQueryString = stringify(searchParams, { ignoreQueryPrefix: true });

    history.replace({
      pathname: oldPath,
      search: newQueryString,
    });
  }

  const goToNextItem = () => {
    if (!modalItem || (!items && !itemsForModal)) {
      return;
    }

    const currentItemIndex = itemsForScroll.findIndex((item) => item.id === modalItem.id);
    if (currentItemIndex < itemsForScroll.length - 1 && itemsForScroll[currentItemIndex + 1]) {
      openItemModal(itemsForScroll[currentItemIndex + 1]);
    }
  };

  const goToPreviousItem = () => {
    if (!modalItem || (!items && !itemsForModal)) {
      return;
    }

    const currentItemIndex = itemsForScroll.findIndex((item) => item.id === modalItem.id);
    if (currentItemIndex > 0 && itemsForScroll[currentItemIndex - 1]) {
      openItemModal(itemsForScroll[currentItemIndex - 1]);
    }
  };

  function handleKeyUpEvent(e) {
    switch (e.key) {
      case 'ArrowRight':
        goToNextItem();
        break;
      case 'ArrowLeft':
        goToPreviousItem();
        break;
      default:
        break;
    }
  }

  const nextArrow =
    modalItem && itemsForScroll.length && itemsForScroll[itemsForScroll.length - 1].id !== modalItem.id ? (
      <div onClick={goToNextItem} className="b-modal-next">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    ) : (
      ''
    );

  const previousArrow =
    modalItem && itemsForScroll.length && itemsForScroll[0].id !== modalItem.id ? (
      <div onClick={goToPreviousItem} className="b-modal-previous">
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    ) : (
      ''
    );

  return (
    <div className="row" onKeyUp={handleKeyUpEvent}>
      {!showNextDeliveryDate
        ? items.map((item) => (
            <div key={item.id} className="col-6 item-col">
              <ItemPreview
                item={item}
                onItemClick={() => openItemModal(item)}
                showMarketplaceName={showMarketplaceName}
              />
            </div>
          ))
        : ''}
      {showNextDeliveryDate
        ? items.map((item) => {
            const marketplaceId = utils.getItemMarketplace(item)?.marketplaceId;
            const itemDdms = marketplaceId
              ? deliveryDayMarketplaces.filter((ddm) => ddm.marketplaceId === marketplaceId)
              : [];

            itemDdms.sort((a, b) => utils.getDateFromString(a.deliveryDate) - utils.getDateFromString(b.deliveryDate));

            const nextDeliveryDate = itemDdms.length ? utils.getDateFromString(itemDdms[0].deliveryDate) : null;

            return (
              <div key={item.id} className="col-6 item-col">
                <ItemPreview
                  item={item}
                  onItemClick={() => openItemModal(item)}
                  showMarketplaceName={showMarketplaceName}
                  nextDeliveryDate={nextDeliveryDate}
                />
              </div>
            );
          })
        : ''}

      <Modal show={!!modalItem} onHide={closeItemModal} size="xl">
        <div onClick={closeItemModal} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} />
        </div>

        {nextArrow}
        {previousArrow}

        <div className="b-modal m-no-padding">
          {modalItem ? (
            <div className="container-fluid marketplace-view-item-modal-container">
              <ItemViewContent item={modalItem} marketplace={marketplace} showNavigator={showNavigator} />
            </div>
          ) : (
            ''
          )}
        </div>
      </Modal>
    </div>
  );
};

ItemColumns.propTypes = {
  items: PropTypes.array.isRequired,
};

export default withRouter(ItemColumns);
