import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import marleen from "./assets/top/top-marleen.jpeg";
import pahkla from "./assets/top/top-pahkla.jpg";
import kiltsimae from "./assets/top/top-kiltsimae.jpg";
import okka from "./assets/top/top-okka.jpg";
import kukkel from "./assets/top/top-kukkel.jpg";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

const TopJanuaryPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paragraphs = [
    {
      title: 'top.january.1',
      description: 'top.january.1.description',
      image: marleen,
      link1Href: '/tootja/17?itemId=124',
      link1: (<Link className="link" to="/tootja/17?itemId=124">Šokolaadi-pähkli sai</Link>),
      link2: (<Link className="link" to="/tootja/17?itemId=100">Mooni-martsipani-kondenspiima sai</Link>),
    },
    {
      title: 'top.january.2',
      description: 'top.january.2.description',
      image: pahkla,
      link1Href: '/tootja/47?itemId=381',
      link1: (<Link className="link" to="/tootja/47?itemId=381">Värske forelli filee</Link>),
    },
    {
      title: 'top.january.3',
      description: 'top.january.3.description',
      image: kiltsimae,
      link1Href: '/tootja/32?itemId=92',
      link1: (<Link className="link" to="/tootja/32?itemId=92">Mahe Ühepajatoit, 750g</Link>),
      link2: (<Link className="link" to="/tootja/32?itemId=89">Mahe Wok ribad, 400g</Link>),
    },
    {
      title: 'top.january.4',
      description: 'top.january.4.description',
      image: okka,
      link1Href: '/tootja/26?itemId=178',
      link1: (<Link className="link" to="/tootja/26?itemId=178">Majonees Kurgiga, 200ml</Link>),
    },
    {
      title: 'top.january.5',
      description: 'top.january.5.description',
      image: kukkel,
      link1Href: '/tootja/38?itemId=276',
      link1: (<Link className="link" to="/tootja/43?itemId=332">Lihtne rukkileib 850g</Link>),
      link2: (<Link className="link" to="/tootja/38?itemId=276">Hapusai (~700g)</Link>),
    },
  ];

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'top.january.helmet.title'}) }</title>
        <meta name="description" content={ intl.formatMessage({id: 'top.january.helmet.description'}) } />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="top.january.title"/>
          </h1>

          <p>
            <FormattedMessage id="top.january.intro"/>
          </p>

          {paragraphs.map((paragraph, index) => (
            <div key={index}>
              <h3 className="text-primary">
                <FormattedMessage id={paragraph.title}/>
              </h3>
              <p><FormattedMessage id={paragraph.description} values={{
                link1: paragraph.link1,
                link2: paragraph.link2,
              }}/></p>
              <Link to={paragraph.link1Href}>
                <img src={paragraph.image} alt="" className="img-content img-border-radius m-b-6"/>
              </Link>
            </div>
          ))}

          <p>
            <FormattedMessage id="top.january.outro"/>
          </p>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

TopJanuaryPage.propTypes = {};

TopJanuaryPage.defaultProps = {};

export default injectIntl(TopJanuaryPage);
