import React from 'react';
import PropTypes from 'prop-types';
import ItemColumns from '../../components/itemColumns';
import MarketplacesPreview from '../../components/marketplacesPreview';

const CtaGroup = ({ ctaGroup }) => {
  return (
    <div className="cta-group">
      {ctaGroup.title ? <h3 className="cta-group__title m-b-3">{ctaGroup.title}</h3> : ''}
      {ctaGroup.description ? <p>{ctaGroup.description}</p> : ''}

      {ctaGroup.items && ctaGroup.items.length ? (
        <div className="m-b-3">
          <ItemColumns items={ctaGroup.items} showMarketplaceName={true} />
        </div>
      ) : (
        ''
      )}

      {ctaGroup.marketplaces && ctaGroup.marketplaces.length ? (
        <div className="m-b-3">
          <MarketplacesPreview marketplaces={ctaGroup.marketplaces} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

CtaGroup.propTypes = {
  ctaGroup: PropTypes.object.isRequired,
};

CtaGroup.defaultProps = {};

export default CtaGroup;
