import React, {useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import PaymentMethod from "../components/paymentMethod";
import {getPaymentMethods, setSelectedPaymentMethod} from "../paymentMethods/paymentMethodsActions";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faTrash} from "@fortawesome/free-solid-svg-icons";
import apiCalls from "../utils/apiCalls";
import Loader from "../components/loader";
import ErrorMessage from "../components/errorMessage";
import utils from "../utils/utils";

function PaymentMethodSelectionModal({closeModal, paymentMethods, setSelectedPaymentMethod,
                                       openNewMethodModal, getPaymentMethods, currentUser, intl}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deletePaymentMethod = (paymentMethod) => {
    if (!window.confirm(intl.formatMessage({id: 'cart.payment.method.modal.delete.confirm'}))) {
      return;
    }
    setLoading(true);
    apiCalls.deletePaymentMethod(paymentMethod).then((result) => {
      getPaymentMethods({userId: currentUser.id}).then((result) => {
        setLoading(false);
      }).catch(error => {
        setError(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
      });
    }).catch((error) => {
      setError(utils.getErrorMessageFromResponse(error, true));
      setLoading(false);
    });
  };

  const setPrimaryAndSelect = (paymentMethod) => {
    setLoading(true);

    paymentMethod.isPrimary = true;
    apiCalls.editPaymentMethod(paymentMethod).then((result) => {
      getPaymentMethods({userId: currentUser.id}).then((result) => {
        setSelectedPaymentMethod(paymentMethod);
        setLoading(false);
        closeModal();
      }).catch(error => {
        setError(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
      });
    }).catch((error) => {
      setError(utils.getErrorMessageFromResponse(error, true));
      setLoading(false);
    });
  };

  return (
    <div className="b-modal">
      <div className="payment-method-modal">
        <h2>
          <FormattedMessage id="cart.payment.method.modal.title"/>
        </h2>
        <p>
          <FormattedMessage id="cart.payment.method.modal.description"/>
        </p>

        <Loader show={loading}/>
        <ErrorMessage error={error}/>

        <div className="payment-method-modal__choices">
          {
            paymentMethods? paymentMethods.map((pm, index) => {
              return (
                <div className="row payment-method-modal__choices-choice">
                  <div className="col-10 col-sm-11">
                    <div className="m-b-3" key={index} onClick={() => setPrimaryAndSelect(pm)}>
                      <PaymentMethod paymentMethod={pm}/>
                    </div>
                  </div>
                  <div className="col-2 col-sm-1">
                    <span className="payment-method-modal__choices-choice-trash" onClick={() => deletePaymentMethod(pm)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </span>
                  </div>
                </div>
              )
            }): ''
          }
          <div>
            <button onClick={() => openNewMethodModal()} className="btn btn-normal-link">
              <FontAwesomeIcon icon={faCreditCard}/> <FormattedMessage id="cart.payment.add.card"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentMethodSelectionModal.propTypes = {
  openNewMethodModal: PropTypes.func.isRequired,
};

PaymentMethodSelectionModal.defaultProps = {};

const mapDispatchToProps = {
  setSelectedPaymentMethod,
  getPaymentMethods
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    paymentMethods: state.paymentMethods.paymentMethods,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodSelectionModal));