import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import SubscriptionQuantitySelector from './subscriptionQuantitySelector';
import { Link } from 'react-router-dom';

function SubscriptionPreview({ product, landing = false }) {
  return (
    <div className="block m-gray m-b-4">
      <div className="row">
        <div className="col-4 col-sm-4 px-0 px-sm-3">
          <img className="img-fluid img-border-radius" src={product.image} alt="Membo fruit box" />
        </div>
        <div className="col-8 col-sm-8 align-self-center">
          <h3 className="m-b-2">
            <FormattedMessage id={product.title} />
          </h3>
          <h4 className="text-sans-serif">{product.price}€</h4>
          <div className="m-b-2">
            {product.content?.length ? (
              <ul className="p-l-2" style={{ paddingInlineStart: '15px' }}>
                {product.content.map((line) => (
                  <li>{line}</li>
                ))}
              </ul>
            ) : (
              ''
            )}
          </div>
          {landing ? (
            <Link to="/subscription" className={`btn btn-default btn-small d-none`}>
              <FormattedMessage id={'subscription.product.cta.create.subscription'} />
            </Link>
          ) : (
            <>
              <div>
                <label>
                  <FormattedMessage id="subscription.product.quantity.label" />
                </label>
              </div>
              <SubscriptionQuantitySelector setQuantity={product.setQuantity} quantity={product.quantity} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

SubscriptionPreview.propTypes = {
  product: PropTypes.object.isRequired,
};

export default SubscriptionPreview;
