import React from 'react';

const ErrorMessage = ({error}) => {
  return error? (
    <div className="alert alert-error m-t-5 m-b-5">
      {error}
    </div>
  ): '';
}

export default ErrorMessage;