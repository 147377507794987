import React, {useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import Loader from "../components/loader";
import ErrorMessage from "../components/errorMessage";
import {withRouter} from "react-router-dom";
import {getOrderBatches} from "./orderBatchesActions";
import OrderBatchViewContent from "./orderBatchViewContent";
import apiCalls from "../utils/apiCalls";
import utils from "../utils/utils";

const OrderBatchViewPage = ({intl, getOrderBatches, orderBatches, loading, error, currentUser, match}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [orderBatch, setOrderBatch] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(false);
  const [ordersError, setOrdersError] = useState(null);

  useEffect(() => {
    if (!orderBatches) {
      getOrderBatches({userId: currentUser.id})
    } else {
      const ob = orderBatches.find(ob => ob.id.toString() === match.params.orderBatchId);
      if (ob) {
        setOrderBatch(ob);
        getOrders(ob);
      }
    }
  }, [orderBatches, getOrderBatches, currentUser.id, setOrderBatch, match.params.orderBatchId]);

  function getOrders(orderBatch) {
    setOrdersLoading(true);
    apiCalls.getOrders({
      orderBatchId: orderBatch.id,
    }).then((result) => {
      setOrders(result.data.orders);
      setOrdersLoading(false);
    }).catch((error) => {
      setOrdersError(utils.getErrorMessageFromResponse(error, true));
      setOrdersLoading(false);
    });
  }

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'my.orders.title'}) }</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <Loader show={loading || ordersLoading}/>
          <ErrorMessage error={error || ordersError}/>

          {orderBatch? <OrderBatchViewContent orderBatch={orderBatch} orders={orders}/>: ''}
        </div>
      </div>

      <Footer/>
    </div>
  );
};

OrderBatchViewPage.propTypes = {};

OrderBatchViewPage.defaultProps = {};

const mapDispatchToProps = {
  getOrderBatches
};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentUser: state.currentUser.user,
    orderBatches: state.orderBatches.orderBatches,
    loading: state.orderBatches.loading,
    error: state.orderBatches.error,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderBatchViewPage)));
