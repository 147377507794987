import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';
import GeneralForm from '../components/generalForm';
import { setCurrentUser } from './currentUserActions';
import GooglePlacesField from '../components/customFields/googlePlacesField';

function UserAddressForm({ user, setUser, setCurrentUser, onSuccess }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const formSchema = {
    type: 'object',
    required: [],
    properties: {
      address: {
        noMarginTop: true,
        type: 'object',
        required: [],
        properties: {
          unit: {
            type: 'string',
            default: '',
          },
          street: {
            type: 'string',
            default: '',
          },
          city: {
            type: 'string',
            default: '',
          },
          country: {
            type: 'string',
            default: '',
          },
          postCode: {
            type: 'string',
            default: '',
          },
          coordinates: {
            type: 'object',
            required: [],
            properties: {
              lat: {
                type: 'string',
              },
              lon: {
                type: 'string',
              },
            },
          },
        },
      },
    },
  };

  const uiSchema = {
    address: {
      'ui:field': 'googlePlacesField',
    },
  };

  const onFormSubmit = () => {
    setLoading(true);

    apiCalls
      .editUser({ user: user })
      .then((response) => {
        setCurrentUser(response.data.user);
        onSuccess(response.data.user);
      })
      .catch((error) => {
        setErrorMessage(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const formFields = {
    googlePlacesField: GooglePlacesField,
  };

  const buttonCopy = 'user.edit.form.submit';

  return user ? (
    <GeneralForm
      onChange={(event) => setUser(event.formData)}
      onSubmit={onFormSubmit}
      formSchema={formSchema}
      formUiSchema={uiSchema}
      formFields={formFields}
      formData={user}
      buttonCopy={buttonCopy}
      errorMessage={errorMessage}
      loading={loading}
      hideImageUpload={true}
    />
  ) : (
    ''
  );
}

UserAddressForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

UserAddressForm.defaultProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

const mapDispatchToProps = {
  setCurrentUser,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserAddressForm));
