import React, {useEffect} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import ostukorv from "../landing/assets/ostukorv.png";
import Helmet from "react-helmet";

const WhyPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paragraphs = [
    {
      title: 'why.reason.1',
      description: 'why.reason.1.description',
    },
    {
      title: 'why.reason.2',
      description: 'why.reason.2.description',
    },
    {
      title: 'why.reason.3',
      description: 'why.reason.3.description',
    },
    {
      title: 'why.reason.4',
      description: 'why.reason.4.description',
    },
    {
      title: 'why.reason.5',
      description: 'why.reason.5.description',
    },
    {
      title: 'why.reason.6',
      description: 'why.reason.6.description',
    },
  ];

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'why.helmet.title'}) }</title>
        <meta name="description" content={ intl.formatMessage({id: 'why.helmet.description'}) } />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="why.title"/>
          </h1>

          <div className="row">
            <div className="col-10 col-sm-8 m-b-3">
              <img src={ostukorv} alt="" className="img-fluid"/>
            </div>
          </div>

          <p>
            <FormattedMessage id="why.paragraph.intro"/>
          </p>

          {paragraphs.map((paragraph, index) => (
            <div key={index}>
              <div className="text-primary">
                <strong><FormattedMessage id={paragraph.title}/></strong><br/>
              </div>
              <p><FormattedMessage id={paragraph.description}/></p>
            </div>
          ))}

          <p>
            <FormattedMessage id="why.paragraph.outro"/>
          </p>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

WhyPage.propTypes = {};

WhyPage.defaultProps = {};

export default injectIntl(WhyPage);
