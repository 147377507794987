import apiCalls from '../utils/apiCalls';

export const ITEMS_GET_BEGIN   = 'ITEMS_GET_BEGIN';
export const ITEMS_GET_SUCCESS = 'ITEMS_GET_SUCCESS';
export const ITEMS_GET_FAILURE = 'ITEMS_GET_FAILURE';
export const ITEM_COLLECTIONS_GET_BEGIN   = 'ITEM_COLLECTIONS_GET_BEGIN';
export const ITEM_COLLECTIONS_GET_SUCCESS = 'ITEM_COLLECTIONS_GET_SUCCESS';
export const ITEM_COLLECTIONS_GET_FAILURE = 'ITEM_COLLECTIONS_GET_FAILURE';
export const MARKETPLACE_SET_SUCCESS = 'MARKETPLACE_SET_SUCCESS';

export const itemsGetBegin = () => ({
  type: ITEMS_GET_BEGIN
});

export const itemsGetSuccess = items => ({
  type: ITEMS_GET_SUCCESS,
  payload: { items }
});

export const itemsGetFailure = error => ({
  type: ITEMS_GET_FAILURE,
  payload: { error }
});

export const marketplaceSetSuccess = marketplace => ({
  type: MARKETPLACE_SET_SUCCESS,
  payload: { marketplace }
});

export const itemCollectionsGetBegin = () => ({
  type: ITEM_COLLECTIONS_GET_BEGIN
});

export const itemCollectionsGetSuccess = itemCollections => ({
  type: ITEM_COLLECTIONS_GET_SUCCESS,
  payload: { itemCollections }
});

export const itemCollectionsGetFailure = error => ({
  type: ITEM_COLLECTIONS_GET_FAILURE,
  payload: { error }
});

export const setMarketplace = marketplace => {
  return dispatch => {
    dispatch(marketplaceSetSuccess(marketplace));
  };
};

export const getItems = params => {
  return dispatch => {
    dispatch(itemsGetBegin());
    return apiCalls.getItems(params)
      .then(res => res.data)
      .then(data => {
        dispatch(itemsGetSuccess(data.items));
        return data.items;
      })
      .catch(error => {
        dispatch(itemsGetFailure(error));
        throw error;
      });
  };
};

export const getItemCollections = params => {
  return dispatch => {
    dispatch(itemCollectionsGetBegin());
    return apiCalls.getItemCollections(params)
      .then(res => res.data)
      .then(data => {
        dispatch(itemCollectionsGetSuccess(data.itemCollections));
        return data.itemCollections;
      })
      .catch(error => {
        dispatch(itemCollectionsGetFailure(error));
        throw error;
      });
  };
};