import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import utils from '../utils/utils';
import PriceFormatter from '../components/priceFormatter';
import CartButton from '../components/cartButton';
import PREFERRED_PRICE_DISPLAY from '../components/constants/preferredPriceDisplay';
import classNames from 'classnames';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import DATE_FORMAT from '../components/constants/dateFormat';

const ItemPreview = ({ item, onItemClick, marketplace, size, showMarketplaceName, nextDeliveryDate }) => {
  const image = getItemImage(item, size, nextDeliveryDate);

  const showUnitPrice =
    item.priceFields.unitPrice && item.priceFields.preferredPriceDisplay === PREFERRED_PRICE_DISPLAY.unitPrice;

  const itemPreviewClasses = classNames({
    'marketplace-view-item': true,
    'm-small': size === 'small',
  });

  const { formatMessage } = useIntl();

  const itemContent = (
    <div className={itemPreviewClasses}>
      {image}
      <div className="marketplace-view-item__content">
        {showMarketplaceName ? (
          <div className="text-muted text-uppercase m-b-1 marketplace-view-item__content-producer text-semi-bold text-ellipsis">
            {utils.getItemMarketplace(item)?.marketplaceName}
          </div>
        ) : (
          ''
        )}
        <div className={`marketplace-view-item__content-name m-b-1 text-semi-bold`}>
          {utils.getItemName(item, formatMessage)}
        </div>
        <div className="marketplace-view-item__content-price m-b-2 text-semi-bold">
          {item.priceFields.comparedToPrice ? (
            <>
              <s className="m-r-1">
                <PriceFormatter price={item.priceFields.comparedToPrice} currency={item.priceFields.currency} />
              </s>
              <span className="text-discount">
                <PriceFormatter price={item.priceFields.price} currency={item.priceFields.currency} />
              </span>
            </>
          ) : (
            <PriceFormatter price={item.priceFields.price} currency={item.priceFields.currency} />
          )}{' '}
          {showUnitPrice ? <>/ {item.priceFields.standardUnitQuantity} kg</> : ''}
        </div>
        <div className="marketplace-view-item__content-cart">
          <CartButton item={item} marketplace={marketplace} small={true} blockButton={true} />
        </div>
      </div>
    </div>
  );

  if (utils.isFunction(onItemClick)) {
    return <div onClick={onItemClick}>{itemContent}</div>;
  } else {
    return <Link to={'/item/' + item.id}>{itemContent}</Link>;
  }
};

function getItemImage(item, size, nextDeliveryDate) {
  const imageClasses = classNames({
    'marketplace-view-item__image m-b-3': item.images.length,
    'marketplace-view-item__no-image m-b-3': !item.images.length,
    'm-small': size === 'small',
  });

  const labels = (
    <>
      {item.priceFields.comparedToPrice ? (
        <div className="marketplace-view-item__image-discount">
          <FontAwesomeIcon icon={faTag} />{' '}
        </div>
      ) : (
        ''
      )}
      {nextDeliveryDate ? (
        <>
          <div className="marketplace-view-item__image-availability">
            <small>
              <FormattedMessage
                id="item.preview.not.available.for.next.delivery.day"
                values={{
                  date: utils.getFormattedDateFromMomentObj(nextDeliveryDate, DATE_FORMAT.displayWithoutTime),
                }}
              />
            </small>
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );

  if (item.images.length) {
    const imageStyle = {
      backgroundImage: 'url(' + item.images[0].thumbnailImage + ')',
    };

    return (
      <div className={imageClasses} style={imageStyle}>
        {labels}
      </div>
    );
  } else {
    return <div className={imageClasses}>{labels}</div>;
  }
}

ItemPreview.propTypes = {
  item: PropTypes.object.isRequired,
  marketplace: PropTypes.object,
  onItemClick: PropTypes.func,
  size: PropTypes.oneOf(['small']),
};

ItemPreview.defaultProps = {};

export default ItemPreview;
