import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';
import appStoreDownload from '../landing/assets/apple-app-store-download.svg';
import playStoreDownload from '../landing/assets/google-play-store-download.svg';
import Footer from '../components/footer';

const AppDownloadPage = ({ intl }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'app.download.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'app.download.helmet.description' })} />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="app.download.title" />
          </h1>
          <p>
            <FormattedMessage id="app.download.description" />
          </p>

          <div className="content-page__app-links m-b-6">
            <div className="m-b-0">
              <a
                href="https://apps.apple.com/us/app/id1497468928#?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
                className="m-r-3 m-b-3"
                onClick={() =>
                  window.gtag('event', 'app_store_link_click', {
                    event_label: 'App store click - iOS',
                    event_category: 'app_store_link',
                    placement: 'above_the_fold',
                    page_path: window.location.pathname,
                  })
                }
              >
                <img src={appStoreDownload} alt="Download from app store" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.membo.home"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  window.gtag('event', 'app_store_link_click', {
                    event_label: 'App store click - Android',
                    event_category: 'app_store_link',
                    placement: 'above_the_fold',
                    page_path: window.location.pathname,
                  })
                }
              >
                <img src={playStoreDownload} alt="Download from play store" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer hideBadges={true} />
    </div>
  );
};

AppDownloadPage.propTypes = {};

AppDownloadPage.defaultProps = {};

export default injectIntl(AppDownloadPage);
