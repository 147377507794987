import apiCalls from '../utils/apiCalls';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export function useServiceAreas(params) {
  const { data, ...rest } = useQuery(['serviceAreas', params], () =>
    apiCalls.getServiceAreas(params).then(({ data: { serviceAreas } }) => serviceAreas)
  );
  return { serviceAreas: data, ...rest };
}

export function useAddresses({ userId }) {
  const { data, ...rest } = useQuery(
    ['addresses', { userId }],
    () =>
      apiCalls
        .getAddresses({
          userId,
        })
        .then(({ data: { addresses } }) => addresses),
    { enabled: !!userId }
  );
  return { addresses: data, ...rest };
}

export function useAddressCreate(onSuccess) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    ({ address }) => apiCalls.createAddress({ address }).then(({ data: { address } }) => address),
    {
      onSuccess: (address) => {
        queryClient.invalidateQueries('addresses');
        onSuccess(address);
      },
    }
  );
  return { createAddress: mutate, ...rest };
}

export function useAddressEdit(onSuccess) {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    ({ address }) => apiCalls.editAddress({ address }).then(({ data: { address } }) => address),
    {
      onSuccess: (address) => {
        queryClient.invalidateQueries('addresses');
        onSuccess(address);
      },
    }
  );
  return { editAddress: mutate, ...rest };
}

export function useAddressDelete() {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(({ address }) => apiCalls.deleteAddress({ address }), {
    onSuccess: () => {
      queryClient.invalidateQueries('addresses');
    },
  });
  return { deleteAddress: mutate, ...rest };
}

export function useSetupIntentCreate(onSuccess) {
  const { mutate, ...rest } = useMutation(
    () => apiCalls.createSetupIntent().then(({ data: { setupIntent } }) => setupIntent),
    {
      onSuccess: (setupIntent) => {
        onSuccess(setupIntent);
      },
    }
  );
  return { createSetupIntent: mutate, ...rest };
}
