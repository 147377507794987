import apiCalls from '../utils/apiCalls';
import axios from 'axios';
import utils from '../utils/utils';

export const LOGIN_POST_BEGIN = 'LOGIN_POST_BEGIN';
export const LOGIN_POST_SUCCESS = 'LOGIN_POST_SUCCESS';
export const LOGIN_POST_FAILURE = 'LOGIN_POST_FAILURE';

export const FB_LOGIN_POST_BEGIN = 'FB_LOGIN_POST_BEGIN';
export const FB_LOGIN_POST_SUCCESS = 'FB_LOGIN_POST_SUCCESS';
export const FB_LOGIN_POST_FAILURE = 'FB_LOGIN_POST_FAILURE';

export const AUTH_REFRESH_BEGIN = 'AUTH_REFRESH_BEGIN';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_CANCELLED = 'AUTH_REFRESH_CANCELLED';
export const AUTH_REFRESH_FAILURE = 'AUTH_REFRESH_FAILURE';

export const CURRENT_USER_GET_BEGIN = 'CURRENT_USER_GET_BEGIN';
export const CURRENT_USER_GET_SUCCESS = 'CURRENT_USER_GET_SUCCESS';
export const CURRENT_USER_GET_FAILURE = 'CURRENT_USER_GET_FAILURE';

export const LOGIN_MODAL_TRIGGER_SUCCESS = 'LOGIN_MODAL_TRIGGER_SUCCESS';

export const loginPostBegin = () => ({
  type: LOGIN_POST_BEGIN,
});

export const loginPostSuccess = (authenticationToken) => ({
  type: LOGIN_POST_SUCCESS,
  payload: { authenticationToken },
});

export const loginPostFailure = (error) => ({
  type: LOGIN_POST_FAILURE,
  payload: { error },
});

export const login = (data, formatMessage) => {
  return (dispatch) => {
    dispatch(loginPostBegin());
    return apiCalls
      .login(data)
      .then((response) => {
        dispatch(loginPostSuccess(response.data));
        localStorage.setItem('refreshToken', response.data.refresh_token);
        return onTokenResponse(response);
      })
      .then(() => dispatch(getCurrentUser()))
      .catch((error) => {
        if (error.response?.status === 401) {
          dispatch(loginPostFailure(formatMessage({ id: 'login.form.error.unauthorized' })));
        } else {
          dispatch(loginPostFailure(utils.getErrorMessageFromResponse(error, true)));
        }
        throw error;
      });
  };
};

export const fbLoginPostBegin = () => ({
  type: FB_LOGIN_POST_BEGIN,
});

export const fbLoginPostSuccess = (authenticationToken) => ({
  type: FB_LOGIN_POST_SUCCESS,
  payload: { authenticationToken },
});

export const fbLoginPostFailure = (error) => ({
  type: FB_LOGIN_POST_FAILURE,
  payload: { error },
});

export const fbLogin = (data) => {
  return (dispatch) => {
    dispatch(fbLoginPostBegin());
    return apiCalls
      .fbLogin(data)
      .then((response) => {
        dispatch(fbLoginPostSuccess(response.data));
        localStorage.setItem('refreshToken', response.data.refresh_token);
        return onTokenResponse(response);
      })
      .then(() => dispatch(getCurrentUser()))
      .catch((error) => {
        dispatch(fbLoginPostFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};

export const authRefreshBegin = () => ({
  type: AUTH_REFRESH_BEGIN,
});

export const authRefreshSuccess = (authenticationToken) => ({
  type: AUTH_REFRESH_SUCCESS,
  payload: { authenticationToken },
});

export const authRefreshCancelled = () => ({
  type: AUTH_REFRESH_CANCELLED,
});

export const authRefreshFailure = (error) => ({
  type: AUTH_REFRESH_FAILURE,
  payload: { error },
});

export const refreshAuth = () => {
  const authData = getAuthData();
  if (!authData) {
    return (dispatch) => {
      // need to cancel auth refresh because currentUserReducer loading is true by default - needs to be set false
      dispatch(authRefreshCancelled());
    };
  }

  return (dispatch) => {
    dispatch(authRefreshBegin());
    return refreshAuthentication(authData)
      .then((res) => {
        dispatch(authRefreshSuccess(res.data));
        return res.data;
      })
      .then(() => {
        dispatch(getCurrentUser());
      })
      .catch((error) => {
        dispatch(authRefreshFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};

function getAuthData() {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    return;
  }

  return {
    refresh_token: refreshToken,
    grant_type: 'refresh_token',
  };
}

function refreshAuthentication(authData, isFromInterceptor) {
  return apiCalls.refreshAuth(authData).then((response) => onTokenResponse(response, isFromInterceptor));
}

function onTokenResponse(response, isFromInterceptor) {
  setDefaultParams();
  setAuthHeader(response.data.access_token);
  if (!isFromInterceptor) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          const authData = getAuthData();
          if (authData) {
            delete axios.defaults.headers.common['Authorization'];
            const data = await refreshAuthentication(authData, true);
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
            return axios(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  return response.data;
}

function setDefaultParams() {
  axios.interceptors.request.use((config) => {
    config.params = config.params || {};
    config.params['platform'] = 'WEB';
    return config;
  });
}

function setAuthHeader(access_token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
}

export const currentUserGetBegin = () => ({
  type: CURRENT_USER_GET_BEGIN,
});

export const currentUserGetSuccess = (user) => ({
  type: CURRENT_USER_GET_SUCCESS,
  payload: { user },
});

export const currentUserGetFailure = (error) => ({
  type: CURRENT_USER_GET_FAILURE,
  payload: { error },
});

export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch(currentUserGetBegin());
    return apiCalls
      .getCurrentUserDetails()
      .then((res) => res.data)
      .then((data) => {
        dispatch(currentUserGetSuccess(data.user));
        return data.user;
      })
      .catch((error) => {
        dispatch(currentUserGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};

export const setCurrentUser = (user) => {
  return (dispatch) => {
    dispatch(currentUserGetSuccess(user));
  };
};

export const openLoginSignupModal = (loginSignupModalTab) => ({
  type: LOGIN_MODAL_TRIGGER_SUCCESS,
  payload: { loginSignupModalTab },
});

export const closeLoginModal = () => ({
  type: LOGIN_MODAL_TRIGGER_SUCCESS,
  payload: { loginSignupModalTab: null },
});
