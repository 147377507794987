import {
  ORDER_BATCHES_GET_BEGIN,
  ORDER_BATCHES_GET_SUCCESS,
  ORDER_BATCHES_GET_FAILURE,
  ORDER_BATCH_CREATE_BEGIN,
  ORDER_BATCH_CREATE_SUCCESS,
  ORDER_BATCH_CREATE_FAILURE,
  ORDER_BATCH_SET_SUCCESS,
} from './orderBatchesActions';

const initialState = {
  orderBatch: null,
  orderBatches: null,
  loading: false,
  error: null,
  createLoading: false,
  createError: null,
};

export default function orderBatchesReducer(state = initialState, action) {
  switch (action.type) {
    case ORDER_BATCHES_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ORDER_BATCHES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBatches: action.payload.orderBatches,
      };
    case ORDER_BATCH_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        orderBatch: action.payload.orderBatch,
      };
    case ORDER_BATCHES_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        orderBatches: null,
      };
    case ORDER_BATCH_CREATE_BEGIN:
      return {
        ...state,
        createLoading: true,
        createError: null,
      };
    case ORDER_BATCH_CREATE_SUCCESS:
      return {
        ...state,
        createLoading: false,
        orderBatch: action.payload.orderBatch,
      };
    case ORDER_BATCH_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createError: action.payload.error,
        orderBatch: null,
      };
    default:
      return state;
  }
}
