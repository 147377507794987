const ATTRIBUTION_CHANNEL = {
	wordOfMouth: 'WORD_OF_MOUTH',
	socialMediaAds: 'SOCIAL_MEDIA_ADS',
	press: 'PRESS',
	facebookGroups: 'FACEBOOK_GROUPS',
	influencer: 'INFLUENCER',
	seo: 'SEO',
	other: 'OTHER',
};

export default ATTRIBUTION_CHANNEL;