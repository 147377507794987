const SERVICE_COUNTRIES = [
  {
    id: 1,
    name: 'Estonia',
    countryAlpha2Code: 'EE',
    flagEmoji: '🇪🇪',
    shoppingChannels: ['SUBSCRIPTION', 'BULK_ORDERS'],
  },
  /*
  {
    id: 2,
    name: 'Finland',
    countryAlpha2Code: 'FI',
    flagEmoji: '🇫🇮',
    shoppingChannels: ['SUBSCRIPTION'],
  },
   */
];

export default SERVICE_COUNTRIES;
