export const SERVICE_AREA_SET_SUCCESS = 'SERVICE_AREA_SET_SUCCESS';
export const SERVICE_COUNTRY_SET_SUCCESS = 'SERVICE_COUNTRY_SET_SUCCESS';
export const SELECTED_SERVICE_AREA_REFRESH_SUCCESS = 'SELECTED_SERVICE_AREA_REFRESH_SUCCESS';

export const serviceAreaSetSuccess = (serviceArea) => ({
  type: SERVICE_AREA_SET_SUCCESS,
  payload: { serviceArea },
});

export const serviceCountrySetSuccess = (serviceCountry) => ({
  type: SERVICE_COUNTRY_SET_SUCCESS,
  payload: { serviceCountry },
});

export const selectedServiceAreaRefreshSuccess = () => ({
  type: SELECTED_SERVICE_AREA_REFRESH_SUCCESS,
  payload: {},
});

export const refreshSelectedServiceArea = () => {
  return (dispatch) => {
    dispatch(selectedServiceAreaRefreshSuccess());
  };
};

export const setSelectedServiceArea = (serviceArea) => {
  return (dispatch) => {
    dispatch(serviceAreaSetSuccess(serviceArea));
  };
};

export const setSelectedServiceCountry = (serviceCountry) => {
  return (dispatch) => {
    dispatch(serviceCountrySetSuccess(serviceCountry));
  };
};
