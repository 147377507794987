import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import {FormattedMessage} from 'react-intl';
import ImagePreview from "./imagePreview";
import {faImages, faImage} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ImageUpload({onSubmit, multiple, buttonText, uploadedImages,
											 pendingImages, failedImages, deleteImage, reorderImages}) {

	const buttonCopy = buttonText || multiple? 'form.image.upload.multiple': 'form.image.upload.single';

	const icon = multiple? (
		<FontAwesomeIcon icon={faImages}/>
	): (
		<FontAwesomeIcon icon={faImage}/>
	);

	return (
		<div>
			<Dropzone multiple={multiple} onDrop={onSubmit} accept="image/jpg,image/png,image/gif,image/jpeg">
				{({getRootProps, getInputProps}) => (
					<div className="b-image-upload" {...getRootProps()}>
						<input {...getInputProps()} />
						<p className="text-center">
							{icon}<br/>
							<FormattedMessage id={buttonCopy}/>
						</p>
					</div>
				)}
			</Dropzone>

			<ImagePreview pendingImages={pendingImages}
										uploadedImages={uploadedImages}
										deleteImage={deleteImage}
										reorderImages={reorderImages}
										failedImages={failedImages}/>
		</div>
	);
}

ImageUpload.propTypes = {
	onSubmit: PropTypes.func,
	multiple: PropTypes.bool,
	buttonText: PropTypes.string,
	uploadedImages: PropTypes.array.isRequired,
	pendingImages: PropTypes.array.isRequired,
	failedImages: PropTypes.array.isRequired,
	deleteImage: PropTypes.func.isRequired,
	reorderImages: PropTypes.func.isRequired,
};

ImageUpload.defaultProps = {
	onSubmit: () => {},
	multiple: false,
	buttonText: null,
};

export default ImageUpload;