import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';
import { addCouponCode, resetCart } from './cartActions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faTag, faTimes } from '@fortawesome/free-solid-svg-icons';
import utils from '../utils/utils';
import { Modal } from 'react-bootstrap';
import DeliveryDayMarketplaceModal from './deliveryDayMarketplaceModal';
import { getPaymentMethods } from '../paymentMethods/paymentMethodsActions';
import PaymentMethod from '../components/paymentMethod';
import PaymentMethodSelectionModal from './paymentMethodSelectionModal';
import NewPaymentMethodModal from './newPaymentMethodModal';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { createOrderBatch } from '../order/orderBatchesActions';
import ReactPixel from 'react-facebook-pixel';
import CouponModal from './couponModal';
import EVENT_NAME from '../components/constants/eventName';
import CartDelivery from './cartDelivery';
import CartPriceQuoteRows from './cartPriceQuoteRows';
import CartItems from './cartItems';
import SelfPickupConfirmModal from './selfPickupConfirmModal';

const CartPage = ({
  intl,
  cart,
  deliveryDayMarketplaces,
  currentUser,
  priceQuote,
  getPaymentMethods,
  selectedPaymentMethod,
  paymentMethods,
  createOrderBatch,
  history,
  resetCart,
  addCouponCode,
  address,
  note,
  useProfileAddress,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    ReactPixel.track('InitiateCheckout');
  }, []);

  useEffect(() => {
    // refresh stripe is needed for when new card add requires redirect back to cart
    getPaymentMethods({ userId: currentUser.id, refreshStripe: true });
  }, [getPaymentMethods, currentUser]);

  const [modalDeliveryDayMarketplace, setModalDeliveryDayMarketplace] = useState(null);
  const [showPaymentMethodSelection, setShowPaymentMethodSelection] = useState(false);
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showSelfPickupConfirmModal, setShowSelfPickupConfirmModal] = useState(false);

  const [selectedTimeRanges, setSelectedTimeRanges] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const submit = () => {
    if (!selectedPaymentMethod) {
      setError(intl.formatMessage({ id: 'cart.submit.error.no.payment.method' }));
      return;
    } else if (!useProfileAddress && !address) {
      setError(intl.formatMessage({ id: 'cart.submit.error.no.address.selected' }));
      return;
    }

    setLoading(true);
    setError(null);

    let allItemIds = [];

    const orders = cart.map((cartOrder) => {
      const items = cartOrder.orderItems.map((oi) => {
        return {
          itemId: oi.item.id,
          quantity: oi.quantity,
        };
      });

      allItemIds = allItemIds.concat(items.map((item) => item.itemId));

      const matchingDeliveryDay = deliveryDayMarketplaces.find((ddm) => ddm.marketplaceId === cartOrder.marketplace.id);

      const selectedTimeRange = selectedTimeRanges.find(
        (tr) => tr.deliveryDayId === matchingDeliveryDay.selectedDeliveryDayMarketplace.deliveryDayId
      );

      return {
        deliveryDayMarketplaceId: matchingDeliveryDay.selectedDeliveryDayMarketplace.id,
        deliveryDayTimeRangeId: selectedTimeRange ? selectedTimeRange.timeRange.id : null,
        items,
        marketplaceId: cartOrder.marketplace.id,
      };
    });

    createOrderBatch({
      orders,
      paymentMethodId: selectedPaymentMethod.id,
      payment: {
        paymentMethodId: selectedPaymentMethod.stripePaymentMethodId,
      },
      couponCode: priceQuote.coupon ? priceQuote.coupon.code : null,
      addressId: address?.id,
      note,
      useProfileAddress,
    })
      .then((orderBatch) => {
        resetCart();
        setLoading(false);
        utils.trackEvent(EVENT_NAME.orderCreated, {
          id: orderBatch.id,
          finalSum: orderBatch.finalSum,
          itemIds: allItemIds,
        });
        ReactPixel.track('Purchase', {
          content_ids: allItemIds,
          currency: 'eur',
          amount: priceQuote.finalSum,
        });
        history.push({
          pathname: '/makse-kinnitus',
        });
      })
      .catch((error) => {
        setError(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
      });
  };

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'cart.helmet.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1 className="m-b-6">
            <FormattedMessage id="cart.title" />
          </h1>

          <CartItems setModalDeliveryDayMarketplace={setModalDeliveryDayMarketplace} />

          <div className="cart__coupon m-b-6 text-right">
            {priceQuote?.infoMessage || priceQuote?.errorMessage ? (
              <p className={priceQuote.infoMessage ? 'text-info' : 'text-danger'}>
                {priceQuote.infoMessage || priceQuote.errorMessage}
              </p>
            ) : (
              ''
            )}

            {priceQuote && priceQuote.coupon ? (
              <>
                <h3 className="">
                  <FormattedMessage id="cart.coupon.title" />
                </h3>
                <div className="m-b-1">
                  {priceQuote.coupon.code}{' '}
                  <strong>
                    {priceQuote.coupon.discountPercent !== null
                      ? `-${priceQuote.coupon.discountPercent}%`
                      : `-${priceQuote.coupon.discountSum}€`}
                  </strong>{' '}
                  <button onClick={() => addCouponCode(null)} className="text-danger btn btn-normal-link m-r-2">
                    <FormattedMessage id="cart.coupon.remove" />
                  </button>
                </div>
              </>
            ) : (
              ''
            )}
            <button onClick={() => setShowCouponModal(true)} className="btn btn-secondary btn-small">
              <FontAwesomeIcon icon={faTag} /> <FormattedMessage id="cart.coupon.add.coupon" />
            </button>
          </div>

          <CartPriceQuoteRows />

          <h3 className="">
            <FormattedMessage id="cart.delivery.time.title" />
          </h3>
          <CartDelivery selectedTimeRanges={selectedTimeRanges} setSelectedTimeRanges={setSelectedTimeRanges} />

          <h3 className="">
            <FormattedMessage id="cart.payment.title" />
          </h3>
          <div className="cart__payment m-b-6">
            {selectedPaymentMethod ? (
              <>
                <div onClick={() => setShowPaymentMethodSelection(true)}>
                  <PaymentMethod paymentMethod={selectedPaymentMethod} hidePrimaryNotice={true} />
                </div>
                <div>
                  <button className="btn btn-normal-link" onClick={() => setShowPaymentMethodSelection(true)}>
                    <FormattedMessage id="cart.payment.choose.another" />
                  </button>
                </div>
              </>
            ) : (
              ''
            )}

            {!selectedPaymentMethod ? (
              <button onClick={() => setShowNewPaymentModal(true)} className="btn btn-normal-link">
                <FontAwesomeIcon icon={faCreditCard} /> <FormattedMessage id="cart.payment.add.card" />
              </button>
            ) : (
              ''
            )}
          </div>

          <Loader show={loading} />
          <ErrorMessage error={error} />

          <div className="text-center">
            <button className="btn btn-primary" onClick={submit}>
              <FormattedMessage id={'cart.cta'} />
            </button>
          </div>
        </div>
      </div>

      <Footer />

      <Modal show={!!modalDeliveryDayMarketplace} onHide={() => setModalDeliveryDayMarketplace(null)}>
        <div onClick={() => setModalDeliveryDayMarketplace(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {modalDeliveryDayMarketplace ? (
          <DeliveryDayMarketplaceModal
            closeModal={() => setModalDeliveryDayMarketplace(null)}
            deliveryDayMarketplaceOption={modalDeliveryDayMarketplace}
          />
        ) : (
          ''
        )}
      </Modal>

      <Modal show={showPaymentMethodSelection} onHide={() => setShowPaymentMethodSelection(false)}>
        <div onClick={() => setShowPaymentMethodSelection(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <PaymentMethodSelectionModal
          closeModal={() => setShowPaymentMethodSelection(false)}
          openNewMethodModal={() => {
            setShowPaymentMethodSelection(false);
            setShowNewPaymentModal(true);
          }}
        />
      </Modal>

      <Modal show={showNewPaymentModal} onHide={() => setShowNewPaymentModal(false)}>
        <div onClick={() => setShowNewPaymentModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {paymentMethods ? (
          <NewPaymentMethodModal closeModal={() => setShowNewPaymentModal(false)} onSuccess={() => setError(null)} />
        ) : (
          ''
        )}
      </Modal>

      <Modal show={showCouponModal} onHide={() => setShowCouponModal(false)}>
        <div onClick={() => setShowCouponModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <CouponModal closeModal={() => setShowCouponModal(false)} onSuccess={() => setShowCouponModal(false)} />
      </Modal>

      <Modal show={showSelfPickupConfirmModal} onHide={() => setShowSelfPickupConfirmModal(false)}>
        <div onClick={() => setShowSelfPickupConfirmModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <SelfPickupConfirmModal
          onSuccess={() => {
            setShowSelfPickupConfirmModal(false);
            submit();
          }}
        />
      </Modal>
    </div>
  );
};

CartPage.propTypes = {};

CartPage.defaultProps = {};

const mapDispatchToProps = {
  getPaymentMethods,
  createOrderBatch,
  resetCart,
  addCouponCode,
};

function mapStateToProps(state) {
  return {
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
    cart: state.cart.cart,
    priceQuote: state.cart.priceQuote,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
    paymentMethods: state.paymentMethods.paymentMethods,
    currentUser: state.currentUser.user,
    address: state.cart.address,
    note: state.cart.note,
    useProfileAddress: state.cart.useProfileAddress,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartPage));
