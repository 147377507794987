import { useQuery } from 'react-query';
import apiCalls from '../utils/apiCalls';

export function useItemCollections({ itemCollectionId }) {
  const { data, ...rest } = useQuery(
    'businessProfiles',
    () => apiCalls.getItemCollections({ itemCollectionId }).then(({ data: { itemCollections } }) => itemCollections),
    {
      enabled: !!itemCollectionId,
    }
  );
  return { itemCollections: data, ...rest };
}

export function useServiceAreas(params) {
  const { data, ...rest } = useQuery(['serviceAreas', params], () =>
    apiCalls.getServiceAreas(params).then(({ data: { serviceAreas } }) => serviceAreas)
  );
  return { serviceAreas: data, ...rest };
}
