const EVENT_NAME = {
	marketplaceView: 'Marketplace view',
	itemView: 'Item view',
	addToCart: 'Add to cart',
	removeFromCart: 'Remove from cart',
	register: 'Register',
	checkout: 'Checkout',
	addPaymentMethod: 'Add payment method',
	orderCreated: 'Order created',
	orderSuccess: 'Order success',
};

export default EVENT_NAME;