import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { setCurrentUser } from '../user/currentUserActions';
import ATTRIBUTION_CHANNEL from '../components/constants/attributionChannel';
import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';

const AttributionForm = ({ currentUser, setCurrentUser }) => {
  const [comment, setComment] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const onFormSubmit = (channel) => {
    const user = {
      ...currentUser,
      attributionChannel: {
        channel: selectedChannel || channel,
        comment: comment,
      },
    };
    setLoading(true);

    apiCalls
      .editUser({ user })
      .then((response) => {
        setCurrentUser(response.data.user);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const channels = Object.keys(ATTRIBUTION_CHANNEL).map((key) => {
    const channel = ATTRIBUTION_CHANNEL[key];
    return (
      <div
        onClick={() => {
          setSelectedChannel(channel);
          if ([ATTRIBUTION_CHANNEL.other, ATTRIBUTION_CHANNEL.influencer].indexOf(channel) === -1) {
            onFormSubmit(channel);
          }
        }}
        className={`form__choice m-b-2 ${selectedChannel === channel ? 'm-active' : ''}`}
      >
        <FormattedMessage id={`attribution.form.field.channel.${channel}`} />
      </div>
    );
  });

  return (
    <div className="attribution-form">
      <h3>
        <FormattedMessage id="attribution.form.header" />
      </h3>
      {channels}

      <Loader show={loading} />
      <ErrorMessage error={errorMessage} />

      {[ATTRIBUTION_CHANNEL.other, ATTRIBUTION_CHANNEL.influencer].indexOf(selectedChannel) !== -1 ? (
        <>
          <div className="form-group field field-string">
            <label className="control-label">
              <FormattedMessage
                id={
                  selectedChannel === ATTRIBUTION_CHANNEL.influencer
                    ? 'attribution.form.field.comment.influencer.label'
                    : 'attribution.form.field.comment.other.label'
                }
              />
            </label>
            <input
              className="form-control"
              placeholder=""
              autoFocus
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <button onClick={onFormSubmit} className="btn btn-primary">
            <FormattedMessage id="attribution.form.cta" />
          </button>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

AttributionForm.propTypes = {};

AttributionForm.defaultProps = {};

const mapDispatchToProps = {
  setCurrentUser,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AttributionForm));
