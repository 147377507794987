import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import Helmet from 'react-helmet';
import { useMarketplaces } from './apiHooks';
import utils from '../utils/utils';
import ItemCategoriesScroll from './itemCategoriesScroll';
import ExpandedMarketplacePreview from '../components/expandedMarketplacePreview';
import { getMarketplaceItems } from '../ctaGroup/marketplaceItemActions';
import { connect } from 'react-redux';

const ShopMarketplaces = ({ match, categories, getMarketplaceItems }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.secondLevelCategory, match.params.firstLevelCategory]);

  const intl = useIntl();

  const firstLevelCategory =
    categories && match.params.firstLevelCategory
      ? categories.find((firstLevel) => utils.convertToUrlString(firstLevel.name) === match.params.firstLevelCategory)
      : null;

  const {
    marketplaces,
    isFetching: marketplacesFetching,
    isError: isMarketplacesError,
    error: marketplacesError,
  } = useMarketplaces({
    itemFirstLevelCategory: firstLevelCategory?.name,
  });

  useEffect(() => {
    if (marketplaces && marketplaces.length) {
      getMarketplaceItems({
        marketplaceIds: marketplaces.map((mp) => mp.id).join(','),
        limit: 8,
      });
    }
  }, [marketplaces, getMarketplaceItems]);

  const firstLevelCategoryTranslationCode = firstLevelCategory
    ? `item.first.level.category.${firstLevelCategory.name}`
    : null;

  const firstLevelCategoryTranslation =
    firstLevelCategoryTranslationCode && intl.messages[firstLevelCategoryTranslationCode]
      ? intl.formatMessage({ id: firstLevelCategoryTranslationCode })
      : firstLevelCategoryTranslationCode
      ? firstLevelCategory.name
      : null;

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: 'shop.items.helmet.title.with.category' },
            { category: firstLevelCategoryTranslation }
          )}
        </title>
      </Helmet>

      <Loader show={marketplacesFetching} />
      {isMarketplacesError ? <ErrorMessage error={utils.getErrorMessageFromResponse(marketplacesError)} /> : ''}

      {categories ? <ItemCategoriesScroll firstLevelCategories={categories} hideSecondLevelCategories={true} /> : ''}

      {marketplaces?.length ? (
        <div className="m-b-6">
          <h3>
            <FormattedMessage id="shop.items.marketplaces.title" />
          </h3>
          {marketplaces.map((marketplace) => (
            <ExpandedMarketplacePreview marketplace={marketplace} />
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

const mapDispatchToProps = {
  getMarketplaceItems: getMarketplaceItems,
};

export default connect(() => {}, mapDispatchToProps)(ShopMarketplaces);
