import {
  DELIVERY_DAY_MARKETPLACES_GET_BEGIN,
  DELIVERY_DAY_MARKETPLACES_GET_SUCCESS,
  DELIVERY_DAY_MARKETPLACES_GET_FAILURE,
  DELIVERY_DAY_MARKETPLACES_SET_SUCCESS,
  PREFERRED_DELIVERY_DAY_SET_SUCCESS,
  UNIFY_DELIVERY_DAYS,
} from './deliveryDayMarketplacesActions';
import utils from "../utils/utils";

const initialState = {
  deliveryDayMarketplaces: [],
  loading: false,
  error: null,
  preferredDeliveryDayId: null,
};

export default function deliveryDayMarketplacesReducer(state = initialState, action) {
  switch (action.type) {
    case DELIVERY_DAY_MARKETPLACES_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case DELIVERY_DAY_MARKETPLACES_GET_SUCCESS: {
      const newDeliveryDayMarketplaces = state.deliveryDayMarketplaces.slice(); // needed to trigger state change

      const mappedDtos = [];
      action.payload.deliveryDayMarketplaces.forEach((ddm) => {
        const existingIndex = mappedDtos
          .findIndex((dto) => dto.marketplaceId === ddm.marketplaceId);

        if (existingIndex === -1) {
          mappedDtos.push({
            marketplaceId: ddm.marketplaceId,
            deliveryDayMarketplaces: [ddm],
            selectedDeliveryDayMarketplace: ddm,
          });
        } else {
          mappedDtos[existingIndex].deliveryDayMarketplaces.push(ddm);
        }
      });

      mappedDtos.forEach((mappedDto) => {
        const existingIndex = newDeliveryDayMarketplaces
          .findIndex((dto) => dto.marketplaceId === mappedDto.marketplaceId);

        if (existingIndex === -1) {
          newDeliveryDayMarketplaces.push(mappedDto);
        } else {
          // this is to avoid resetting selected DDM when visiting marketplace view
          replaceMappedDtoSelectedDdmIfNeeded(mappedDto, newDeliveryDayMarketplaces, existingIndex);
          newDeliveryDayMarketplaces[existingIndex] = mappedDto;
        }
      });

      const preferredDeliveryDayId = state.preferredDeliveryDayId || getPreferredDeliveryDayFromDtos(newDeliveryDayMarketplaces);
      if (preferredDeliveryDayId && action.payload.unifySelectedDeliveryDays) {
        setSelectedDdmIfPossible(preferredDeliveryDayId, newDeliveryDayMarketplaces);
      }

      return {
        ...state,
        loading: false,
        deliveryDayMarketplaces: newDeliveryDayMarketplaces,
      };
    }
    case DELIVERY_DAY_MARKETPLACES_SET_SUCCESS: {
      const newDeliveryDayMarketplaces = state.deliveryDayMarketplaces.slice(); // needed to trigger state change

      const existing = newDeliveryDayMarketplaces
        .find(ddm => ddm.marketplaceId === action.payload.marketplaceId);

      if (!existing) {
        return state;
      }

      existing.selectedDeliveryDayMarketplace = action.payload.deliveryDayMarketplace;

      return {
        ...state,
        loading: false,
        deliveryDayMarketplaces: newDeliveryDayMarketplaces
      };
    }
    case PREFERRED_DELIVERY_DAY_SET_SUCCESS: {
      const newDeliveryDayMarketplaces = state.deliveryDayMarketplaces.slice(); // needed to trigger state change

      let preferredDeliveryDayId = state.preferredDeliveryDayId;
      const ddmDtosWithDeliveryDay = newDeliveryDayMarketplaces.filter(ddmDto => ddmDto.deliveryDayMarketplaces
        .find(ddm => ddm.deliveryDayId === action.payload.deliveryDayId));

      if (ddmDtosWithDeliveryDay.length === newDeliveryDayMarketplaces.length) {
        preferredDeliveryDayId = action.payload.deliveryDayId;
      }

      return {
        ...state,
        preferredDeliveryDayId,
      };
    }
    case UNIFY_DELIVERY_DAYS: {
      const newDeliveryDayMarketplaces = state.deliveryDayMarketplaces.slice(); // needed to trigger state change
      const preferredDeliveryDayId = state.preferredDeliveryDayId ||
        getPreferredDeliveryDayFromDtos(newDeliveryDayMarketplaces, action.payload.marketplaceIdsInCart);
      if (preferredDeliveryDayId) {
        setSelectedDdmIfPossible(preferredDeliveryDayId, newDeliveryDayMarketplaces);
      } else {
        return state;
      }

      return {
        ...state,
        deliveryDayMarketplaces: newDeliveryDayMarketplaces,
      };
    }
    case DELIVERY_DAY_MARKETPLACES_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        deliveryDayMarketplaces: []
      };
    default:
      return state;
  }
}

// deliveryDay unification is needed for:
// cart button add (after confirm or with no confirm)
// cart init on refresh
function setSelectedDdmIfPossible(preferredDeliveryDayId, ddmDtos) {
  ddmDtos.forEach(ddmDto => {
    const selectedDdm = ddmDto.deliveryDayMarketplaces.find(ddm => ddm.deliveryDayId === preferredDeliveryDayId);
    if (selectedDdm) {
      ddmDto.selectedDeliveryDayMarketplace = selectedDdm;
    }
  });
}

function getPreferredDeliveryDayFromDtos(allDdmDtos, marketplaceIdsInCart) {
  if (allDdmDtos.length === 0) {
    return null;
  }

  let ddmDtos;
  if (marketplaceIdsInCart && marketplaceIdsInCart.length) {
    ddmDtos = allDdmDtos.filter(ddmDto => marketplaceIdsInCart.indexOf(ddmDto.marketplaceId) !== -1)
  } else {
    ddmDtos = allDdmDtos;
  }

  const selectedDeliveryDayMarketplaces = ddmDtos.map(ddmDto => ddmDto.selectedDeliveryDayMarketplace);
  selectedDeliveryDayMarketplaces.sort((a, b) => {
    const aDeliveryDate =  utils.getDateFromString(a.deliveryDate);
    const bDeliveryDate =  utils.getDateFromString(b.deliveryDate);

    return aDeliveryDate.diff(bDeliveryDate);
  });

  const latestDeliveryDayId = selectedDeliveryDayMarketplaces[selectedDeliveryDayMarketplaces.length - 1].deliveryDayId;

  if (ddmDtos.every(ddmDto => ddmDto.deliveryDayMarketplaces.some(ddm => ddm.deliveryDayId === latestDeliveryDayId))) {
    return latestDeliveryDayId;
  } else {
    return null;
  }
}

function replaceMappedDtoSelectedDdmIfNeeded(mappedDto, existingDeliveryDayMarketplaces, existingIndex) {
  const selectedDdmIndex = mappedDto.deliveryDayMarketplaces
    .findIndex(ddm => ddm.deliveryDayId === existingDeliveryDayMarketplaces[existingIndex].selectedDeliveryDayMarketplace.deliveryDayId);
  if (selectedDdmIndex !== -1) {
    mappedDto.selectedDeliveryDayMarketplace = mappedDto.deliveryDayMarketplaces[selectedDdmIndex];
  }
}