import {
  CART_REFRESH_SUCCESS,
  CART_ADD_SUCCESS,
  CART_REMOVE_SUCCESS,
  CART_MARKETPLACE_REMOVE_SUCCESS,
  CART_RESET_SUCCESS,
  PRICE_QUOTE_GET_BEGIN,
  PRICE_QUOTE_GET_SUCCESS,
  PRICE_QUOTE_GET_FAILURE,
  COUPON_CODE_SET_SUCCESS,
  USE_PROFILE_ADDRESS_SET_SUCCESS,
  ADDRESS_SET_SUCCESS,
  NOTE_SET_SUCCESS,
} from './cartActions';
import ReactPixel from 'react-facebook-pixel';
import utils from '../utils/utils';
import EVENT_NAME from '../components/constants/eventName';

const initialState = {
  priceQuote: null,
  cart: [],
  couponCode: null,
  loading: false,
  error: null,
  useProfileAddress: true,
  address: null,
  note: null,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case CART_RESET_SUCCESS:
      saveCartToLocalStorage([]);

      return {
        ...state,
        cart: [],
        couponCode: null,
      };
    case CART_REFRESH_SUCCESS:
      const newCart = getCartFromLocalStorage();

      if (!newCart || !newCart.length) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          cart: newCart,
        };
      }
    case CART_ADD_SUCCESS: {
      const newCart = state.cart.slice();

      const existingOrderIndex = newCart.findIndex((order) => order.marketplace.id === action.payload.marketplace.id);

      if (existingOrderIndex === -1) {
        newCart.push({
          marketplace: action.payload.marketplace,
          orderItems: [
            {
              item: action.payload.item,
              quantity: 1,
            },
          ],
        });
      } else {
        const existingOrder = newCart[existingOrderIndex];

        const existingOrderItemIndex = existingOrder.orderItems.findIndex(
          (orderItem) => orderItem.item.id === action.payload.item.id
        );

        if (existingOrderItemIndex === -1) {
          existingOrder.orderItems.push({
            item: action.payload.item,
            quantity: 1,
          });
        } else {
          existingOrder.orderItems[existingOrderItemIndex].quantity += 1;
        }
      }

      saveCartToLocalStorage(newCart);
      utils.trackEvent(EVENT_NAME.addToCart, {
        id: action.payload.item.id,
        name: action.payload.item.name,
      });

      ReactPixel.track('AddToCart', {
        content_ids: [action.payload.item.id],
        content_name: action.payload.item.name,
        content_type: 'product',
      });

      return {
        ...state,
        loading: false,
        cart: newCart,
      };
    }
    case CART_REMOVE_SUCCESS: {
      const newCart = state.cart.slice();

      const existingOrderIndex = newCart.findIndex((order) => order.marketplace.id === action.payload.marketplaceId);

      if (existingOrderIndex === -1) {
        return state;
      }

      const existingOrder = newCart[existingOrderIndex];

      const existingOrderItemIndex = existingOrder.orderItems.findIndex(
        (orderItem) => orderItem.item.id === action.payload.item.id
      );

      if (existingOrderItemIndex === -1) {
        return state;
      } else {
        if (existingOrder.orderItems[existingOrderItemIndex].quantity > 1) {
          existingOrder.orderItems[existingOrderItemIndex].quantity -= 1;
        } else {
          existingOrder.orderItems.splice(existingOrderItemIndex, 1);
          if (!existingOrder.orderItems.length) {
            newCart.splice(existingOrderIndex, 1);
          }
        }
      }

      saveCartToLocalStorage(newCart);
      utils.trackEvent(EVENT_NAME.removeFromCart, {
        id: action.payload.item.id,
        name: action.payload.item.name,
      });

      return {
        ...state,
        loading: false,
        cart: newCart,
      };
    }
    case CART_MARKETPLACE_REMOVE_SUCCESS: {
      const newCart = state.cart.slice();

      const existingOrderIndex = newCart.findIndex((order) => order.marketplace.id === action.payload.marketplace.id);

      if (existingOrderIndex === -1) {
        return state;
      }

      newCart.splice(existingOrderIndex, 1);
      saveCartToLocalStorage(newCart);

      return {
        ...state,
        loading: false,
        cart: newCart,
      };
    }
    case PRICE_QUOTE_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case COUPON_CODE_SET_SUCCESS:
      return {
        ...state,
        loading: false,
        couponCode: action.payload.couponCode,
      };
    case USE_PROFILE_ADDRESS_SET_SUCCESS:
      return {
        ...state,
        useProfileAddress: action.payload.useProfileAddress,
        address: action.payload.useProfileAddress ? null : state.address,
        note: action.payload.useProfileAddress ? null : state.note,
      };
    case ADDRESS_SET_SUCCESS:
      return {
        ...state,
        address: action.payload.address,
      };
    case NOTE_SET_SUCCESS:
      return {
        ...state,
        note: action.payload.note,
      };
    case PRICE_QUOTE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        priceQuote: action.payload.priceQuote,
      };
    case PRICE_QUOTE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        priceQuote: null,
      };
    default:
      return state;
  }
}

function saveCartToLocalStorage(newCart) {
  localStorage.setItem('cart', JSON.stringify(newCart));
}

function getCartFromLocalStorage() {
  const cart = localStorage.getItem('cart');

  return JSON.parse(cart);
}
