import apiCalls from '../utils/apiCalls';

export const MARKETPLACE_ITEMS_GET_BEGIN   = 'MARKETPLACE_ITEMS_GET_BEGIN';
export const MARKETPLACE_ITEMS_GET_SUCCESS = 'MARKETPLACE_ITEMS_GET_SUCCESS';
export const MARKETPLACE_ITEMS_GET_FAILURE = 'MARKETPLACE_ITEMS_GET_FAILURE';

export const marketplaceItemsGetBegin = () => ({
  type: MARKETPLACE_ITEMS_GET_BEGIN
});

export const marketplaceItemsGetSuccess = (items, setHasAllItems) => ({
  type: MARKETPLACE_ITEMS_GET_SUCCESS,
  payload: { items, setHasAllItems }
});

export const marketplaceItemsGetFailure = error => ({
  type: MARKETPLACE_ITEMS_GET_FAILURE,
  payload: { error }
});

export const getMarketplaceItems = (params, setHasAllItems) => {
  return dispatch => {
    if (!setHasAllItems) {
      // don't show loader in case of extra items fetch
      dispatch(marketplaceItemsGetBegin());
    }
    return apiCalls.getItems(params)
      .then(res => res.data)
      .then(data => {
        dispatch(marketplaceItemsGetSuccess(data.items, setHasAllItems));
        return data.items;
      })
      .catch(error => {
        dispatch(marketplaceItemsGetFailure(error));
        throw error;
      });
  };
};