import React from 'react';
import { useIntl } from 'react-intl';
import ItemColumns from '../components/itemColumns';
import utils from '../utils/utils';

const ShopItemsThirdLevelCategories = ({
  thirdLevelCategories,
  items,
  deliveryDayMarketplaces,
  showNextDeliveryDate,
}) => {
  const intl = useIntl();

  const itemsGroupedByThirdLevelCategory = items ? utils.groupBy(items, 'thirdLevelCategory') : null;

  const thirdLevelCategoriesOrderedAndPresent =
    itemsGroupedByThirdLevelCategory && thirdLevelCategories
      ? Object.keys(itemsGroupedByThirdLevelCategory).map((thirdLevelCatName) => {
          return thirdLevelCategories.find((cat) => cat.name === thirdLevelCatName);
        })
      : [];

  thirdLevelCategoriesOrderedAndPresent.sort((a, b) => {
    return a.order - b.order;
  });

  const itemsForModalScroll = thirdLevelCategoriesOrderedAndPresent
    ? thirdLevelCategoriesOrderedAndPresent.reduce((result, thirdLevelCategory) => {
        return result.concat(itemsGroupedByThirdLevelCategory[thirdLevelCategory?.name || 'null']);
      }, [])
    : [];

  return (
    <>
      {thirdLevelCategoriesOrderedAndPresent?.map((thirdLevelCategory, index) => {
        const categoryItems = itemsGroupedByThirdLevelCategory[thirdLevelCategory?.name || 'null'];
        const categoryTranslation = thirdLevelCategory ? `item.third.level.category.${thirdLevelCategory.name}` : null;

        return (
          <div key={index}>
            {thirdLevelCategory ? (
              <h3>
                {intl.messages[categoryTranslation]
                  ? intl.formatMessage({ id: categoryTranslation })
                  : thirdLevelCategory.name}
              </h3>
            ) : (
              ''
            )}
            {categoryItems ? (
              <ItemColumns
                items={categoryItems}
                itemsForModal={itemsForModalScroll}
                showMarketplaceName={true}
                showNavigator={true}
                showNextDeliveryDate={showNextDeliveryDate}
                deliveryDayMarketplaces={deliveryDayMarketplaces}
                disableModalListener={index !== 0}
              />
            ) : (
              ''
            )}
          </div>
        );
      })}
    </>
  );
};

export default ShopItemsThirdLevelCategories;
