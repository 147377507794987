import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getPaymentMethods } from '../paymentMethods/paymentMethodsActions';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import { useSetupIntentCreate } from './apiHooks';
import NewPaymentMethodModalPaymentElement from './newPaymentMethodModalPaymentElement';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import utils from '../utils/utils';

function NewPaymentMethodModal({ closeModal, getPaymentMethods, currentUser, intl, onSuccess }) {
  const [setupIntent, setSetupIntent] = useState(null);

  const stripe = useStripe();

  const { createSetupIntent, error, isLoading, isError } = useSetupIntentCreate((setupIntent) =>
    setSetupIntent(setupIntent)
  );

  useEffect(() => {
    createSetupIntent();
  }, [createSetupIntent]);

  return (
    <div className="b-modal">
      <div className="payment-method-modal">
        <h2 className="m-b-6">
          <FormattedMessage id="cart.new.payment.method.modal.title" />
        </h2>

        <div className="payment-method-modal__choices m-b-6">
          <Loader show={isLoading} />
          {isError ? <ErrorMessage error={utils.getErrorMessageFromResponse(error)} /> : ''}

          {setupIntent ? (
            <Elements stripe={stripe} options={{ clientSecret: setupIntent.clientSecret }}>
              <NewPaymentMethodModalPaymentElement
                onSuccess={() => {
                  getPaymentMethods({ userId: currentUser.id, refreshStripe: true });
                  closeModal();
                }}
                refreshSetupIntent={() => createSetupIntent()}
              />
            </Elements>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

NewPaymentMethodModal.propTypes = {
  onSuccess: PropTypes.func,
};

NewPaymentMethodModal.defaultProps = {
  onSuccess: () => {},
};

const mapDispatchToProps = {
  getPaymentMethods,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    paymentMethods: state.paymentMethods.paymentMethods,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewPaymentMethodModal));
