import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import PREFERRED_PRICE_DISPLAY from '../components/constants/preferredPriceDisplay';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import CartButton from '../components/cartButton';
import ReactTooltip from 'react-tooltip';

function CartItems({ cart, deliveryDayMarketplaces, setModalDeliveryDayMarketplace, intl }) {
  const { formatMessage } = useIntl();

  return (
    <>
      {cart.map((cartOrder, index) => {
        const matchingDeliveryDay = deliveryDayMarketplaces.find(
          (ddm) => ddm.marketplaceId === cartOrder.marketplace.id
        );

        const selectedDeliveryDate =
          matchingDeliveryDay && matchingDeliveryDay.selectedDeliveryDayMarketplace
            ? utils.getFormattedDateFromString(
                matchingDeliveryDay.selectedDeliveryDayMarketplace.deliveryDate,
                DATE_FORMAT.displayWithoutTime
              )
            : '';

        return (
          <div key={index} className="m-b-6">
            <h3 className="m-b-3">{cartOrder.marketplace.name}</h3>
            {selectedDeliveryDate ? (
              <p>
                <small>
                  <FormattedMessage id="cart.order.delivery.day.label" />: <strong>{selectedDeliveryDate}</strong>{' '}
                  <button
                    onClick={() => setModalDeliveryDayMarketplace(matchingDeliveryDay)}
                    className="btn btn-normal-link btn-small"
                  >
                    <FormattedMessage id="cart.order.delivery.day.change" />
                  </button>
                </small>
              </p>
            ) : (
              ''
            )}
            {cartOrder.orderItems.map((orderItem, index) => {
              const itemImage = orderItem.item.images.length ? (
                <img className="cart__item-image" src={orderItem.item.images[0].thumbnailImage} alt="" />
              ) : (
                ''
              );

              const showUnitTooltip =
                orderItem.item.priceFields.unitPrice &&
                orderItem.item.priceFields.preferredPriceDisplay === PREFERRED_PRICE_DISPLAY.unitPrice;

              return (
                <div className="m-b-3 cart__item" key={index}>
                  <div className="row">
                    <div className="col-8 col-md-7">
                      <Link to={`/tootja/${cartOrder.marketplace.id}?itemId=${orderItem.item.id}`}>
                        {itemImage} {utils.getItemName(orderItem.item, formatMessage)}
                      </Link>
                    </div>
                    <div className="col-4 col-md-2 text-md-right order-md-1">
                      <strong>
                        {utils.getRoundedPrice(orderItem.item.priceFields.price * orderItem.quantity)}€{' '}
                        {showUnitTooltip ? (
                          <span
                            className="text-primary"
                            data-tip={intl.formatMessage({
                              id: 'cart.item.price.unit.explanation',
                            })}
                          >
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </span>
                        ) : (
                          ''
                        )}
                      </strong>
                    </div>
                    <div className="col-12 col-md-3 order-md-0 cart__item-buttons">
                      <CartButton
                        item={orderItem.item}
                        marketplace={cartOrder.marketplace}
                        small={true}
                        neutralColor={true}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
      <ReactTooltip place="top" type="dark" effect="float" />
    </>
  );
}

CartItems.propTypes = {
  deliveryDayMarketplaces: PropTypes.array,
  priceQuote: PropTypes.object,
};

CartItems.defaultProps = {};

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartItems));
