import apiCalls from '../utils/apiCalls';
import utils from "../utils/utils";

export const DELIVERY_DAY_MARKETPLACES_GET_BEGIN   = 'DELIVERY_DAY_MARKETPLACES_GET_BEGIN';
export const DELIVERY_DAY_MARKETPLACES_GET_SUCCESS = 'DELIVERY_DAY_MARKETPLACES_GET_SUCCESS';
export const DELIVERY_DAY_MARKETPLACES_GET_FAILURE = 'DELIVERY_DAY_MARKETPLACES_GET_FAILURE';
export const DELIVERY_DAY_MARKETPLACES_SET_SUCCESS = 'DELIVERY_DAY_MARKETPLACES_SET_SUCCESS';
export const PREFERRED_DELIVERY_DAY_SET_SUCCESS = 'PREFERRED_DELIVERY_DAY_SET_SUCCESS';
export const UNIFY_DELIVERY_DAYS = 'UNIFY_DELIVERY_DAYS';

export const deliveryDayMarketplacesGetBegin = () => ({
  type: DELIVERY_DAY_MARKETPLACES_GET_BEGIN
});

export const deliveryDayMarketplacesGetSuccess = (deliveryDayMarketplaces, unifySelectedDeliveryDays) => ({
  type: DELIVERY_DAY_MARKETPLACES_GET_SUCCESS,
  payload: { deliveryDayMarketplaces, unifySelectedDeliveryDays }
});

export const deliveryDayMarketplacesGetFailure = error => ({
  type: DELIVERY_DAY_MARKETPLACES_GET_FAILURE,
  payload: { error }
});

export const deliveryDayMarketplaceSetSuccess = (deliveryDayMarketplace, marketplaceId) => ({
  type: DELIVERY_DAY_MARKETPLACES_SET_SUCCESS,
  payload: { deliveryDayMarketplace, marketplaceId }
});

export const preferredDeliveryDaySetSuccess = (deliveryDayId) => ({
  type: PREFERRED_DELIVERY_DAY_SET_SUCCESS,
  payload: { deliveryDayId }
});

export const unifyDeliveryDaysSuccess = (marketplaceIdsInCart) => ({
  type: UNIFY_DELIVERY_DAYS,
  payload: { marketplaceIdsInCart }
});

export const getDeliveryDayMarketplaces = (params, unifySelectedDeliveryDays) => {
  return dispatch => {
    dispatch(deliveryDayMarketplacesGetBegin());
    return apiCalls.getDeliveryDayMarketplaces(params)
      .then(res => res.data)
      .then(data => {
        dispatch(deliveryDayMarketplacesGetSuccess(data.deliveryDayMarketplaces, unifySelectedDeliveryDays));
        return data.deliveryDayMarketplaces;
      })
      .catch(error => {
        dispatch(deliveryDayMarketplacesGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};

export const setDeliveryDayMarketplace = (deliveryDayMarketplace, marketplaceId) => {
  return dispatch => {
    dispatch(deliveryDayMarketplaceSetSuccess(deliveryDayMarketplace, marketplaceId));
  };
};

export const setPreferredDeliveryDayId = (deliveryDayId) => {
  return dispatch => {
    dispatch(preferredDeliveryDaySetSuccess(deliveryDayId));
  };
};

export const unifyDeliveryDays = (marketplaceIdsInCart) => {
  return dispatch => {
    dispatch(unifyDeliveryDaysSuccess(marketplaceIdsInCart));
  };
};