import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import { setAddress, setNote, setUseProfileAddress } from './cartActions';
import UserAddressesSelector from './userAddressesSelector';

function CartDelivery({
  priceQuote,
  selectedTimeRanges,
  setSelectedTimeRanges,
  useProfileAddress,
  setUseProfileAddress,
  currentUser,
  setAddress,
  address,
  note,
  setNote,
}) {
  const activeServiceAreaId = address
    ? address.serviceAreaId
    : currentUser?.activeBusinessProfile
    ? currentUser.activeBusinessProfile.deliveryAddress
      ? currentUser.activeBusinessProfile.deliveryAddress.serviceAreaId
      : currentUser.activeBusinessProfile.address.serviceAreaId
    : currentUser?.serviceArea?.id;

  useEffect(() => {
    // selects last possible timeRange as default
    if (!selectedTimeRanges.length && priceQuote.deliveryFees.length && activeServiceAreaId) {
      const timeRangesToBeSelected = [];
      priceQuote.deliveryFees.forEach((deliveryFee) => {
        const userTimeRanges = deliveryFee.deliveryDay.timeRanges.filter((tr) => {
          return activeServiceAreaId === tr.serviceAreaId;
        });
        if (userTimeRanges.length) {
          timeRangesToBeSelected.push({
            deliveryDayId: deliveryFee.deliveryDay.id,
            timeRange: userTimeRanges[userTimeRanges.length - 1],
          });
        }
      });

      if (timeRangesToBeSelected.length) {
        setSelectedTimeRanges(timeRangesToBeSelected);
      }
    }
  }, [priceQuote, selectedTimeRanges, setSelectedTimeRanges, activeServiceAreaId]);

  const setSelectedTimeRange = (deliveryDayId, timeRange) => {
    const newTimeRanges = selectedTimeRanges.slice();
    const existingTimeRange = newTimeRanges.findIndex((tr) => tr.deliveryDayId === deliveryDayId);
    if (existingTimeRange === -1) {
      newTimeRanges.push({
        deliveryDayId,
        timeRange,
      });
    } else {
      newTimeRanges[existingTimeRange].timeRange = timeRange;
    }

    setSelectedTimeRanges(newTimeRanges);
  };

  return (
    <div className="cart__delivery m-b-6">
      {priceQuote ? (
        <div className="m-b-3">
          {priceQuote.deliveryFees.map((deliveryFee, index) => {
            const selectedTimeRange = selectedTimeRanges.find((tr) => tr.deliveryDayId === deliveryFee.deliveryDay.id);

            return (
              <div className="m-b-3" key={index}>
                <p className="m-b-1">
                  <FormattedMessage
                    id={'cart.delivery.days'}
                    values={{
                      deliveryDate: utils.getFormattedDateFromString(
                        deliveryFee.deliveryDate,
                        DATE_FORMAT.displayWithoutTimeAndDayName
                      ),
                    }}
                  />
                </p>

                {deliveryFee.deliveryDay.timeRanges
                  .filter((tr) => {
                    return activeServiceAreaId === tr.serviceAreaId;
                  })
                  .map((tr, index) => {
                    const isDisabled = tr.remaining === 0;

                    return (
                      <div
                        onClick={() => (!isDisabled ? setSelectedTimeRange(deliveryFee.deliveryDay.id, tr) : {})}
                        className={`cart__delivery-ranges-range m-r-2 m-b-2 ${
                          selectedTimeRange && selectedTimeRange.timeRange.id === tr.id ? 'm-active' : ''
                        } ${isDisabled ? 'text-muted m-disabled' : ''}`}
                        key={index}
                      >
                        {utils.getFormattedTimeRange(tr)}

                        {(tr.remaining && tr.remaining <= 10) || isDisabled ? (
                          <div>
                            <small>
                              <FormattedMessage
                                id={'cart.delivery.days.remaining'}
                                values={{
                                  remaining: tr.remaining,
                                }}
                              />
                            </small>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </div>
            );
          })}

          {priceQuote.deliveryFees.length > 1 ? (
            <div className="alert alert-primary">
              <small>
                <FormattedMessage id={'cart.orders.delivery.fee.multiple.warning'} />
              </small>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}

      <UserAddressesSelector
        setSelectedAddress={setAddress}
        selectedAddress={address}
        showNoteOption={true}
        note={note}
        setNote={setNote}
        useProfileAddress={useProfileAddress}
        setUseProfileAddress={setUseProfileAddress}
      />
    </div>
  );
}

CartDelivery.propTypes = {
  selectedTimeRanges: PropTypes.array,
  setSelectedTimeRanges: PropTypes.func,
};

CartDelivery.defaultProps = {};

const mapDispatchToProps = {
  setUseProfileAddress,
  setAddress,
  setNote,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    priceQuote: state.cart.priceQuote,
    useProfileAddress: state.cart.useProfileAddress,
    address: state.cart.address,
    note: state.cart.note,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CartDelivery));
