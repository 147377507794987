import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

function SubscriptionQuantitySelector({ quantity, setQuantity }) {
  return (
    <div className="d-flex align-items-center">
      <div className={`cart-item-status m-r-2 ${quantity === 0 ? 'm-white' : ''}`}>
        <span
          className="cart-item-status__remove"
          onClick={() => {
            if (quantity) {
              setQuantity(quantity - 1);
            }
          }}
        >
          <FontAwesomeIcon icon={faMinus} />
        </span>
        <span className="cart-item-status__quantity text-h4">{quantity}</span>
        <span
          className="cart-item-status__add"
          onClick={() => {
            setQuantity(quantity + 1);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </span>
      </div>
    </div>
  );
}

SubscriptionQuantitySelector.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
};

export default SubscriptionQuantitySelector;
