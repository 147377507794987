import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from '../utils/utils';
import { matchPath } from 'react-router';
import { Context } from '../i18n/localeWrapper';

const CategoryNavigator = ({ history, categories, match }) => {
  const localeContext = useContext(Context);
  const intl = useIntl();

  if (!categories) {
    return '';
  }

  // needed because sidebar sits above category sub-paths
  const matchWithCategoryParams = matchPath(history.location.pathname, {
    path: `${match.path}/:firstLevelCategory/:secondLevelCategory?`,
  });

  const firstLevelCategory = matchWithCategoryParams?.params.firstLevelCategory
    ? categories.find(
        (firstLevel) => utils.convertToUrlString(firstLevel.name) === matchWithCategoryParams.params.firstLevelCategory
      )
    : null;

  const secondLevelCategory =
    matchWithCategoryParams?.params.secondLevelCategory && firstLevelCategory
      ? firstLevelCategory.secondLevelCategories.find(
          (secondLevel) =>
            utils.convertToUrlString(secondLevel.name) === matchWithCategoryParams.params.secondLevelCategory
        )
      : null;

  const slugifiedFirstLevelCategory = firstLevelCategory ? utils.convertToUrlString(firstLevelCategory.name) : null;
  const slugifiedSecondLevelCategory = secondLevelCategory ? utils.convertToUrlString(secondLevelCategory.name) : null;

  return (
    <div className="text-muted">
      <small>
        <Link className="text-muted" to={`${match.path}`}>
          <FormattedMessage id={'shop.items.navigator.home'} />
        </Link>
        {firstLevelCategory ? (
          <>
            <span className="m-r-2 m-l-2">></span>
            <Link className="text-muted" to={`${match.path}/${slugifiedFirstLevelCategory}`}>
              <FormattedMessage id={`item.first.level.category.${firstLevelCategory.name}`} />
            </Link>
          </>
        ) : (
          ''
        )}
        {secondLevelCategory ? (
          <>
            <span className="m-r-2 m-l-2">></span>
            <Link
              className="text-muted"
              to={`${match.path}/${slugifiedFirstLevelCategory}/${slugifiedSecondLevelCategory}`}
            >
              {utils.getSecondCategoryTranslation(secondLevelCategory, localeContext.locale, intl)}
            </Link>
          </>
        ) : (
          ''
        )}
      </small>
    </div>
  );
};

CategoryNavigator.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default withRouter(CategoryNavigator);
