import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setSelectedServiceCountry } from '../serviceAreas/serviceAreasActions';
import rockBaby from './assets/v2/rock-baby.png';
import rockHector from './assets/v2/rock-hector.png';
import rockFruits from './assets/v2/rock-fruits.png';
import delivery from './assets/v2/delivery.jpg';
import Footer from '../components/footer';
import FirstOrderCoupon from '../components/firstOrderCoupon';
import LandingMap from './landingMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import SERVICE_COUNTRIES from '../components/constants/serviceCountries';
import { useServiceAreas } from './apiHooks';
import utils from '../utils/utils';
import SubscriptionProduct from '../subscription/subscriptionProduct';

const Landing = ({ intl, setSelectedServiceCountry, selectedServiceCountry, currentUser }) => {
  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const { formatMessage, messages } = useIntl();

  const { serviceAreas } = useServiceAreas({});

  const getCtaLink = ({ buttonStyle = 'btn-primary' }) => {
    if (selectedServiceCountry) {
      return (
        <Link to={mainCtaLink} className={`btn ${buttonStyle}`}>
          <FormattedMessage id={'landing.first.cta'} />
        </Link>
      );
    } else {
      return (
        <button onClick={() => setShowCountrySelector(true)} className={`btn ${buttonStyle}`}>
          <FormattedMessage id={'landing.first.cta.select.country'} />
        </button>
      );
    }
  };

  const mainCtaLink = '/subscription';

  const products = utils.getSubscriptionProducts({ currentUser });

  return (
    <div className="landing">
      <Helmet>
        <title>{intl.formatMessage({ id: 'landing.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'landing.helmet.description' })} />
      </Helmet>

      <FirstOrderCoupon />
      <div className="container-fluid landing__first text-center">
        <div className="row row-eq-height">
          <div className="col-12 col-md-6 landing__first-right order-md-2" />
          <div className="col-12 col-md-6 landing__first-left align-self-center">
            <div className="landing__first-left-content text-left">
              <h1 className="landing__first-left-content-title">
                <FormattedMessage id={'landing.first.title'} />
              </h1>
              <p className="landing__first-left-content-description">
                <FormattedMessage id={'landing.first.description'} />
              </p>

              <div className="landing__first-left-content-cta d-none">
                {getCtaLink({ buttonStyle: 'btn-secondary' })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container m-b-6 landing__numbers">
        <div className="row row-eq-height">
          <div className="col-12 col-md-4 m-b-3">
            <div
              className="landing__numbers-number text-center m-b-6"
              style={{ backgroundImage: `url("${rockHector}")` }}
            />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.direct.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.direct.description'} />
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 m-b-3">
            <div
              className="landing__numbers-number text-center m-b-4"
              style={{ backgroundImage: `url("${rockFruits}")` }}
            />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.fresh.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.fresh.description'} />
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4 m-b-3">
            <div className="landing__numbers-number m-b-4" style={{ backgroundImage: `url("${rockBaby}")` }} />
            <div className="landing__numbers-content text-center">
              <h3>
                <FormattedMessage id={'landing.second.delivery.title'} />
              </h3>
              <p className="m-b-1">
                <FormattedMessage id={'landing.second.delivery.description'} />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid landing__delivery">
        <div className="row">
          <div
            className="col-12 col-md-6 landing__delivery-left"
            style={{ backgroundImage: `url("${delivery}")` }}
          ></div>
          <div className="col-12 col-md-6 landing__delivery-right text-center align-self-center">
            <div className="landing__delivery-right-content">
              <h2 className="text-left">
                <FormattedMessage id={'landing.basket.title'} />
              </h2>
              <p className="text-large text-left m-b-6">
                <FormattedMessage id={'landing.basket.description'} />
              </p>
              <div className="text-left m-b-4 d-none">{getCtaLink({})}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__products">
        <div className="container">
          <h2 className="text-center">
            <FormattedMessage id={'landing.products.title'} />
          </h2>
          <p className="text-large m-b-6 text-center">
            <FormattedMessage id={'landing.products.description'} />
          </p>
          {products.map((product) => (
            <SubscriptionProduct product={product} landing={true} />
          ))}
        </div>
      </div>

      <div className="landing__map m-b-6 p-t-6 p-b-6">
        <div className="container">
          <h2 className="text-center">
            <FormattedMessage id={'landing.map.title'} />
          </h2>
          <p className="text-center m-b-6">
            <FormattedMessage id={'landing.map.description'} />
          </p>
          <LandingMap serviceAreas={serviceAreas} />
        </div>
      </div>

      <Footer />

      <Modal show={!!showCountrySelector} onHide={() => setShowCountrySelector(false)}>
        <div onClick={() => setShowCountrySelector(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <div className="b-modal">
          <h2>
            <FormattedMessage id="country.select.modal.title" />
          </h2>
          <p className="m-b-6">
            <FormattedMessage id="country.select.modal.description" />
          </p>
          <div>
            {SERVICE_COUNTRIES.map((country) => {
              const countryName = messages[`country.${country.countryAlpha2Code}`]
                ? formatMessage({ id: `country.${country.countryAlpha2Code}` })
                : country.name;
              return (
                <div
                  key={country.countryAlpha2Code}
                  onClick={() => {
                    setSelectedServiceCountry(country);
                    setShowCountrySelector(false);
                  }}
                  className={`form__choice m-b-2 ${
                    selectedServiceCountry?.countryAlpha2Code === country.countryAlpha2Code ? 'm-active' : ''
                  }`}
                >
                  {country.flagEmoji} {countryName}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};

Landing.propTypes = {};

Landing.defaultProps = {};

function mapStateToProps(state) {
  return {
    ctaGroups: state.ctaGroups.ctaGroups,
    currentUser: state.currentUser.user,
    currentUserLoading: state.currentUser.loading,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

const mapDispatchToProps = {
  setSelectedServiceCountry,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Landing));
