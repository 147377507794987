import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { Link, withRouter } from 'react-router-dom';
import { getOrderBatches } from './orderBatchesActions';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import OrderInvoicePaymentStatus from '../components/orderInvoicePaymentStatus';

const MyOrdersPage = ({ intl, getOrderBatches, orderBatches, loading, error, currentUser }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getOrderBatches({ userId: currentUser.id });
  }, [getOrderBatches, currentUser.id]);

  const orderBatchesList =
    orderBatches && orderBatches.length
      ? orderBatches.map((orderBatch, index) => {
          const dateCreated = utils.getFormattedDateFromString(
            orderBatch.dateCreated,
            DATE_FORMAT.displayWithTimeAndWeekday
          );

          const paymentStatus =
            orderBatch.payment.status === 'COMPLETED' ? (
              ''
            ) : orderBatch.payment.status === 'REFUNDED' ? (
              <div className="alert alert-warning">
                <FormattedMessage id="my.orders.order.payment.status.refunded" />
              </div>
            ) : (
              <div className="alert alert-danger">
                <FormattedMessage
                  id="my.orders.order.payment.status.needs.action"
                  values={{
                    id: orderBatch.payment.id,
                  }}
                />
              </div>
            );

          const invoicePaymentStatus = orderBatch.payment.invoicePaymentStatus ? (
            <OrderInvoicePaymentStatus invoicePaymentStatus={orderBatch.payment.invoicePaymentStatus} />
          ) : (
            ''
          );

          return (
            <Link to={`/tellimus/${orderBatch.id}`}>
              <div key={index} className="order-batch m-b-6">
                <h3 className="m-b-0 order-batch__title">
                  <FormattedMessage
                    id="my.orders.order.title"
                    values={{
                      id: orderBatch.id,
                    }}
                  />
                </h3>
                <div className="text-muted m-b-2">
                  <small>{dateCreated}</small>
                </div>
                {paymentStatus}
                {invoicePaymentStatus}
                <div className="text-right">
                  <FormattedMessage
                    id="my.orders.order.total.sum"
                    values={{
                      sum: <strong className="text-h3">{orderBatch.finalSum}€</strong>,
                    }}
                  />
                </div>
              </div>
            </Link>
          );
        })
      : '';

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'my.orders.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1 className="m-b-6">
            <FormattedMessage id="my.orders.title" />
          </h1>

          <Loader show={loading} />
          <ErrorMessage error={error} />

          {orderBatchesList}
        </div>
      </div>

      <Footer />
    </div>
  );
};

MyOrdersPage.propTypes = {};

MyOrdersPage.defaultProps = {};

const mapDispatchToProps = {
  getOrderBatches,
};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentUser: state.currentUser.user,
    orderBatches: state.orderBatches.orderBatches,
    loading: state.orderBatches.loading,
    error: state.orderBatches.error,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(MyOrdersPage)));
