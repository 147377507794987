import React, {useEffect} from 'react';
import {injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";

const PrivacyPolicyPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({id: 'producer.helmet.title'})}</title>
        <meta name="description" content={intl.formatMessage({id: 'producer.helmet.description'})}/>
      </Helmet>
      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>Membo O&Uuml; privaatsuspoliitika</h1>
          <p>Viimati muudetud <strong>14.10.2021</strong></p>
          <ol>
            <li>
              <h2>&Uuml;lds&auml;tted</h2>
              <ol>
                <li>K&auml;esolev privaatsuspoliitika reguleerib isikuandmete kogumist, t&ouml;&ouml;tlemist ja s&auml;ilitamist k&auml;sitlevaid p&otilde;him&otilde;tteid. Isikuandmeid kogub t&ouml;&ouml;tleb ja s&auml;ilitab isikuandmete vastutav t&ouml;&ouml;tleja Membo O&Uuml;.</li>
                <li>Andmesubjekt privaatsuspoliitika t&auml;henduses on klient v&otilde;i muu f&uuml;&uuml;siline isik, kelle isikuandmeid andmet&ouml;&ouml;tleja t&ouml;&ouml;tleb.</li>
                <li>Klient privaatsuspoliitika t&auml;henduses on iga&uuml;ks, kes tellib andmet&ouml;&ouml;tleja iOS ja Android &auml;ppidest v&otilde;i kodulehelt kaupu v&otilde;i teenuseid.</li>
                <li>Andmet&ouml;&ouml;tleja j&auml;rgib &otilde;igusaktides s&auml;testatud andmete t&ouml;&ouml;tlemise p&otilde;him&otilde;tteid, muuhulgas t&ouml;&ouml;tleb andmet&ouml;&ouml;tleja isikuandmeid seaduslikult, &otilde;iglaselt ja turvaliselt. Andmet&ouml;&ouml;tleja on v&otilde;imeline kinnitama, et isikuandmeid on t&ouml;&ouml;deldud vastavalt &otilde;igusaktides s&auml;testatule.</li>
              </ol>
            </li>
            <li>
              <h2>Isikuandmete kogumine, t&ouml;&ouml;tlemine ja s&auml;ilitamine</h2>
              <ol>
                <li>Isikuandmed, mida andmet&ouml;&ouml;tleja kogub, t&ouml;&ouml;tleb ja s&auml;ilitab, on kogutud elektrooniliselt, peamiselt kodulehe, iOS ja android &auml;ppide ning e-posti vahendusel.</li>
                <li>Oma isikuandmete jagamisega annab andmesubjekt andmet&ouml;&ouml;tlejale &otilde;iguse koguda, korraldada, kasutada ja hallata privaatsuspoliitikas m&auml;&auml;ratletud eesm&auml;rgil isikuandmeid, mida andmesubjekt otse v&otilde;i kaudselt kodulehel kaupu v&otilde;i teenuseid ostes andmet&ouml;&ouml;tlejale jagab.</li>
                <li>Andmesubjekt vastutab selle eest, et tema poolt esitatud andmed oleksid t&auml;psed, &otilde;iged ja terviklikud. Teadlikult valeandmete esitamist peetakse privaatsuspoliitika rikkumiseks. Andmesubjekt on kohustatud andmet&ouml;&ouml;tlejat viivitamatult teavitama esitatud andmete muutumisest.</li>
                <li>Andmet&ouml;&ouml;tleja ei vastuta andmesubjekti poolt valeandmete esitamisest p&otilde;hjustatud kahju eest andmesubjektile v&otilde;i kolmandatele osapooltele.</li>
              </ol>
            </li>
            <li>
              <h2>Klientide isikuandmete t&ouml;&ouml;tlemine</h2>
              <ol>
                <li>Andmet&ouml;&ouml;tleja v&otilde;ib t&ouml;&ouml;delda j&auml;rgnevaid andmesubjekti isikuandmeid:</li>
                <ol>
                  <li>Ees- ja perekonnanimi;</li>
                  <li>S&uuml;nnikuup&auml;ev;</li>
                  <li>Telefoninumber;</li>
                  <li>E-posti aadress;</li>
                  <li>Kohaletoimetamise aadress;</li>
                  <li>Maksekaardi detailid;</li>
                </ol>
                <li>Lisaks eeltoodule on andmet&ouml;&ouml;tlejal &otilde;igus koguda kliendi kohta andmeid, mis on k&auml;ttesaadavad avalikes registrites.</li>
                <li>Isikuandmete t&ouml;&ouml;tlemise &otilde;iguslik alus on isikuandmete kaitse &uuml;ldm&auml;&auml;ruse paragrahv 6 lg 1 p-d a), b), c) ja f):</li>
                <ol>
                  <li>andmesubjekt on andnud n&otilde;usoleku t&ouml;&ouml;delda oma isikuandmeid &uuml;hel v&otilde;i mitmel konkreetsel eesm&auml;rgil;</li>
                  <li>isikuandmete t&ouml;&ouml;tlemine on vajalik andmesubjekti osalusel s&otilde;lmitud lepingu t&auml;itmiseks v&otilde;i lepingu s&otilde;lmimisele eelnevate meetmete v&otilde;tmiseks vastavalt andmesubjekti taotlusele;</li>
                  <li>isikuandmete t&ouml;&ouml;tlemine on vajalik vastutava t&ouml;&ouml;tleja juriidilise kohustuse t&auml;itmiseks;</li>
                  <li>isikuandmete t&ouml;&ouml;tlemine on vajalik vastutava t&ouml;&ouml;tleja v&otilde;i kolmanda isiku &otilde;igustatud huvi korral, v&auml;lja arvatud juhul, kui sellise huvi kaaluvad &uuml;les andmesubjekti huvid v&otilde;i p&otilde;hi&otilde;igused ja -vabadused, mille nimel tuleb kaitsta isikuandmeid, eriti juhul kui andmesubjekt on laps.</li>
                </ol>
                <li>Isikuandmete t&ouml;&ouml;tlemine vastavalt t&ouml;&ouml;tlemise eesm&auml;rgile:</li>
                <ol>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; julgeolek ja turvalisus. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash; vastavalt seaduses nimetatud t&auml;htaegadele</li>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; tellimuse t&ouml;&ouml;tlemine. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash;10 aastat</li>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; e-poe teenuste toimimise tagamine. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash;10 aastat</li>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; kliendihaldus. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash;10 aastat</li>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; finantstegevus, raamatupidamine. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash; vastavalt seaduses nimetatud t&auml;htaegadele</li>
                  <li>T&ouml;&ouml;tlemise eesm&auml;rk &ndash; turundus. Isikuandmete s&auml;ilitamise maksimaalne aeg &ndash;10 aastat</li>
                </ol>
                <li>Andmet&ouml;&ouml;tlejal on &otilde;igus klientide isikuandmeid jagada kolmandate isikutega, kelleks on n&auml;iteks volitatud andmet&ouml;&ouml;tlejad, raamatupidajad, transpordi- ja kullerettev&otilde;tted, &uuml;lekandeteenuseid pakkuvad ettev&otilde;tted. Andmet&ouml;&ouml;tleja on isikuandmete vastutav t&ouml;&ouml;tleja. Andmet&ouml;&ouml;tleja edastab maksete teostamiseks vajalikud isikuandmed volitatud t&ouml;&ouml;tleja Stripe Payments Europe, Limited-ile.</li>
                <li>Andmesubjekti isikuandmete t&ouml;&ouml;tlemisel ja s&auml;ilitamisel rakendab andmet&ouml;&ouml;tleja organisatoorseid ja tehnilisi meetmeid, mis tagavad isikuandmete kaitse juhusliku v&otilde;i ebaseadusliku h&auml;vitamise, muutmise, avalikustamise ja mis tahes muu ebaseadusliku t&ouml;&ouml;tlemise eest.</li>
                <li>Andmet&ouml;&ouml;tleja s&auml;ilitab andmesubjektide andmeid s&otilde;ltuvalt t&ouml;&ouml;tlemise eesm&auml;rgist, kuid mitte kauem kui 10 aastat.</li>
              </ol>
            </li>
            <li>
              <h2>Andmesubjekti &otilde;igused</h2>
              <ol>
                <li>Andmesubjektil on &otilde;igus saada ligip&auml;&auml;s oma isikuandmetele ning nendega tutvuda.</li>
                <li>Andmesubjektil on &otilde;igus saada informatsiooni tema isikuandmete t&ouml;&ouml;tlemise kohta.</li>
                <li>Andmesubjektil on &otilde;igus t&auml;iendada v&otilde;i parandada ebat&auml;pseid andmeid.</li>
                <li>Kui andmet&ouml;&ouml;tleja t&ouml;&ouml;tleb andmesubjekti isikuandmeid andmesubjekti n&otilde;usoleku alusel, siis on andmesubjektil &otilde;igus igal ajal n&otilde;usolek tagasi v&otilde;tta.</li>
                <li>Andmesubjektil on õigus nõuda oma andmete kustutamist.</li>
                <li>Andmesubjekt saab &otilde;iguste teostamiseks p&ouml;&ouml;rduda e-poe klienditoe poole aadressil info@membo.ee.</li>
                <li>Andmesubjektil on oma &otilde;iguste kaitseks v&otilde;imalik esitada kaebus Andmekaitse Inspektsioonile.</li>
              </ol>
            </li>
            <li>
              <h2>L&otilde;pps&auml;tted</h2>
              <ol>
                <li>K&auml;esolevad andmekaitsetingimused on koostatud koosk&otilde;las Euroopa Parlamendi ja n&otilde;ukogu m&auml;&auml;rusega (EL) nr 2016/679 f&uuml;&uuml;siliste isikute kaitse kohta isikuandmete t&ouml;&ouml;tlemisel ja selliste andmete vaba liikumise ning direktiivi 95/46 / E&Uuml; kehtetuks tunnistamise kohta / E&Uuml; (isikuandmete kaitse &uuml;ldm&auml;&auml;rus), Eesti Vabariigi isikuandmete kaitse seadusega ning Eesti Vabariigi ja Euroopa Liidu &otilde;igusaktidega.</li>
                <li>Andmet&ouml;&ouml;tlejal on &otilde;igus andmekaitsetingimusi osaliselt v&otilde;i t&auml;ielikult muuta, teavitades andmesubjekte muudatustest kodulehe <a href="https://membo.ee">https://membo.ee</a> kaudu.</li>
              </ol>
            </li>
          </ol>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

PrivacyPolicyPage.propTypes = {};

PrivacyPolicyPage.defaultProps = {};

export default injectIntl(PrivacyPolicyPage);
