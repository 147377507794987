import React from 'react';
import PropTypes from 'prop-types';
import Currency from "react-currency-formatter";

const PriceFormatter = ({ price, currency }) => {
  return (
    <Currency
      quantity={ price }
      currency={ currency }
      decimal="."
      group=" "
    />
    );
};

PriceFormatter.propTypes = {
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
};

PriceFormatter.defaultProps = {};

export default PriceFormatter;
