import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/index';
import { Link, withRouter } from 'react-router-dom';
import { refreshCart } from '../cart/cartActions';
import { refreshSelectedServiceArea } from '../serviceAreas/serviceAreasActions';

import NavbarRight from './navbarRight';
import NavbarXs from './navbarXs';

import logo from '../assets/logo-new-dark.svg';
import logoSmall from '../assets/logo-small.svg';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import LoginSignupModal from '../loginSignup/loginSignupModal';
import { refreshAuth } from '../user/currentUserActions';
import { Context } from '../i18n/localeWrapper';
import { parse } from 'qs';
import { useIntl } from 'react-intl';
import SearchPopup from './searchPopup';

const Navbar = ({ history, location, refreshCart, refreshAuth, refreshSelectedServiceArea, currentUserLoading }) => {
  const { formatMessage } = useIntl();

  const [query, setQuery] = useState('');
  const [showSearchXs, setShowSearchXs] = useState(false);

  useEffect(() => {
    refreshAuth();
  }, [refreshAuth]);

  useEffect(() => {
    refreshSelectedServiceArea();
  }, [refreshSelectedServiceArea]);

  useEffect(() => {
    if (!currentUserLoading) {
      refreshCart();
    }
  }, [refreshCart, currentUserLoading]);

  const localeContext = useContext(Context);
  const setLang = (code) => {
    localStorage.setItem('lang', code);
    localeContext.selectLanguage(code);
  };

  useEffect(() => {
    const searchParams = parse(location.search, { ignoreQueryPrefix: true });
    if (['en', 'et'].indexOf(searchParams.lang) !== -1) {
      setLang(searchParams.lang);
    }
    // eslint-disable-next-line
  }, []);

  const [isXsNavOpen, setIsXsNavOpen] = useState(false);
  const [loginModalTab, setLoginModalTab] = useState(null);

  useEffect(() => {
    window.analytics.page();
  }, [location.pathname]);

  history.listen(() => {
    setIsXsNavOpen(false);
  });

  function logout() {
    localStorage.removeItem('refreshToken');
    window.location = '/';
  }

  const navbarClasses = classNames({
    navbar: true,
    'm-show-xs-nav': isXsNavOpen,
    //'m-landing': location.pathname === '/',
  });

  return (
    <>
      <div className={navbarClasses}>
        <div className="container-fluid">
          <div className="navbar__left">
            <div className="navbar__left-logo m-r-6">
              <Link to="/">
                <div className="navbar__logo">
                  <img className="d-none d-sm-inline" src={logo} alt="logo" />
                  <img className="d-sm-none" src={logoSmall} alt="logo" />
                </div>
              </Link>
            </div>

            <div className="navbar__left-search d-none">
              <div className="form-group field field-string m-b-0 d-none d-sm-block">
                <input
                  onClick={(e) => setQuery(e.target.value)}
                  className="form-control"
                  placeholder={formatMessage({ id: 'navbar.search.placeholder' })}
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className="d-sm-none m-l-2" onClick={() => setShowSearchXs(!showSearchXs)}>
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          </div>

          <NavbarRight
            setLang={setLang}
            logout={logout}
            openLoginModal={setLoginModalTab}
            setIsXsNavOpen={setIsXsNavOpen}
            isXsNavOpen={isXsNavOpen}
          />
        </div>
      </div>

      <NavbarXs isXsNavOpen={isXsNavOpen} openLoginSignupModal={setLoginModalTab} logout={logout} />

      {query || showSearchXs ? (
        <SearchPopup
          typedQuery={query}
          setTypedQuery={setQuery}
          close={() => {
            setQuery(null);
            setShowSearchXs(false);
          }}
        />
      ) : (
        ''
      )}

      <Modal show={!!loginModalTab} onHide={() => setLoginModalTab(null)}>
        <div onClick={() => setLoginModalTab(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <LoginSignupModal closeLoginModal={() => setLoginModalTab(null)} loginSignupModalTab={loginModalTab} />
      </Modal>
    </>
  );
};

Navbar.propTypes = {};

Navbar.defaultProps = {};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentUserLoading: state.currentUser.loading,
  };
}

const mapDispatchToProps = {
  refreshCart,
  refreshAuth,
  refreshSelectedServiceArea,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
