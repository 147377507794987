import {
  CTA_GROUPS_GET_BEGIN,
  CTA_GROUPS_GET_SUCCESS,
  CTA_GROUPS_GET_FAILURE,
} from './ctaGroupActions';

const initialState = {
  ctaGroups: null,
  loading: false,
  error: null
};

export default function ctaGroupReducer(state = initialState, action) {
  switch(action.type) {
    case CTA_GROUPS_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CTA_GROUPS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        ctaGroups: action.payload.ctaGroups
      };
    case CTA_GROUPS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ctaGroups: null
      };
    default:
      return state;
  }
}