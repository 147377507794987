import React from 'react';
import {FormattedMessage} from "react-intl";
import utils from "../utils/utils";
import DATE_FORMAT from "../components/constants/dateFormat";
import {setDeliveryDayMarketplace} from "../deliveryDayMarketplaces/deliveryDayMarketplacesActions";
import {connect} from "react-redux";
import classNames from "classnames";

function DeliveryDayMarketplaceModal({closeModal, deliveryDayMarketplaceOption, setDeliveryDayMarketplace}) {
  return (
    <div className="b-modal">
      <div className="delivery-day-marketplace-modal">
        <h2>
          <FormattedMessage id="cart.order.delivery.day.modal.title"/>
        </h2>
        <p>
          <FormattedMessage id="cart.order.delivery.day.modal.description"/>
        </p>

        <div className="delivery-day-marketplace-modal__choices">
          {
            deliveryDayMarketplaceOption.deliveryDayMarketplaces.map((ddm, index) => {
              const formattedDeliveryDate = utils.getFormattedDateFromString(
                ddm.deliveryDate,
                DATE_FORMAT.displayWithoutTimeAndDayName)

              const choiceClasses = classNames({
                "delivery-day-marketplace-modal__choices-choice m-b-3 text-capitalize": true,
                "m-active": ddm.id === deliveryDayMarketplaceOption.selectedDeliveryDayMarketplace.id,
              });

              return (
                <div className={choiceClasses} key={index} onClick={() => {
                  setDeliveryDayMarketplace(ddm, deliveryDayMarketplaceOption.marketplaceId);
                  closeModal();
                }}>
                  <strong>
                    {formattedDeliveryDate}
                  </strong>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

DeliveryDayMarketplaceModal.propTypes = {
};

DeliveryDayMarketplaceModal.defaultProps = {};

const mapDispatchToProps = {
  setDeliveryDayMarketplace
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDayMarketplaceModal);