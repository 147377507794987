import React, {useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {addCouponCode} from "./cartActions";

function CouponModal({onSuccess, addCouponCode}) {
  const [couponCode, setCouponCode] = useState("");

  const submit = async () => {
    addCouponCode(couponCode);
    onSuccess();
  };

  return (
    <div className="b-modal">
      <div className="coupon-modal">
        <h2 className="m-b-6">
          <FormattedMessage id="coupon.modal.title"/>
        </h2>

        <div className="coupon-modal__form m-b-6">
          <div className="form-group field field-string">
            <label className="control-label"><FormattedMessage id={'coupon.modal.form.field.coupon.label'}/></label>
            <input className="form-control" placeholder="" autoFocus
                   type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value? e.target.value.toUpperCase(): e.target.value)}/>
          </div>
        </div>

        <div className="coupon-modal__cta">
          <button className="btn btn-primary" onClick={submit}>
            <FormattedMessage id="coupon.modal.cta"/>
          </button>
        </div>
      </div>
    </div>
  );
}

CouponModal.propTypes = {
  onSuccess: PropTypes.func,
};

CouponModal.defaultProps = {
  onSuccess: () => {},
};

const mapDispatchToProps = {
  addCouponCode
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CouponModal));