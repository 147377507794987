import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { Link, withRouter } from 'react-router-dom';
import { useBusinessProfiles, useUserEdit } from './apiHooks';
import { getCurrentUser, setCurrentUser } from '../user/currentUserActions';
import utils from '../utils/utils';

const BusinessProfilesPage = ({ intl, currentUser, setCurrentUser }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    isLoading: loading,
    businessProfiles,
    isError,
    error,
  } = useBusinessProfiles({
    userId: currentUser?.id,
  });

  const {
    editUser,
    isLoading: editLoading,
    isError: isEditError,
    error: editError,
  } = useUserEdit(
    (user) => {
      setCurrentUser(user);
    },
    () => window.scrollTo(0, 0)
  );

  const businessProfilesList = businessProfiles?.length ? (
    businessProfiles.map((businessProfile) => {
      const isActiveBusiness = currentUser.activeBusinessProfileId === businessProfile.id;
      return (
        <div className="block m-b-6">
          <h3 className="">{businessProfile.companyName}</h3>
          <div className="m-b-1">
            <FormattedMessage id="business.profiles.profile.registry.code" />: {businessProfile.registryCode}
          </div>
          <div className="m-b-1">
            <FormattedMessage id="business.profiles.profile.vat.number" />: {businessProfile.vatNumber || '-'}
          </div>
          <div className={businessProfile.deliveryAddress ? 'm-b-1' : 'm-b-3'}>
            <FormattedMessage id="business.profiles.profile.address" />: {businessProfile.address.street}
          </div>
          {businessProfile.deliveryAddress ? (
            <div className="m-b-3">
              <FormattedMessage id="business.profiles.profile.delivery.address" />:{' '}
              {businessProfile.deliveryAddress.street}
            </div>
          ) : (
            ''
          )}

          <div>
            <button
              onClick={() => {
                editUser({
                  user: {
                    ...currentUser,
                    activeBusinessProfileId: isActiveBusiness ? null : businessProfile.id,
                  },
                });
              }}
              className={`btn btn-small m-r-2 ${isActiveBusiness ? 'btn-default' : 'btn-primary'}`}
            >
              <FormattedMessage
                id={
                  isActiveBusiness ? 'business.profiles.profile.disable.active' : 'business.profiles.profile.set.active'
                }
              />
            </button>
            <Link
              className="btn btn-small btn-default"
              key={businessProfile.id}
              to={`/business-account/${businessProfile.id}`}
            >
              <FormattedMessage id="business.profiles.profile.cta.edit" />
            </Link>
          </div>
        </div>
      );
    })
  ) : businessProfiles?.length === 0 ? (
    <p>
      <FormattedMessage id="business.profiles.no.profiles.found" />
    </p>
  ) : (
    ''
  );

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'business.profiles.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1 className="m-b-6">
            <FormattedMessage id="business.profiles.title" />
          </h1>

          <Loader show={loading || editLoading} />
          {isError || isEditError ? <ErrorMessage error={utils.getErrorMessageFromResponse(error || editError)} /> : ''}

          <div className="m-b-3">{businessProfilesList}</div>
          <div>
            <Link to={`/business-account/new`} className="btn btn-primary">
              <FormattedMessage id="business.profiles.cta.create.new" />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

BusinessProfilesPage.propTypes = {};

BusinessProfilesPage.defaultProps = {};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

const mapDispatchToProps = {
  setCurrentUser,
  getCurrentUser,
};

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(BusinessProfilesPage)));
