import React, { useEffect } from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    window.gtag("send", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  };

  const HOC = (props) => {
    useEffect(
      () => trackPage(props.location.pathname),
      [props.location.pathname]
    );

    return <WrappedComponent {...props} />;
  };

  return HOC;
};
