import React, { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import utils from '../utils/utils';
import { Link } from 'react-router-dom';
import { Context } from '../i18n/localeWrapper';

const ShopItemsFirstLevelCategory = ({ match, categories }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.firstLevelCategory]);

  const intl = useIntl();

  const firstLevelCategory =
    categories && match.params.firstLevelCategory
      ? categories.find((firstLevel) => utils.convertToUrlString(firstLevel.name) === match.params.firstLevelCategory)
      : null;

  const filteredFirstLevelCategories = firstLevelCategory ? [firstLevelCategory] : categories;

  const firstLevelCategoryTranslationCode = firstLevelCategory
    ? `item.first.level.category.${firstLevelCategory.name}`
    : null;

  const firstLevelCategoryTranslation =
    firstLevelCategoryTranslationCode && intl.messages[firstLevelCategoryTranslationCode]
      ? intl.formatMessage({ id: firstLevelCategoryTranslationCode })
      : firstLevelCategoryTranslationCode
      ? firstLevelCategory.name
      : null;

  const shopPath = match.path.includes('/business') ? '/shop/business' : '/shop';

  const localeContext = useContext(Context);

  return (
    <div className="d-none d-lg-block">
      {firstLevelCategoryTranslation ? (
        <Helmet>
          <title>
            {intl.formatMessage(
              { id: 'shop.items.helmet.title.with.category' },
              { category: firstLevelCategoryTranslation }
            )}
          </title>
        </Helmet>
      ) : (
        ''
      )}

      {filteredFirstLevelCategories?.map((firstLevelCategory) => {
        const categoryTranslation = `item.first.level.category.${firstLevelCategory.name}`;
        const slugifiedFirstLevelCategoryName = utils.convertToUrlString(firstLevelCategory.name);
        const slugifiedSecondLevelCategoryName = utils.convertToUrlString(
          firstLevelCategory.secondLevelCategories[0].name
        );
        const categoryLink = `${shopPath}/${slugifiedFirstLevelCategoryName}/${slugifiedSecondLevelCategoryName}`;

        return (
          <div key={firstLevelCategory.name} className="shop-items-index__categories m-b-6">
            <Link to={categoryLink}>
              <div className={`shop-items-index__categories-category m-r-3`}>
                <h2 className="shop-items-index__categories-category-name">
                  <img
                    className="shop-items-index__categories-category-icon m-r-2"
                    src={firstLevelCategory.iconUrl}
                    alt={`${firstLevelCategory.name} icon`}
                  />
                  {intl.messages[categoryTranslation]
                    ? intl.formatMessage({ id: categoryTranslation })
                    : firstLevelCategory.name}
                </h2>

                <div className="row">
                  {firstLevelCategory.secondLevelCategories.map((secondLevelCategory) => {
                    const slugifiedFirstLevelCategoryName = utils.convertToUrlString(firstLevelCategory.name);
                    const slugifiedSecondLevelCategoryName = utils.convertToUrlString(secondLevelCategory.name);
                    const categoryLink = `${shopPath}/${slugifiedFirstLevelCategoryName}/${slugifiedSecondLevelCategoryName}`;

                    return (
                      <div key={secondLevelCategory.name} className="col-4 col-lg-3 col-xl-2">
                        <Link key={secondLevelCategory.name} to={categoryLink}>
                          <div className={`first-level-category m-b-6 text-center`}>
                            {secondLevelCategory.imageUrl ? (
                              <img
                                className="first-level-category__image m-b-1"
                                src={secondLevelCategory.imageUrl}
                                alt=""
                              />
                            ) : (
                              ''
                            )}
                            <div className="first-level-category__name align-middle">
                              {utils.getSecondCategoryTranslation(secondLevelCategory, localeContext.locale, intl)}
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ShopItemsFirstLevelCategory;
