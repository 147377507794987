import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Form from "react-jsonschema-form";
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames/index';

import utils from '../utils/utils';
import ImageUpload from "./imageUpload";

function GeneralForm({title, onChange, onSubmit, uploadImage, deleteImage, onImagesChange, formSchema, formUiSchema,
                       formData, formFields, uploadedImages, buttonCopy, errorMessage, warningMessage, loading,
                       hideImageUpload, singleImage, icon}) {

  const [pendingImages, setPendingImages] = useState([]);
  const [failedImages, setFailedImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(false);

  const onImageSubmit = (newImages) => {
    const newPendingImages = pendingImages.concat(newImages);
    setPendingImages(newPendingImages);
    uploadPendingImages(newPendingImages);
  };

  const uploadPendingImages = (newPendingImages) => {
    setImagesLoading(true);

    const newUploadedImages = singleImage? []: uploadedImages;
    const newFailedImages = failedImages;

    /*
    * Uploads images one by one:
    * - creates an initial Promise.resolve() as the second argument of Array.reduce
    * - promiseChain.then returns another promise which resolves when image is uploaded
    * - next promiseChain.then is executed once previous image has been uploaded
    * - in case of an error, returns Promise.resolve() in order to continue other img uploads
    * */
    return newPendingImages.reduce((promiseChain, image) => {
      return promiseChain.then(() =>
        uploadImage(image)
          .then((result) => {
            newUploadedImages.push(result.data.uploadedImage);
            onImagesChange(newUploadedImages);
          })
          .catch((errors) => {
            const failedImage = image;
            failedImage.errorMessage = utils.getErrorMessageFromResponse(errors, true);
            newFailedImages.push(failedImage);
            return Promise.resolve();
          })
      );
    }, Promise.resolve()).then(() => {
      setFailedImages(newFailedImages);
      setPendingImages([]);
      setImagesLoading(false);
    });
  };

  const startDeleteImage = (uploadedImage) => {
    setImagesLoading(true);

    deleteImage(uploadedImage)
      .then(() => {
        const newUploadedImages = uploadedImages;
        const deletedIndex = newUploadedImages.findIndex(image => image.id === uploadedImage.id);
        if (deletedIndex >= 0) {
          newUploadedImages.splice(deletedIndex, 1)
        }
        setImagesLoading(false);
        onImagesChange(newUploadedImages);
      })
  };

  const reorderImages = ({oldIndex, newIndex}) => {
    const newImages = uploadedImages;
    const [removed] = newImages.splice(oldIndex, 1);
    newImages.splice(newIndex, 0, removed);
    onImagesChange(newImages);
  };

  const getFormHeader = () => {
    const headerClasses = classNames({
      "with-icon": icon,
      "form-header": true
    });

    const iconImg = icon? (
      <img className="form-header__icon" src={icon} alt="Form type icon"/>
    ): '';

    return (
      <div className={headerClasses}>
        {iconImg}
        <h2 className="form-header__title">
          {title}
        </h2>
      </div>
    );
  };

  const errorMessageElem = errorMessage ? (
    <div className="alert alert-error">
      {errorMessage}
    </div>
  ) : '';

  const warningMessageElem = warningMessage ? (
    <div className="alert alert-warning">
      {warningMessage}
    </div>
  ) : '';

  const imageFields = hideImageUpload? '': (
    <ImageUpload onSubmit={onImageSubmit}
                 multiple={ !singleImage }
                 pendingImages={pendingImages}
                 uploadedImages={uploadedImages}
                 deleteImage={startDeleteImage}
                 reorderImages={reorderImages}
                 failedImages={failedImages}/>
  );

  const imagesLoadingNotification = imagesLoading? (
    <div className="alert alert-info">
      <FormattedMessage id="form.images.loading.notification"/>
    </div>
  ): '';

  const buttonClasses = classNames({
    "btn-loading": loading || imagesLoading,
    "btn btn-primary": true
  });

  const header = title? getFormHeader(): '';

  return (
    <React.Fragment>
      {header}

      {imageFields}

      {errorMessageElem}
      {warningMessageElem}

      <Form schema={formSchema} uiSchema={formUiSchema} fields={formFields} showErrorList={false}
            formData={formData} onChange={onChange} noValidate={true}>
        <div/>
      </Form>

      { imagesLoadingNotification }

      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" className={buttonClasses} onClick={onSubmit} disabled={loading}>
            <FormattedMessage id={buttonCopy}/>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

GeneralForm.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  uploadImage: PropTypes.func,
  deleteImage: PropTypes.func,
  onImagesChange: PropTypes.func,
  formSchema: PropTypes.object,
  formUiSchema: PropTypes.object,
  formData: PropTypes.object,
  formFields: PropTypes.object,
  uploadedImages: PropTypes.array,
  buttonCopy: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  warningMessage: PropTypes.string,
  loading: PropTypes.bool,
  hideImageUpload: PropTypes.bool,
  singleImage: PropTypes.bool,
};

GeneralForm.defaultProps = {
  onChange: () => {
  },
  onSubmit: () => {
  },
  uploadImage: () => {
  },
  deleteImage: () => {
  },
  formSchema: {},
  formUiSchema: {},
  formData: {},
  formFields: {},
  buttonCopy: '',
  errorMessage: null,
  loading: false,
  hideImageUpload: false,
  uploadedImages: [],
  singleImage: false,
};

export default GeneralForm;