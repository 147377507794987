import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";
import GeneralForm from "../components/generalForm";
import apiCalls from "../utils/apiCalls";
import utils from "../utils/utils";
import ReCAPTCHA from "react-google-recaptcha";

const PasswordResetTriggerPage = ({intl}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaResult, setCaptchaResult] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [resetFields, setResetFields] = useState({email: ''});

  const onSubmit = () => {

    if (!captchaResult) {
      setErrorMessage(intl.formatMessage({id: "password.reset.trigger.error.no.captcha"}));
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    apiCalls.triggerPasswordReset({email: resetFields.email, captchaResult: captchaResult})
      .then((response) => {
        setShowSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const formSchema = {
    "type": "object",
    "required": [],
    "properties": {
      "email": {
        "type": "string",
        "title": intl.formatMessage({id: "password.reset.form.field.email.label"})
      },
    }
  };

  const uiSchema = {};

  const content = showSuccess? (
    <>
      <h1>
        <FormattedMessage id="password.reset.trigger.success.title"/>
      </h1>

      <p>
        <FormattedMessage id="password.reset.trigger.success.description"/>
      </p>
    </>
  ): (
    <>
      <h1>
        <FormattedMessage id="password.reset.trigger.title"/>
      </h1>

      <p>
        <FormattedMessage id="password.reset.trigger.description"/>
      </p>

      <div className="m-b-2">
        <ReCAPTCHA
          sitekey="6LeER1YaAAAAAFgJNJYAphOm6ZEvAB4ZOW_28kCM"
          onChange={(result) => setCaptchaResult(result)}
        />
      </div>

      <GeneralForm title={null}
                   onChange={(event) => setResetFields(event.formData)}
                   onSubmit={onSubmit}

                   formSchema={formSchema}
                   formUiSchema={uiSchema}
                   formFields={{}}

                   formData={resetFields}
                   buttonCopy={"password.reset.trigger.form.cta"}
                   errorMessage={errorMessage}
                   loading={loading}
                   hideImageUpload={true}/>
    </>
  );

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'password.reset.trigger.helmet.title'}) }</title>
        <meta name="description" content=""/>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          {content}
        </div>
      </div>

      <Footer/>
    </div>
  );
};

PasswordResetTriggerPage.propTypes = {};

PasswordResetTriggerPage.defaultProps = {};

export default injectIntl(PasswordResetTriggerPage);
