const ITEM_MEASUREMENT_UNIT = {
  kilograms: {
    name: 'KILOGRAMS',
    shorthandTranslation: 'item.measurmeent.unit.shorthand.KILOGRAMS',
  },
  litres: {
    name: 'LITRES',
    shorthandTranslation: 'item.measurmeent.unit.shorthand.LITRES',
  },
  units: {
    name: 'UNITS',
    shorthandTranslation: 'item.measurmeent.unit.shorthand.UNITS',
  },
};

export default ITEM_MEASUREMENT_UNIT;
