import React from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';

function AuthenticatedRoute({ currentUser, requiresFullProfile, ...props }) {
  if (!currentUser) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { redirectUrl: props.location.pathname, search: props.location.search },
        }}
      />
    );
  } else if (
    requiresFullProfile &&
    (!currentUser.address || !currentUser.address.street || !currentUser.phoneNumber) &&
    !currentUser.activeBusinessProfile
  ) {
    return (
      <Redirect
        to={{
          pathname: '/kohaletoimetamine',
          state: { redirectUrl: props.location.pathname, search: props.location.search },
        }}
      />
    );
  } else {
    return <Route {...props} />;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
