import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';
import GeneralForm from '../components/generalForm';
import { setCurrentUser } from './currentUserActions';
import GooglePlacesField from '../components/customFields/googlePlacesField';
import Helmet from 'react-helmet';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import CustomSelect from '../components/customFields/customSelect';
import { useServiceAreas } from '../cart/apiHooks';

function UserForm({ currentUser, intl, history, setCurrentUser, isOnboarding, location, selectedServiceCountry }) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(currentUser);
  const [avatar, setAvatar] = useState(currentUser.avatar);

  const { serviceAreas } = useServiceAreas({
    country: selectedServiceCountry?.countryAlpha2Code,
  });

  const serviceAreaOptions = serviceAreas
    ? serviceAreas.map((area) => {
        return {
          value: area.id,
          label: `${area.name}`,
        };
      })
    : [];

  const formSchema = {
    type: 'object',
    required: [],
    properties: {
      firstName: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.firstName.label' }),
      },
      lastName: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.lastName.label' }),
      },
      contact: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.contact.label' }),
      },
      phoneNumber: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.phone.number.label' }),
      },
      serviceAreaId: {
        type: 'string',
        title: intl.formatMessage({ id: 'user.form.field.service.area.label' }),
        options: serviceAreaOptions,
      },
      address: {
        type: 'object',
        required: [],
        properties: {
          unit: {
            type: 'string',
            default: '',
          },
          street: {
            type: 'string',
            default: '',
          },
          city: {
            type: 'string',
            default: '',
          },
          country: {
            type: 'string',
            default: '',
          },
          postCode: {
            type: 'string',
            default: '',
          },
          coordinates: {
            type: 'object',
            required: [],
            properties: {
              lat: {
                type: 'string',
              },
              lon: {
                type: 'string',
              },
            },
          },
        },
      },
      promotionalEmailsDisabled: {
        type: 'boolean',
        title: intl.formatMessage({ id: 'user.form.field.promotional.emails.disabled.label' }),
      },
    },
  };

  const uiSchema = {
    address: {
      'ui:field': 'googlePlacesField',
    },
    serviceAreaId: {
      'ui:field': 'customSelect',
      'ui:help': intl.formatMessage({ id: 'user.form.field.service.area.help' }),
    },
  };

  const uploadUserAvatar = (image) => {
    return apiCalls.uploadImage(image, 'user', user.id);
  };

  const deleteUserAvatar = (image) => {
    return apiCalls.deleteImage(image);
  };

  const onUserAvatarChange = (uploadedImages) => {
    setAvatar(uploadedImages[0]);
  };

  const onFormSubmit = () => {
    setLoading(true);

    apiCalls
      .editUser({ user: user, image: avatar })
      .then((response) => {
        setCurrentUser(response.data.user);
        onFormSuccess(response.data.user);
      })
      .catch((error) => {
        setErrorMessage(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
        window.scrollTo(0, 0);
      });
  };

  const onFormSuccess = (user) => {
    const redirectUrl = location.state ? location.state.redirectUrl : null;

    if (redirectUrl) {
      history.push({
        pathname: redirectUrl,
      });
    } else {
      history.push({
        pathname: '/',
      });
    }
  };

  const formFields = {
    googlePlacesField: GooglePlacesField,
    customSelect: CustomSelect,
  };

  const buttonCopy = 'user.edit.form.submit';

  const images = avatar ? [avatar] : [];

  const header = !isOnboarding ? intl.formatMessage({ id: 'user.edit.title' }) : undefined;

  const userForm = user ? (
    <GeneralForm
      title={header}
      onChange={(event) => setUser(event.formData)}
      onSubmit={onFormSubmit}
      uploadImage={uploadUserAvatar}
      deleteImage={deleteUserAvatar}
      onImagesChange={onUserAvatarChange}
      formSchema={formSchema}
      formUiSchema={uiSchema}
      formFields={formFields}
      formData={user}
      uploadedImages={images}
      buttonCopy={buttonCopy}
      errorMessage={errorMessage}
      loading={loading}
      hideImageUpload={isOnboarding}
      singleImage={true}
    />
  ) : (
    ''
  );

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'user.form.helmet.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2">
            {isOnboarding ? (
              <>
                <h2>
                  <FormattedMessage id={'user.onboarding.title'} />
                </h2>
                <p>
                  <FormattedMessage id={'user.onboarding.description'} />
                </p>
              </>
            ) : (
              ''
            )}

            {userForm}
          </div>
        </div>
      </div>
    </div>
  );
}

UserForm.propTypes = {
  onboard: PropTypes.bool,
};

UserForm.defaultProps = {
  onboard: false,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

const mapDispatchToProps = {
  setCurrentUser,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserForm));
