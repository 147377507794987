import { combineReducers } from 'redux';
import currentUser from "./user/currentUserReducer";
import ctaGroups from "./ctaGroup/ctaGroupReducer";
import marketplaces from "./marketplace/marketplacesReducer";
import marketplace from "./marketplace/marketplaceReducer";
import deliveryDayMarketplaces from "./deliveryDayMarketplaces/deliveryDayMarketplacesReducer";
import cart from "./cart/cartReducer";
import paymentMethods from "./paymentMethods/paymentMethodsReducer";
import orderBatches from "./order/orderBatchesReducer";
import serviceAreas from "./serviceAreas/serviceAreasReducer";
import marketplaceItems from "./ctaGroup/marketplaceItemReducer";

export default combineReducers({
  cart,
  deliveryDayMarketplaces,
  marketplaces,
  marketplace,
  ctaGroups,
  currentUser,
  paymentMethods,
  orderBatches,
  serviceAreas,
  marketplaceItems,
});
