import React, { useEffect } from 'react';
import Loader from '../components/loader';

const HelpPage = () => {
  useEffect(() => {
    window.location.replace(
      'https://healthy-promotion-e73.notion.site/Korduma-kippuvad-k-simused-1526e6bb1a904ca6bb8a49ae5ed32a6e'
    );
  }, []);

  return (
    <div className="content-page">
      <Loader show={true} />
    </div>
  );
};

HelpPage.propTypes = {};

HelpPage.defaultProps = {};

export default HelpPage;
