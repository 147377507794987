import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// TODO test failed card, failed 3DS, redirect, non-redirect
function NewPaymentMethodModalPaymentElement({ onSuccess, refreshSetupIntent }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const submit = async () => {
    if (!stripe || !elements) {
      return;
    }

    setError(null);
    setLoading(true);

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      refreshSetupIntent();
    } else {
      setLoading(false);
      onSuccess();
    }
  };

  return (
    <>
      <div className="m-b-3">
        <PaymentElement />
      </div>

      <div className="payment-method-modal__cta">
        <Loader show={loading} />
        <ErrorMessage error={error} />

        <button className="btn btn-primary" onClick={submit}>
          <FormattedMessage id="cart.new.payment.method.modal.cta" />
        </button>
      </div>
    </>
  );
}

NewPaymentMethodModalPaymentElement.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  refreshSetupIntent: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default withRouter(connect(mapStateToProps)(NewPaymentMethodModalPaymentElement));
