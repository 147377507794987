import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import utils from '../utils/utils';
import { useIntl } from 'react-intl';
import HorizontalScroll from '../components/horizontalScroll';
import { Context } from '../i18n/localeWrapper';

const ItemCategoriesScroll = ({ firstLevelCategories, hideSecondLevelCategories = false, match }) => {
  const intl = useIntl();

  const firstLevelCategory = match.params.firstLevelCategory
    ? firstLevelCategories.find(
        (firstLevel) => utils.convertToUrlString(firstLevel.name) === match.params.firstLevelCategory
      )
    : null;
  const slugifiedFirstLevelCategoryName = firstLevelCategory ? utils.convertToUrlString(firstLevelCategory.name) : null;

  const shopPath = match.path.includes('/business') ? '/shop/business' : '/shop';

  const localeContext = useContext(Context);

  const secondLevelCategoryContent = firstLevelCategory?.secondLevelCategories.map((secondLevelCategory) => {
    const slugifiedSecondLevelCategoryName = utils.convertToUrlString(secondLevelCategory.name);
    const categoryLink = `${shopPath}/${slugifiedFirstLevelCategoryName}/${slugifiedSecondLevelCategoryName}`;
    const isActive = slugifiedSecondLevelCategoryName === match.params.secondLevelCategory;

    return (
      <Link itemId={secondLevelCategory.name} key={secondLevelCategory.name} to={categoryLink}>
        <div className={`badge badge-light m-r-3 ${isActive ? 'm-active' : ''}`}>
          {utils.getSecondCategoryTranslation(secondLevelCategory, localeContext.locale, intl)}
        </div>
      </Link>
    );
  });

  return (
    <div className="item-categories m-b-3">
      {secondLevelCategoryContent && !hideSecondLevelCategories ? (
        <div className="item-categories__second-categories">
          <HorizontalScroll content={secondLevelCategoryContent} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

ItemCategoriesScroll.propTypes = {
  firstLevelCategories: PropTypes.array.isRequired,
};

export default withRouter(ItemCategoriesScroll);
