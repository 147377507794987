import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';

const OrderBatchViewOrder = ({ order, showIndividualDeliveryDay }) => {
  const deliveryDateWithTime = order.deliveryDate
    ? utils.getDeliveryRange(order.deliveryDate, DATE_FORMAT.displayWithTimeAndWeekday)
    : '';

  return (
    <div className="order-batch-view__order m-b-3">
      <h3 className="m-b-1">{order.marketplaceName}</h3>
      <div className="text-muted m-b-1">
        <small>
          <FormattedMessage
            id="order.batch.view.order.id"
            values={{
              id: order.id,
            }}
          />
        </small>
      </div>
      {showIndividualDeliveryDay ? (
        <div className="m-b-3">
          <small>
            {order.deliveryFrom ? (
              <FormattedMessage
                id="order.batch.view.order.delivery.with.time.range"
                values={{
                  deliveryRange: utils.getFormattedDateRangeFromStrings(order.deliveryFrom, order.deliveryTo),
                }}
              />
            ) : (
              <FormattedMessage
                id="order.batch.view.order.delivery"
                values={{
                  deliveryDate: deliveryDateWithTime,
                }}
              />
            )}
          </small>
        </div>
      ) : (
        ''
      )}
      {order.items.map((orderItem, index) => {
        return (
          <div key={index} className="row order-batch-view__order-item">
            <div className="col-8 col-sm-7">{orderItem.itemName}</div>
            <div className="col-4 col-sm-3 text-right order-sm-2">
              <span className="text-semi-bold">{utils.getRoundedPrice(orderItem.itemPrice * orderItem.quantity)}€</span>
            </div>
            <div className="col-12 col-sm-2 order-sm-1 order-batch-view__order-item-quantity">
              <FormattedMessage
                id="order.batch.view.order.quantity"
                values={{
                  quantity: orderItem.quantity,
                }}
              />
            </div>
          </div>
        );
      })}
      <hr />
    </div>
  );
};

OrderBatchViewOrder.propTypes = {
  order: PropTypes.object.isRequired,
  showIndividualDeliveryDay: PropTypes.bool,
};

OrderBatchViewOrder.defaultProps = {
  showIndividualDeliveryDay: true,
};

export default withRouter(injectIntl(OrderBatchViewOrder));
