import apiCalls from '../utils/apiCalls';
import utils from "../utils/utils";

export const MARKETPLACES_GET_BEGIN   = 'MARKETPLACES_GET_BEGIN';
export const MARKETPLACES_GET_SUCCESS = 'MARKETPLACES_GET_SUCCESS';
export const MARKETPLACES_GET_FAILURE = 'MARKETPLACES_GET_FAILURE';
export const MARKETPLACE_SET_SUCCESS = 'MARKETPLACE_SET_SUCCESS';

export const marketplacesGetBegin = () => ({
  type: MARKETPLACES_GET_BEGIN
});

export const marketplacesGetSuccess = marketplaces => ({
  type: MARKETPLACES_GET_SUCCESS,
  payload: { marketplaces }
});

export const marketplacesGetFailure = error => ({
  type: MARKETPLACES_GET_FAILURE,
  payload: { error }
});

export const marketplaceSetSuccess = marketplace => ({
  type: MARKETPLACE_SET_SUCCESS,
  payload: { marketplace }
});

export const setMarketplace = marketplace => {
  return dispatch => {
    dispatch(marketplaceSetSuccess(marketplace));
  };
};

export const getMarketplaces = params => {
  return dispatch => {
    dispatch(marketplacesGetBegin());
    return apiCalls.getMarketplaces(params)
      .then(res => res.data)
      .then(data => {
        dispatch(marketplacesGetSuccess(data.marketplaces));
        return data.marketplaces;
      })
      .catch(error => {
        dispatch(marketplacesGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};