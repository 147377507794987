import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { addItemToCart, removeItemFromCart } from '../cart/cartActions';
import { connect } from 'react-redux';
import NavbarRightCartPopup from './navbarRightCartPopup';
import { Context } from '../i18n/localeWrapper';
import SERVICE_COUNTRIES from '../components/constants/serviceCountries';
import { setSelectedServiceCountry } from '../serviceAreas/serviceAreasActions';

const NavbarRight = ({
  isXsNavOpen,
  setIsXsNavOpen,
  currentUser,
  openLoginModal,
  logout,
  setLang,
  selectedServiceCountry,
  setSelectedServiceCountry,
}) => {
  const [showCountrySelector, setShowCountrySelector] = useState(false);

  const { formatMessage, messages } = useIntl();

  const localeContext = useContext(Context);

  const navbarXsIcon = isXsNavOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />;

  const userLinks = currentUser ? (
    <>
      <li className="navbar__right-link m-with-avatar m-hover-user-popup list-inline-item">
        <button className="navbar__right-link-link btn btn-normal-link navbar__right-link-element">
          {currentUser.activeBusinessProfile != null ? (
            <>
              <div className="navbar__right-link-initial text-center d-inline-block">
                {currentUser.activeBusinessProfile.companyName[0]}
              </div>
              <span className="navbar__right-link-username hidden-sm">
                {currentUser.activeBusinessProfile.companyName}
              </span>
            </>
          ) : (
            <>
              <img className="navbar__right-link-avatar" alt="avatar" src={currentUser.avatar.thumbnailImage} />
              <span className="navbar__right-link-username hidden-sm">
                {currentUser.firstName ? currentUser.firstName : currentUser.screenName}
              </span>
            </>
          )}
          <span className="navbar__right-link-caret">
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </button>
        <div className="navbar__right-link-popup m-user-popup">
          <div className="navbar__right-link-popup-user">
            {currentUser.activeBusinessProfile != null ? (
              <>
                <div className="navbar__right-link-popup-user-image text-center">
                  {currentUser.activeBusinessProfile.companyName[0]}
                </div>

                <div className="navbar__right-link-popup-user-content">
                  <p className="navbar__right-link-popup-user-content-name m-b-0">
                    {currentUser.activeBusinessProfile.companyName}
                  </p>
                  <p className="navbar__right-link-popup-user-content-email m-b-1">
                    <small>{currentUser.activeBusinessProfile.registryCode}</small>
                  </p>
                </div>
              </>
            ) : (
              <>
                <img src={currentUser.avatar.thumbnailImage} alt="User avatar" />

                <div className="navbar__right-link-popup-user-content">
                  <p className="navbar__right-link-popup-user-content-name m-b-0">
                    {currentUser.firstName ? currentUser.firstName : currentUser.screenName}
                  </p>
                  <p className="navbar__right-link-popup-user-content-email m-b-1">
                    <small>{currentUser.email}</small>
                  </p>
                </div>
              </>
            )}
          </div>
          <NavLink to="/minu-tellimused" className="m-popup-user-link">
            <FormattedMessage id="navbar.links.my.orders" />
          </NavLink>
          <NavLink className="m-popup-user-link" activeClassName="active" to="/muuda-profiili">
            <FormattedMessage id={'navbar.links.edit.profile'} />
          </NavLink>
          <NavLink className="m-popup-user-link" activeClassName="active" to="/business-accounts">
            <FormattedMessage id={'navbar.links.business.profiles'} />
          </NavLink>
          <button className="btn btn-normal-link m-popup-user-link" onClick={logout}>
            <FormattedMessage id={'navbar.links.log.out'} />
          </button>
          <div className="navbar__right-link-popup-arrow" />
        </div>
      </li>
    </>
  ) : (
    <>
      <li className="navbar__right-link list-inline-item">
        <button
          className="btn btn-normal-link navbar-button-link m-normal-button navbar__right-link-element"
          onClick={() => openLoginModal('login')}
        >
          <FormattedMessage id={'navbar.links.log.in'} />
        </button>
      </li>
      <li className="navbar__right-link list-inline-item">
        <button
          className="btn btn-normal-link navbar-button-link m-normal-button navbar__right-link-element"
          onClick={() => openLoginModal('signup')}
        >
          <FormattedMessage id={'navbar.links.sign.up'} />
        </button>
      </li>
    </>
  );

  const languages = [
    {
      code: 'en',
      flag: '🇬🇧',
      name: 'English',
    },
    {
      code: 'et',
      flag: '🇪🇪',
      name: 'Estonian',
    },
  ];

  const selectedCountryName = selectedServiceCountry
    ? messages[`country.${selectedServiceCountry.countryAlpha2Code}`]
      ? formatMessage({ id: `country.${selectedServiceCountry.countryAlpha2Code}` })
      : selectedServiceCountry.name
    : '';

  const countrySelector = (
    <li
      className={`navbar__right-link m-hover-info-popup list-inline-item ${showCountrySelector ? 'm-visible' : ''}`}
      onClick={() => setShowCountrySelector(!showCountrySelector)}
    >
      <span className="navbar__right-link-element">
        {selectedServiceCountry ? (
          `${selectedServiceCountry.flagEmoji} ${selectedCountryName}`
        ) : (
          <FormattedMessage id={'navbar.links.country.select'} />
        )}{' '}
        <FontAwesomeIcon icon={faCaretDown} />
      </span>
      <div className="navbar__right-link-popup m-info-popup">
        <h4>
          <FormattedMessage id={'navbar.links.country.select.popup.header.country'} />
        </h4>
        {SERVICE_COUNTRIES.map((country) => {
          const countryName = messages[`country.${country.countryAlpha2Code}`]
            ? formatMessage({ id: `country.${country.countryAlpha2Code}` })
            : country.name;
          return (
            <button
              key={country.countryAlpha2Code}
              onClick={(e) => {
                setSelectedServiceCountry(country);
                setShowCountrySelector(false);
                e.stopPropagation();
              }}
              className={`btn btn-normal-link m-popup-user-link d-block ${
                selectedServiceCountry?.countryAlpha2Code === country.countryAlpha2Code ? 'active' : ''
              }`}
            >
              {country.flagEmoji} {countryName}
            </button>
          );
        })}
        <h4 className="m-t-2">
          <FormattedMessage id={'navbar.links.country.select.popup.header.language'} />
        </h4>
        {languages.map((lang) => {
          const langName = messages[`lang.${lang.code}`] ? formatMessage({ id: `lang.${lang.code}` }) : lang.name;
          return (
            <button
              key={lang.code}
              onClick={(e) => {
                setLang(lang.code);
                setShowCountrySelector(false);
                e.stopPropagation();
              }}
              className={`btn btn-normal-link m-popup-user-link ${localeContext.locale === lang.code ? 'active' : ''}`}
            >
              {lang.flag} {langName}
            </button>
          );
        })}
        <div className="navbar__right-link-popup-arrow" />
      </div>
    </li>
  );

  return (
    <div className="navbar__right d-none">
      <ul className="navbar__right list-inline list-unstyled d-none d-lg-block">
        {countrySelector}
        <li className="navbar__right-link list-inline-item">
          <Link to="/subscription" className="navbar__right-link-element">
            <FormattedMessage id="navbar.links.subscription" />
          </Link>
        </li>
        <li className="navbar__right-link list-inline-item">
          <Link to="/shop" className="navbar__right-link-element">
            <FormattedMessage id="navbar.links.shop" />
          </Link>
        </li>
        <li className="navbar__right-link list-inline-item">
          <NavLink className="navbar__right-link-element" activeClassName="active" to="/business">
            <FormattedMessage id="navbar.links.business.customers" />
          </NavLink>
        </li>
        <li className="navbar__right-link list-inline-item">
          <a
            className="navbar__right-link-element"
            href="https://healthy-promotion-e73.notion.site/Korduma-kippuvad-k-simused-1526e6bb1a904ca6bb8a49ae5ed32a6e"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="navbar.links.faq" />
          </a>
        </li>
        {userLinks}
        <NavbarRightCartPopup />
      </ul>

      <ul className="navbar__right list-inline list-unstyled d-block d-lg-none">
        {countrySelector}

        <NavbarRightCartPopup />

        <li className="navbar__right-link list-inline-item">
          <div className="navbar__right-link-toggle" onClick={() => setIsXsNavOpen(!isXsNavOpen)}>
            {navbarXsIcon}
          </div>
        </li>
      </ul>
    </div>
  );
};

NavbarRight.propTypes = {
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  setIsXsNavOpen: PropTypes.func.isRequired,
  setLang: PropTypes.func.isRequired,
  isXsNavOpen: PropTypes.bool,
};

NavbarRight.defaultProps = {
  isXsNavOpen: false,
  showAuctionsDropdown: false,
};

const mapDispatchToProps = {
  addItemToCart,
  removeItemFromCart,
  setSelectedServiceCountry,
};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentUser: state.currentUser.user,
    selectedServiceCountry: state.serviceAreas.selectedServiceCountry,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarRight);
