import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import { withRouter } from 'react-router-dom';
import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';
import { getPaymentMethods } from '../paymentMethods/paymentMethodsActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import NewPaymentMethodModal from '../cart/newPaymentMethodModal';
import { Modal } from 'react-bootstrap';
import PaymentMethod from '../components/paymentMethod';
import PaymentMethodSelectionModal from '../cart/paymentMethodSelectionModal';
import { useSubscription } from './apiHooks';

const PAYMENT_STATUSES_REQUIRING_ACTION = ['requires_confirmation', 'requires_action', 'requires_capture'];

const SubscriptionConfirmPage = ({ history, selectedPaymentMethod, match }) => {
  const stripe = useStripe();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [showPaymentMethodSelection, setShowPaymentMethodSelection] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    subscription,
    isLoading: subscriptionLoading,
    isError: isSubscriptionError,
    error: subscriptionError,
  } = useSubscription({ subscriptionId: match.params.subscriptionId });

  useEffect(() => {
    if (subscription) {
      checkPaymentConfirmation(subscription);
    }
    // eslint-disable-next-line
  }, [subscription]);

  function checkPaymentConfirmation(subscription) {
    if (!subscription.latestPayment) {
      return;
    }

    setLoading(true);
    const payment = subscription.latestPayment;

    if (!PAYMENT_STATUSES_REQUIRING_ACTION.includes(payment.paymentIntentStatus)) {
      success();
      return;
    }

    confirmPayment(payment);
  }

  function confirmPayment(payment) {
    stripe
      .confirmCardPayment(payment.clientSecret, {
        payment_method: payment.paymentMethodId,
      })
      .then(function (result) {
        if (result.error) {
          // let the user solve errors in the subscription page in Stripe, not here
          history.push({
            pathname: '/subscription',
          });
        } else {
          apiCalls.getPayment({ paymentId: payment.id, refresh: true }).then((result) => success());
        }
      });
  }

  function success() {
    setLoading(false);
    history.push({
      pathname: '/subscription',
    });
  }

  function retryPayment() {
    if (!subscription.latestPayment) {
      return;
    }

    setLoading(true);
    const payment = subscription.latestPayment;

    apiCalls
      .createPayment({
        paymentId: payment.id,
        payment: {
          paymentMethodId: selectedPaymentMethod.stripePaymentMethodId,
        },
      })
      .then((result) => {
        confirmPayment(result.data.payment);
      })
      .catch((error) => {
        setError(utils.getErrorMessageFromResponse(error, true));
        setLoading(false);
      });
  }

  return (
    <div className="content-page">
      <Helmet>
        <title>{formatMessage({ id: 'subscription.confirm.title' })}</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="subscription.confirm.title" />
          </h1>
          <Loader show={loading || subscriptionLoading} />
          {error || isSubscriptionError ? (
            <ErrorMessage error={utils.getErrorMessageFromResponse(error || subscriptionError)} />
          ) : (
            ''
          )}

          {!loading && !subscriptionLoading ? (
            <>
              <div className="m-b-6">
                {selectedPaymentMethod ? (
                  <>
                    <div onClick={() => setShowPaymentMethodSelection(true)}>
                      <PaymentMethod paymentMethod={selectedPaymentMethod} hidePrimaryNotice={true} />
                    </div>
                    <div>
                      <button className="btn btn-normal-link" onClick={() => setShowPaymentMethodSelection(true)}>
                        <FormattedMessage id="cart.payment.choose.another" />
                      </button>
                    </div>
                  </>
                ) : (
                  ''
                )}

                {!selectedPaymentMethod ? (
                  <button onClick={() => setShowNewPaymentModal(true)} className="btn btn-normal-link">
                    <FontAwesomeIcon icon={faCreditCard} /> <FormattedMessage id="cart.payment.add.card" />
                  </button>
                ) : (
                  ''
                )}
              </div>

              {selectedPaymentMethod ? (
                <div className="text-center">
                  <button className="btn btn-primary" onClick={retryPayment}>
                    <FormattedMessage id={'subscription.confirm.cta'} />
                  </button>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>

      <Footer />

      <Modal show={showNewPaymentModal} onHide={() => setShowNewPaymentModal(false)}>
        <div onClick={() => setShowNewPaymentModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <NewPaymentMethodModal closeModal={() => setShowNewPaymentModal(false)} onSuccess={() => setError(null)} />
      </Modal>

      <Modal show={showPaymentMethodSelection} onHide={() => setShowPaymentMethodSelection(false)}>
        <div onClick={() => setShowPaymentMethodSelection(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        <PaymentMethodSelectionModal
          closeModal={() => setShowPaymentMethodSelection(false)}
          openNewMethodModal={() => {
            setShowPaymentMethodSelection(false);
            setShowNewPaymentModal(true);
          }}
        />
      </Modal>
    </div>
  );
};

SubscriptionConfirmPage.propTypes = {};

SubscriptionConfirmPage.defaultProps = {};

const mapDispatchToProps = {
  getPaymentMethods,
};

function mapStateToProps(state) {
  return {
    cart: state.cart.cart,
    currentUser: state.currentUser.user,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(SubscriptionConfirmPage)));
