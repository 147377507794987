import React from 'react';
import { FormattedMessage } from 'react-intl';
import Geosuggest from 'react-geosuggest';
import COUNTRIES from '../constants/countries';
import CustomSelect from './customSelect';

class GooglePlacesField extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.props.formData
      ? this.props.formData
      : {
          customerName: '',
          phoneNumber: '',
          unit: '',
          street: '',
          city: '',
          country: '',
          postCode: '',
          coordinates: {
            lat: '',
            lon: '',
          },
        };

    this.handleLocationSelect = this.handleLocationSelect.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onStreetChange = this.onStreetChange.bind(this);
    this.onServiceAreaChange = this.onServiceAreaChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps.formData);
  }

  onChange(name) {
    return (event) => {
      this.setState(
        {
          [name]: event.target.value,
        },
        () => this.props.onChange(this.state)
      );
    };
  }

  onStreetChange(value) {
    this.setState(
      {
        street: value,
      },
      () => this.props.onChange(this.state)
    );
  }

  onServiceAreaChange(value) {
    this.setState(
      {
        serviceAreaId: value,
      },
      () => this.props.onChange(this.state)
    );
  }

  onCountryChange(country) {
    this.setState({ country }, () => this.props.onChange(this.state));
  }

  handleLocationSelect(suggest) {
    if (!suggest) {
      return;
    }

    const addressProps = this.getAddressPropsFromAddressComponents(suggest);

    this.setState(
      {
        street: suggest.label,
        city: addressProps.city,
        country: addressProps.country,
        postCode: addressProps.postCode,
        coordinates: {
          lat: suggest.location.lat ? suggest.location.lat.toString() : '',
          lon: suggest.location.lng ? suggest.location.lng.toString() : '',
        },
      },
      () => this.props.onChange(this.state)
    );
  }

  getAddressPropsFromAddressComponents(suggest) {
    const addressProperties = {
      city: '',
      postCode: '',
      country: '',
    };

    const addressComponents = suggest.gmaps.address_components;

    for (let i = 0; i < addressComponents.length; i++) {
      const component = addressComponents[i];
      const addressType = component.types[0];

      switch (addressType) {
        case 'postal_town':
        case 'locality':
          addressProperties.city = component.long_name;
          break;
        case 'postal_code':
          addressProperties.postCode = component.long_name;
          break;
        case 'country':
          addressProperties.country = component.short_name;
          break;
        default:
          break;
      }
    }

    return addressProperties;
  }

  render() {
    const country = this.state.country ? COUNTRIES.find((c) => c.code === this.state.country.toLowerCase()) : '';

    return (
      <fieldset className={this.props.schema.noMarginTop ? 'm-no-margin-top' : ''}>
        {!this.props.schema.hideLegend ? (
          <legend>{this.props.schema.title || <FormattedMessage id={'form.legend.address'} />}</legend>
        ) : (
          ''
        )}
        <div className="row">
          {this.props.schema.showCustomerFields ? (
            <>
              <div className="col-12">
                <div className="form-group field field-string">
                  <label className="control-label">
                    <FormattedMessage id={'form.field.customer.name.label'} />
                  </label>
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={this.state.customerName}
                    onChange={this.onChange('customerName')}
                  />
                  <p className="help-block">
                    <FormattedMessage id={'form.field.customer.name.help'} />
                  </p>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group field field-string">
                  <label className="control-label">
                    <FormattedMessage id={'form.field.phone.number.label'} />
                  </label>
                  <input
                    className="form-control"
                    placeholder=""
                    type="text"
                    value={this.state.phoneNumber}
                    onChange={this.onChange('phoneNumber')}
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {this.props.schema.properties.serviceAreaId ? (
            <div className="col-12">
              <div className="form-group field field-string">
                <label className="control-label">
                  <FormattedMessage id={'form.field.service.area.id.label'} />
                </label>
                <div className="m-b-2">
                  <CustomSelect
                    onChange={this.onServiceAreaChange}
                    schema={{
                      title: '',
                      options: this.props.schema.properties.serviceAreaId.options,
                    }}
                    formData={this.state.serviceAreaId}
                  />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="col-12">
            <div className="form-group field field-string">
              <label className="control-label">
                <FormattedMessage id={'form.field.street.label'} />
              </label>
              <Geosuggest
                autoComplete="off"
                placeholder=""
                inputClassName="form-control"
                onChange={this.onStreetChange}
                onSuggestSelect={this.handleLocationSelect}
                initialValue={this.state.street}
              />
              <p className="help-block">
                <FormattedMessage id={'form.field.street.help'} />
              </p>
              {this.state.street && !/\d/.test(this.state.street) ? (
                <p className="alert alert-warning">
                  <FormattedMessage id={'form.field.street.warning.no.number'} />
                </p>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className="col-12">
            <div className="form-group field field-string">
              <label className="control-label">
                <FormattedMessage id={'form.field.unit.label'} />
              </label>
              <input
                className="form-control"
                placeholder=""
                type="text"
                value={this.state.unit}
                onChange={this.onChange('unit')}
              />
              <p className="help-block">
                <FormattedMessage id={'form.field.unit.help'} />
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-4">
            <div className="form-group field field-string">
              <label className="control-label">
                <FormattedMessage id={'form.field.city.label'} />
              </label>
              <p className="m-b-0">
                <strong>{this.state.city}</strong>
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-4">
            <div className="form-group field field-string">
              <label className="control-label">
                <FormattedMessage id={'form.field.country.label'} />
              </label>
              <p className="m-b-0">
                <strong>{country ? country.name : ''}</strong>
              </p>
            </div>
          </div>

          <div className="col-12 col-sm-4">
            <div className="form-group field field-string">
              <label className="control-label">
                <FormattedMessage id={'form.field.post.code.label'} />
              </label>
              <p className="m-b-0">
                <strong>{this.state.postCode}</strong>
              </p>
            </div>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default GooglePlacesField;
