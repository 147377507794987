import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Footer from '../components/footer';
import circlePurple from '../landing/assets/widgets/tomato.svg';
import circleGreen from '../landing/assets/widgets/bread.svg';
import image from './assets/freshFruits/main.jpg';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const BlogFreshFruits = ({ intl }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paragraphs = [
    {
      paragraphs: ['fresh.fruits.p.intro.1', 'fresh.fruits.p.intro.2'],
    },
    {
      image: image,
    },
    {
      title: 'fresh.fruits.p.1.title',
      paragraphs: ['fresh.fruits.p.1.description.1', 'fresh.fruits.p.1.description.2'],
    },
    {
      title: 'fresh.fruits.p.2.title',
      paragraphs: ['fresh.fruits.p.2.description.1'],
    },
    {
      smallTitle: 'fresh.fruits.p.3.title',
      paragraphs: [
        'fresh.fruits.p.3.description.1',
        'fresh.fruits.p.3.description.2',
        'fresh.fruits.p.3.description.3',
      ],
    },
    {
      smallTitle: 'fresh.fruits.p.4.title',
      paragraphs: ['fresh.fruits.p.4.description.1', 'fresh.fruits.p.4.description.2'],
    },
    {
      smallTitle: 'fresh.fruits.p.5.title',
      paragraphs: [
        'fresh.fruits.p.5.description.1',
        'fresh.fruits.p.5.description.2',
        'fresh.fruits.p.5.description.3',
      ],
    },
    {
      smallTitle: 'fresh.fruits.p.6.title',
      paragraphs: ['fresh.fruits.p.6.description.1', 'fresh.fruits.p.6.description.2'],
    },
    {
      title: 'fresh.fruits.p.7.title',
      paragraphs: ['fresh.fruits.p.7.description.1'],
    },
  ];

  return (
    <div className="content-page">
      <Helmet>
        <title>{intl.formatMessage({ id: 'fresh.fruits.helmet.title' })}</title>
        <meta name="description" content={intl.formatMessage({ id: 'fresh.fruits.helmet.description' })} />
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt="" />
      <img className="content-page__circle-green" src={circleGreen} alt="" />

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1 className="m-b-6">
            <FormattedMessage id="fresh.fruits.title" />
          </h1>

          {paragraphs.map((paragraph, index) => (
            <div key={index} className="m-b-6">
              {paragraph.image ? (
                <div className="row m-b-6">
                  <div className="col-12">
                    <img src={paragraph.image} alt="" className="img-fluid img-border-radius" />
                  </div>
                </div>
              ) : (
                ''
              )}
              {paragraph.title ? (
                <h3 className="text-primary">
                  <strong>
                    <FormattedMessage id={paragraph.title} />
                  </strong>
                  <br />
                </h3>
              ) : (
                ''
              )}
              {paragraph.smallTitle ? (
                <h4 className="text-primary">
                  <strong>
                    <FormattedMessage id={paragraph.smallTitle} />
                  </strong>
                  <br />
                </h4>
              ) : (
                ''
              )}
              {paragraph.paragraphs
                ? paragraph.paragraphs.map((p) => (
                    <p>
                      <FormattedMessage id={p} />
                    </p>
                  ))
                : ''}
            </div>
          ))}

          <div className="text-center">
            <Link to="/shop/fruits_vegetables/fruit" className="btn btn-primary">
              <FormattedMessage id="fresh.fruits.cta" />
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

BlogFreshFruits.propTypes = {};

BlogFreshFruits.defaultProps = {};

export default injectIntl(BlogFreshFruits);
