import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import utils from '../utils/utils';
import DATE_FORMAT from '../components/constants/dateFormat';
import PropTypes from 'prop-types';
import OrderBatchViewOrder from './orderBatchViewOrder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import AddressModal from '../cart/addressModal';
import { Modal } from 'react-bootstrap';
import { getOrderBatches } from './orderBatchesActions';
import { connect } from 'react-redux';
import OrderInvoicePaymentStatus from '../components/orderInvoicePaymentStatus';

const OrderBatchViewContent = ({ orderBatch, orders, getOrderBatches, currentUser }) => {
  const [editAddress, setEditAddress] = useState(null);

  const dateCreated = utils.getFormattedDateFromString(orderBatch.dateCreated, DATE_FORMAT.displayWithTimeAndWeekday);

  const uniqueDeliveryDayIds = orders
    .map((o) => o.deliveryDayId)
    .filter((value, index, self) => self.indexOf(value) === index);

  const hasSingleDeliveryDay = uniqueDeliveryDayIds.length === 1;

  const paymentStatus =
    orderBatch.payment.status === 'COMPLETED' ? (
      ''
    ) : (
      <div className="text-danger">
        <FormattedMessage
          id="order.batch.view.payment.status.needs.action"
          values={{
            id: orderBatch.payment.id,
            link: (
              <Link className="link" to={`/maksa-uuesti/${orderBatch.payment.id}`}>
                <FormattedMessage id="order.batch.view.payment.status.needs.action.pay.again" />
              </Link>
            ),
          }}
        />
      </div>
    );

  const invoicePaymentStatus = orderBatch.payment.invoicePaymentStatus ? (
    <div className="m-b-2">
      <OrderInvoicePaymentStatus invoicePaymentStatus={orderBatch.payment.invoicePaymentStatus} />
    </div>
  ) : (
    ''
  );

  return (
    <div className="order-batch-view">
      <h1 className="m-b-2">
        <FormattedMessage
          id="order.batch.view.title"
          values={{
            id: orderBatch.id,
          }}
        />
      </h1>
      <div className="text-muted m-b-2">
        <small>
          <FormattedMessage
            id="order.batch.view.date"
            values={{
              date: dateCreated,
            }}
          />
        </small>
      </div>
      {invoicePaymentStatus}
      {hasSingleDeliveryDay ? (
        <div className="m-b-2">
          {orders[0].deliveryFrom ? (
            <FormattedMessage
              id="order.batch.view.order.delivery.with.time.range"
              values={{
                deliveryRange: utils.getFormattedDateRangeFromStrings(orders[0].deliveryFrom, orders[0].deliveryTo),
              }}
            />
          ) : (
            <FormattedMessage
              id="order.batch.view.order.delivery"
              values={{
                deliveryDate: utils.getDeliveryRange(orders[0].deliveryDate, DATE_FORMAT.displayWithTimeAndWeekday),
              }}
            />
          )}
        </div>
      ) : (
        ''
      )}
      {orderBatch.address ? (
        <div className="">
          <div className="m-b-2">
            <FormattedMessage
              id="order.batch.view.order.delivery.address"
              values={{
                address: orderBatch.address.street,
              }}
            />
          </div>
          <button onClick={() => setEditAddress(orderBatch.address)} className="btn btn-small btn-default">
            <FormattedMessage id="order.batch.view.order.delivery.address.edit" />
          </button>
        </div>
      ) : (
        ''
      )}
      {orderBatch.note ? (
        <div className="m-t-2">
          <FormattedMessage
            id="order.batch.view.order.note"
            values={{
              note: orderBatch.note,
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div className="m-t-3 m-b-6">{paymentStatus}</div>
      {orders.map((order, index) => {
        return <OrderBatchViewOrder order={order} showIndividualDeliveryDay={!hasSingleDeliveryDay} />;
      })}
      {orderBatch.coupon ? (
        <div className="text-right text-success">
          <FormattedMessage
            id="my.orders.order.coupon.discount.percent"
            values={{
              code: orderBatch.coupon.code,
              sum:
                orderBatch.coupon.discountPercent != null ? (
                  <strong className="text-h4">-{orderBatch.coupon.discountPercent}%</strong>
                ) : (
                  <strong className="text-h4">-{orderBatch.coupon.discountSum}€</strong>
                ),
            }}
          />
        </div>
      ) : (
        ''
      )}
      <div className="text-right text-muted">
        <FormattedMessage
          id="my.orders.order.delivery.fee"
          values={{
            sum: <strong className="text-h4">{orderBatch.deliveryFee}€</strong>,
          }}
        />
      </div>
      <div className="text-right">
        <FormattedMessage
          id="my.orders.order.total.sum"
          values={{
            sum: <strong className="text-h3">{orderBatch.finalSum}€</strong>,
          }}
        />
      </div>

      <Modal show={!!editAddress} onHide={() => setEditAddress(null)}>
        <div onClick={() => setEditAddress(null)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </div>

        {editAddress ? (
          <AddressModal
            existingAddress={editAddress}
            isOrderBatchAddress={true}
            onSuccess={() => {
              setEditAddress(null);
              getOrderBatches({ userId: currentUser.id });
            }}
          />
        ) : (
          ''
        )}
      </Modal>
    </div>
  );
};

OrderBatchViewContent.propTypes = {
  orderBatch: PropTypes.object.isRequired,
  orders: PropTypes.array,
};

OrderBatchViewContent.defaultProps = {};

const mapDispatchToProps = {
  getOrderBatches,
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderBatchViewContent)));
