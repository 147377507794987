import React from 'react';

const Loader = ({ show }) => show? (
  <div className="text-center loader">
    <div className="spinner" />
  </div>
): '';

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;