import {
  LOGIN_POST_BEGIN,
  LOGIN_POST_SUCCESS,
  LOGIN_POST_FAILURE,
  FB_LOGIN_POST_BEGIN,
  FB_LOGIN_POST_SUCCESS,
  FB_LOGIN_POST_FAILURE,
  AUTH_REFRESH_BEGIN,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  CURRENT_USER_GET_BEGIN,
  CURRENT_USER_GET_SUCCESS,
  CURRENT_USER_GET_FAILURE,
  LOGIN_MODAL_TRIGGER_SUCCESS,
  AUTH_REFRESH_CANCELLED,
} from './currentUserActions';

const initialState = {
  authenticationToken: null,
  user: null,
  loading: true,
  error: null,
  authLoading: false,
  authError: null,
  loginSignupModalTab: null,
};

const identifyUser = (user) => {
  if (window.analytics) {
    window.analytics.identify(user.id);
  }
};

export default function currentUserReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_POST_BEGIN:
    case FB_LOGIN_POST_BEGIN:
      return {
        ...state,
        authLoading: true,
        authError: null,
      };
    case AUTH_REFRESH_SUCCESS:
    case LOGIN_POST_SUCCESS:
    case FB_LOGIN_POST_SUCCESS:
      return {
        ...state,
        authLoading: false,
        authenticationToken: action.payload.authenticationToken,
      };
    case LOGIN_POST_FAILURE:
    case FB_LOGIN_POST_FAILURE:
      return {
        ...state,
        authenticationToken: null,
        authLoading: false,
        authError: action.payload.error,
      };
    case AUTH_REFRESH_BEGIN:
      return {
        ...state,
        authLoading: true,
        authError: null,
      };
    case AUTH_REFRESH_FAILURE:
      return {
        ...state,
        authenticationToken: null,
        authLoading: false,
      };
    case AUTH_REFRESH_CANCELLED:
      return {
        ...state,
        loading: false,
      };
    case CURRENT_USER_GET_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CURRENT_USER_GET_SUCCESS:
      identifyUser(action.payload.user);

      return {
        ...state,
        loading: false,
        user: action.payload.user,
      };
    case CURRENT_USER_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        user: null,
      };
    case LOGIN_MODAL_TRIGGER_SUCCESS:
      return {
        ...state,
        loginSignupModalTab: action.payload.loginSignupModalTab,
      };
    default:
      return state;
  }
}
