import React, { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Loader from '../components/loader';
import ErrorMessage from '../components/errorMessage';
import Helmet from 'react-helmet';
import { useDeliveryDayMarketplaces, useItems, useMarketplaces } from './apiHooks';
import utils from '../utils/utils';
import MarketplacesPreview from '../components/marketplacesPreview';
import ItemCategoriesScroll from './itemCategoriesScroll';
import { connect } from 'react-redux';
import ShopItemsThirdLevelCategories from './shopItemsThirdLevelCategories';
import { Link } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../i18n/localeWrapper';

const ShopItemsSecondLevelCategory = ({ match, categories, deliveryDayMarketplaces, cart }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [match.params.secondLevelCategory, match.params.firstLevelCategory]);

  const intl = useIntl();

  const firstLevelCategory =
    categories && match.params.firstLevelCategory
      ? categories.find((firstLevel) => utils.convertToUrlString(firstLevel.name) === match.params.firstLevelCategory)
      : null;

  const secondLevelCategory =
    categories && match.params.secondLevelCategory
      ? categories
          .map((firstLevel) => firstLevel.secondLevelCategories)
          .flat()
          .find((secondLevel) => utils.convertToUrlString(secondLevel.name) === match.params.secondLevelCategory)
      : null;

  const {
    isLoading: loading,
    items,
    isError,
    error,
  } = useItems({
    secondLevelCategory: secondLevelCategory?.name,
    business: match?.path?.includes('/business/'),
  });

  const {
    marketplaces,
    isLoading: marketplacesLoading,
    isError: isMarketplacesError,
    error: marketplacesError,
  } = useMarketplaces({
    itemFirstLevelCategory: firstLevelCategory?.name,
    itemSecondLevelCategory: secondLevelCategory?.name,
  });

  const {
    deliveryDayMarketplaces: marketplaceDeliveryDayMarketplaces,
    isLoading: ddmLoading,
    isError: isDdmError,
    error: ddmError,
  } = useDeliveryDayMarketplaces({
    marketplaceIds: marketplaces?.map((mp) => mp.id).join(','),
  });

  const cartDeliveryDays = deliveryDayMarketplaces
    ? utils.getDeliveryDaysFromCart(cart, deliveryDayMarketplaces)
    : null;

  const notAvailableItems =
    items && cartDeliveryDays?.length && marketplaceDeliveryDayMarketplaces
      ? items.filter((item) => {
          const marketplaceId = utils.getItemMarketplace(item)?.marketplaceId;
          if (!marketplaceId) {
            return false;
          }

          return !cartDeliveryDays.some((cartDeliveryDay) =>
            marketplaceDeliveryDayMarketplaces.find(
              (ddm) => ddm.marketplaceId === marketplaceId && ddm.deliveryDayId === cartDeliveryDay.deliveryDayId
            )
          );
        })
      : null;

  const availableItems = notAvailableItems?.length
    ? items.filter((i) => !notAvailableItems.find((nai) => nai.id === i.id))
    : items;

  const localeContext = useContext(Context);

  const secondLevelCategoryTranslation = secondLevelCategory
    ? utils.getSecondCategoryTranslation(secondLevelCategory, localeContext.locale, intl)
    : null;

  const thirdLevelCategories = categories?.reduce((result, firstLevelCategory) => {
    return result.concat(
      firstLevelCategory.secondLevelCategories.reduce(
        (result, secondLevelCategory) => result.concat(secondLevelCategory.thirdLevelCategories),
        []
      )
    );
  }, []);

  const mobileBackLink = firstLevelCategory ? `/shop/${utils.convertToUrlString(firstLevelCategory.name)}` : '/shop';

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: 'shop.items.helmet.title.with.category' },
            { category: secondLevelCategoryTranslation }
          )}
        </title>
      </Helmet>

      <Loader show={loading || marketplacesLoading || ddmLoading} />
      {isError || isMarketplacesError || isDdmError ? (
        <ErrorMessage error={utils.getErrorMessageFromResponse(error || marketplacesError || ddmError)} />
      ) : (
        ''
      )}

      {secondLevelCategory ? <h2 className="m-b-3">{secondLevelCategoryTranslation}</h2> : ''}

      {categories ? (
        <div className="m-b-6">
          <ItemCategoriesScroll firstLevelCategories={categories} />
        </div>
      ) : (
        ''
      )}

      {availableItems?.length ? (
        <ShopItemsThirdLevelCategories
          items={availableItems}
          thirdLevelCategories={thirdLevelCategories}
          deliveryDayMarketplaces={marketplaceDeliveryDayMarketplaces}
        />
      ) : items && !items.length && secondLevelCategory?.name === 'BOX_FRUITS_VEGETABLES' ? (
        <p>
          <FormattedMessage id="shop.items.items.fruits.vegetables.empty" />
        </p>
      ) : items && !items.length ? (
        <p>
          <FormattedMessage id="shop.items.items.empty" />
        </p>
      ) : (
        ''
      )}

      {notAvailableItems?.length ? (
        <>
          <hr className="m-b-6 m-t-6" />

          <h3>
            <FormattedMessage id="shop.items.items.for.next.delivery.day.title" />
          </h3>
          <p>
            <FormattedMessage id="shop.items.items.for.next.delivery.day.description" />
          </p>

          <ShopItemsThirdLevelCategories
            items={notAvailableItems}
            thirdLevelCategories={thirdLevelCategories}
            deliveryDayMarketplaces={marketplaceDeliveryDayMarketplaces}
            showNextDeliveryDate={true}
          />
        </>
      ) : (
        ''
      )}

      {marketplaces?.length ? (
        <div className="m-b-6">
          <h3>
            <FormattedMessage id="shop.items.marketplaces.title" />
          </h3>
          <MarketplacesPreview marketplaces={marketplaces} />
        </div>
      ) : (
        ''
      )}

      {categories && items?.length > 4 ? <ItemCategoriesScroll firstLevelCategories={categories} /> : ''}

      <Link className="shop-items__second-level-back-button d-lg-none" to={mobileBackLink}>
        <FontAwesomeIcon icon={faArrowLeft} /> <FormattedMessage id={'shop.items.index.show.all.categories'} />
      </Link>
    </>
  );
};

function mapStateToProps(state) {
  return {
    deliveryDayMarketplaces: state.deliveryDayMarketplaces.deliveryDayMarketplaces,
    cart: state.cart.cart,
  };
}

export default connect(mapStateToProps)(ShopItemsSecondLevelCategory);
