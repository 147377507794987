import React, {useEffect, useState} from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import Footer from "../components/footer";
import circlePurple from "../landing/assets/widgets/tomato.svg";
import circleGreen from "../landing/assets/widgets/bread.svg";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import {useStripe} from "@stripe/react-stripe-js";
import Loader from "../components/loader";
import ErrorMessage from "../components/errorMessage";
import {withRouter} from "react-router-dom";
import apiCalls from "../utils/apiCalls";
import utils from "../utils/utils";
import {getPaymentMethods} from "../paymentMethods/paymentMethodsActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faTimes} from "@fortawesome/free-solid-svg-icons";
import NewPaymentMethodModal from "../cart/newPaymentMethodModal";
import {Modal} from "react-bootstrap";
import PaymentMethod from "../components/paymentMethod";
import PaymentMethodSelectionModal from "../cart/paymentMethodSelectionModal";

const PaymentConfirmPage = ({history, intl, selectedPaymentMethod, getPaymentMethods, paymentMethods, match,
                            currentUser}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!paymentMethods) {
      getPaymentMethods()
    }
  }, [paymentMethods, getPaymentMethods]);

  useEffect(() => {
    getPayment();
    // eslint-disable-next-line
  }, [match.params.paymentId]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [payment, setPayment] = useState(null);
  const [showNewPaymentModal, setShowNewPaymentModal] = useState(false);
  const [showPaymentMethodSelection, setShowPaymentMethodSelection] = useState(false);
  const stripe = useStripe();

  function getPayment() {
    apiCalls.getPayment({
      paymentId: match.params.paymentId,
      refresh: true,
    }).then((result) => {
      const pmnt = result.data.payments[0];
      setPayment(pmnt);
      if (pmnt.status === 'COMPLETED') {
        success();
      }
    }).catch((error) => {
      setError(utils.getErrorMessageFromResponse(error, true));
      setLoading(false);
    });
  }

  function confirmPayment(payment) {
    stripe.confirmCardPayment(payment.clientSecret, {
      payment_method: payment.paymentMethodId,
    })
      .then(function(result) {
        if (result.error) {
          if (result.error.message) {
            setError(intl.formatMessage({id: 'payment.confirm.error.generic.with.message'}, {message: result.error.message}));
          } else {
            setError(intl.formatMessage({id: 'payment.confirm.error.generic'}));
          }
          deletePaymentMethod(payment);
        } else {
          apiCalls.getPayment({paymentId: payment.id, refresh: true}).then(result => success());
        }
      });
  }

  function deletePaymentMethod(cardPayment) {
    if (!selectedPaymentMethod || selectedPaymentMethod.stripePaymentMethodId !== cardPayment.paymentMethodId) {
      return;
    }

    apiCalls.deletePaymentMethod(selectedPaymentMethod).then((result) => {
      getPaymentMethods({userId: currentUser.id}).then((result) => {
        setLoading(false);
      })
        .catch(error => {
          setError(utils.getErrorMessageFromResponse(error, true));
          setLoading(false);
        });
    }).catch((error) => {
      setError(utils.getErrorMessageFromResponse(error, true));
      setLoading(false);
    });
  }

  function success() {
    setLoading(false);
    history.push({
      pathname: '/jee'
    });
  }

  function retryPayment() {
    if (!payment) {
      return;
    }

    setLoading(true);

    apiCalls.createPayment({
      paymentId: payment.id,
      payment: {
        paymentMethodId: selectedPaymentMethod.stripePaymentMethodId,
      }
    }).then((result) => {
      confirmPayment(result.data.payment);
    }).catch((error) => {
      setError(utils.getErrorMessageFromResponse(error, true));
      setLoading(false);
    });
  }

  return (
    <div className="content-page">
      <Helmet>
        <title>{ intl.formatMessage({id: 'payment.confirm.title'}) }</title>
      </Helmet>

      <img className="content-page__circle-purple" src={circlePurple} alt=""/>
      <img className="content-page__circle-green" src={circleGreen} alt=""/>

      <div className="container content-page__container">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h1>
            <FormattedMessage id="payment.confirm.title"/>
          </h1>
          <p className="m-b-6">
            <FormattedMessage id="payment.confirm.description"/>
          </p>
          <Loader show={loading}/>
          <ErrorMessage error={error}/>

          {!loading? (
            <>
              <div className="m-b-6">
                {selectedPaymentMethod? (
                  <>
                    <div onClick={() => setShowPaymentMethodSelection(true)}>
                      <PaymentMethod paymentMethod={selectedPaymentMethod} hidePrimaryNotice={true}/>
                    </div>
                    <div>
                      <button className="btn btn-normal-link" onClick={() => setShowPaymentMethodSelection(true)}>
                        <FormattedMessage id="cart.payment.choose.another"/>
                      </button>
                    </div>
                  </>
                ): ''}

                {!selectedPaymentMethod? (
                  <button onClick={() => setShowNewPaymentModal(true)} className="btn btn-normal-link">
                    <FontAwesomeIcon icon={faCreditCard}/> <FormattedMessage id="cart.payment.add.card"/>
                  </button>
                ) : ''}
              </div>

              {selectedPaymentMethod? (
                <div className="text-center">
                  <button className="btn btn-primary" onClick={retryPayment}>
                    <FormattedMessage id={'payment.confirm.cta'}/>
                  </button>
                </div>): ''
              }
            </>
          ): ''}
        </div>
      </div>

      <Footer/>

      <Modal show={showNewPaymentModal} onHide={() => setShowNewPaymentModal(false)}>
        <div onClick={() => setShowNewPaymentModal(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg"/>
        </div>

        <NewPaymentMethodModal closeModal={() => setShowNewPaymentModal(false)} onSuccess={() => setError(null)}/>
      </Modal>

      <Modal show={showPaymentMethodSelection} onHide={() => setShowPaymentMethodSelection(false)}>
        <div onClick={() => setShowPaymentMethodSelection(false)} className="b-modal-close">
          <FontAwesomeIcon icon={faTimes} size="lg"/>
        </div>

        <PaymentMethodSelectionModal closeModal={() => setShowPaymentMethodSelection(false)} openNewMethodModal={() => {
          setShowPaymentMethodSelection(false);
          setShowNewPaymentModal(true);
        }}/>
      </Modal>
    </div>
  );
};

PaymentConfirmPage.propTypes = {};

PaymentConfirmPage.defaultProps = {};

const mapDispatchToProps = {
  getPaymentMethods
};

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser.user,
    paymentMethods: state.paymentMethods.paymentMethods,
    selectedPaymentMethod: state.paymentMethods.selectedPaymentMethod,
  };
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmPage)));
