import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Helmet from 'react-helmet';
import Footer from '../components/footer';
import pakk1 from './assets/gifts/pakk1.jpg';
import pakk2 from './assets/gifts/pakk2.jpg';
import pakk3 from './assets/gifts/pakk3.jpg';
import pakk4 from './assets/gifts/pakk4.jpg';
import pakk5 from './assets/gifts/pakk5.jpg';

const Gifts = () => {
  const scroll = () => {
    const section = document.querySelector('#contactForm');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const { formatMessage } = useIntl();

  return (
    <div className="landing">
      <Helmet>
        <title>{formatMessage({ id: 'gifts.helmet.title' })}</title>
        <meta name="description" content={formatMessage({ id: 'gifts.first.description' })} />
      </Helmet>

      <div className="container-fluid landing__first m-gifts text-center">
        <div className="row row-eq-height">
          <div className="col-12 col-md-6 landing__first-right order-md-2 m-office" />
          <div className="col-12 col-md-6 landing__first-left align-self-center">
            <div className="landing__first-left-content text-left">
              <h1 className="landing__first-left-content-title">
                <FormattedMessage id={'gifts.first.title'} />
              </h1>
              <p className="landing__first-left-content-description">
                <FormattedMessage id={'gifts.first.description'} />
              </p>

              <div className="landing__first-left-content-cta">
                <button className="btn btn-secondary" onClick={scroll}>
                  <FormattedMessage id={'gifts.first.cta'} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center">
              <div className="landing__basket-image">
                <img src={pakk1} alt="Gift pack 1" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>Jõulupakk #1</h2>
                <p className="text-large">
                  Mesikuma maitsemesi vanilli, kurkumi ja ingveriga 230g, Myr Chai Taimetee “Õhtu rahu” 20g, Bett’r Mahe
                  pralinee mandlivõi täidisega 36g, Täkumetsa Piparkook 80g, Nudist Winery Mõttetu glögi - vürtsidega
                  mustsõstravein/Täiesti mõttetu glögi - alkoholivaba musta kirsi jõulujook 0.75l, Mahe klementiin 2kg
                </p>
                <p className="text-large">
                  <strong>
                    25.15€ <small>(+ KM)</small>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket m-b-6">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center order-lg-1">
              <div className="landing__basket-image m-left-padding">
                <img src={pakk2} alt="Gift pack 2" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>Jõulupakk #2</h2>
                <p className="text-large">Mahe klementiini kast, 5kg</p>
                <p className="text-large">
                  <strong>
                    12.80€ <small>(+ KM)</small>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center">
              <div className="landing__basket-image">
                <img src={pakk3} alt="Gift pack 1" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>Jõulupakk #3</h2>
                <p className="text-large">Mahe puuviljakast, ~3.5kg</p>
                <p className="text-large">
                  <strong>
                    15.42€ <small>(+ KM)</small>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket m-b-6">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center order-lg-1">
              <div className="landing__basket-image m-left-padding">
                <img src={pakk4} alt="Gift pack 2" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>Jõulupakk #4</h2>
                <p className="text-large">Mahe apelsini kast, 5kg</p>
                <p className="text-large">
                  <strong>
                    8.75€ <small>(+ KM)</small>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__basket">
        <div className="container">
          <div className="row">
            <div className="col-10 offset-1 offset-lg-0 col-lg-7 align-self-center">
              <div className="landing__basket-image">
                <img src={pakk5} alt="Gift pack 1" className="img-fluid" />
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <div className="landing__basket-content">
                <h2>Jõulupakk #5</h2>
                <p className="text-large">
                  Bett’r Mahe pralinee mandlivõi täidisega 39g, Bett’r Mahe vahvel sarapuupähkli ja kakaokreemiga 30g,
                  Nudist Winery Rabarbra rabarberivahuvein 0.75l, Mahe pähklite ja rosinate segu 250g, Mahe puuviljad
                  2kg (mahe hurmaa, mahe klementiin, mahe granaatõun, mahe kiivi)
                </p>
                <p className="text-large">
                  <strong>
                    25€ <small>(+ KM)</small>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="landing__contact m-t-6" id="contactForm">
        <div className="container">
          <h2 className="text-center">
            <FormattedMessage id="gifts.contact.title" />
          </h2>
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <p className="m-b-6 text-center text-large">
                <FormattedMessage id="gifts.contact.description" />
              </p>
            </div>
          </div>

          <iframe
            title="producer-form-iframe"
            src="https://docs.google.com/forms/d/e/1FAIpQLSc9jqQMYVfw379wh-nj-McYzfowE9VrYioUga6ctNisp0jkOQ/viewform?embedded=true"
            width="100%"
            height="1500"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
      </div>

      <Footer hideBadges={true} />
    </div>
  );
};

export default Gifts;
