import apiCalls from '../utils/apiCalls';
import utils from "../utils/utils";

export const PAYMENT_METHODS_GET_BEGIN   = 'PAYMENT_METHODS_GET_BEGIN';
export const PAYMENT_METHODS_GET_SUCCESS = 'PAYMENT_METHODS_GET_SUCCESS';
export const PAYMENT_METHODS_GET_FAILURE = 'PAYMENT_METHODS_GET_FAILURE';
export const SELECTED_PAYMENT_METHOD_SET_SUCCESS = 'SELECTED_PAYMENT_METHOD_SET_SUCCESS';

export const paymentMethodsGetBegin = () => ({
  type: PAYMENT_METHODS_GET_BEGIN
});

export const paymentMethodsGetSuccess = paymentMethods => ({
  type: PAYMENT_METHODS_GET_SUCCESS,
  payload: { paymentMethods }
});

export const paymentMethodsGetFailure = error => ({
  type: PAYMENT_METHODS_GET_FAILURE,
  payload: { error }
});

export const selectedPaymentMethodSetSuccess = paymentMethod => ({
  type: SELECTED_PAYMENT_METHOD_SET_SUCCESS,
  payload: { paymentMethod }
});

export const setSelectedPaymentMethod = paymentMethod => {
  return dispatch => {
    dispatch(selectedPaymentMethodSetSuccess(paymentMethod));
  };
};

export const getPaymentMethods = params => {
  return dispatch => {
    dispatch(paymentMethodsGetBegin());
    return apiCalls.getPaymentMethods(params)
      .then(res => res.data)
      .then(data => {
        dispatch(paymentMethodsGetSuccess(data.paymentMethods));
        return data.paymentMethods;
      })
      .catch(error => {
        dispatch(paymentMethodsGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};