import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);

  return (
    <span
      onClick={() => {
        scrollPrev();
      }}
      className={`horizontal-scroll-arrow m-left ${isFirstItemVisible ? 'd-none' : ''}`}
    >
      <FontAwesomeIcon size="lg" icon={faArrowCircleLeft} />
    </span>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <span
      onClick={() => {
        scrollNext();
      }}
      className={`horizontal-scroll-arrow m-right ${isLastItemVisible ? 'd-none' : ''}`}
    >
      <FontAwesomeIcon size="lg" icon={faArrowCircleRight} />
    </span>
  );
}

const HorizontalScroll = ({ content }) => {
  return (
    <div className="membo-horizontal-scroll m-b-3">
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onWheel={() => {}}>
        {content}
      </ScrollMenu>
      <span className="membo-scroll-fade" />
    </div>
  );
};

export default HorizontalScroll;
