import apiCalls from '../utils/apiCalls';
import utils from '../utils/utils';

export const CART_REFRESH_SUCCESS = 'CART_REFRESH_SUCCESS';
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_REMOVE_SUCCESS = 'CART_REMOVE_SUCCESS';
export const CART_MARKETPLACE_REMOVE_SUCCESS = 'CART_MARKETPLACE_REMOVE_SUCCESS';
export const CART_RESET_SUCCESS = 'CART_RESET_SUCCESS';
export const PRICE_QUOTE_GET_BEGIN = 'PRICE_QUOTE_GET_BEGIN';
export const PRICE_QUOTE_GET_SUCCESS = 'PRICE_QUOTE_GET_SUCCESS';
export const PRICE_QUOTE_GET_FAILURE = 'PRICE_QUOTE_GET_FAILURE';
export const COUPON_CODE_SET_SUCCESS = 'COUPON_CODE_SET_SUCCESS';
export const USE_PROFILE_ADDRESS_SET_SUCCESS = 'USE_PROFILE_ADDRESS_SET_SUCCESS';
export const ADDRESS_SET_SUCCESS = 'ADDRESS_SET_SUCCESS';
export const NOTE_SET_SUCCESS = 'NOTE_SET_SUCCESS';

export const cartRefreshSuccess = (cart) => ({
  type: CART_REFRESH_SUCCESS,
  payload: { cart },
});

export const cartAddSuccess = (item, marketplace) => ({
  type: CART_ADD_SUCCESS,
  payload: { item, marketplace },
});

export const cartRemoveSuccess = (item, marketplaceId) => ({
  type: CART_REMOVE_SUCCESS,
  payload: { item, marketplaceId },
});

export const cartResetSuccess = () => ({
  type: CART_RESET_SUCCESS,
});

export const cartMarketplaceRemoveSuccess = (marketplace) => ({
  type: CART_MARKETPLACE_REMOVE_SUCCESS,
  payload: { marketplace },
});

export const priceQuoteGetBegin = () => ({
  type: PRICE_QUOTE_GET_BEGIN,
});

export const priceQuoteGetSuccess = (priceQuote) => ({
  type: PRICE_QUOTE_GET_SUCCESS,
  payload: { priceQuote },
});

export const priceQuoteGetFailure = (error) => ({
  type: PRICE_QUOTE_GET_FAILURE,
  payload: { error },
});

export const couponCodeSetSuccess = (couponCode) => ({
  type: COUPON_CODE_SET_SUCCESS,
  payload: { couponCode },
});

export const useProfileAddressSetSuccess = (useProfileAddress) => ({
  type: USE_PROFILE_ADDRESS_SET_SUCCESS,
  payload: { useProfileAddress },
});

export const addressSetSuccess = (address) => ({
  type: ADDRESS_SET_SUCCESS,
  payload: { address },
});

export const noteSetSuccess = (note) => ({
  type: NOTE_SET_SUCCESS,
  payload: { note },
});

export const resetCart = () => {
  return (dispatch) => {
    dispatch(cartResetSuccess());
  };
};

export const addItemToCart = (item, marketplace) => {
  return (dispatch) => {
    dispatch(cartAddSuccess(item, marketplace));
  };
};

export const removeItemFromCart = (item, marketplaceId) => {
  return (dispatch) => {
    dispatch(cartRemoveSuccess(item, marketplaceId));
  };
};

export const removeMarketplaceFromCart = (marketplace) => {
  return (dispatch) => {
    dispatch(cartMarketplaceRemoveSuccess(marketplace));
  };
};

export const refreshCart = () => {
  return (dispatch) => {
    dispatch(cartRefreshSuccess());
  };
};

export const addCouponCode = (couponCode) => {
  return (dispatch) => {
    dispatch(couponCodeSetSuccess(couponCode));
  };
};

export const setUseProfileAddress = (useProfileAddress) => {
  return (dispatch) => {
    dispatch(useProfileAddressSetSuccess(useProfileAddress));
  };
};

export const setAddress = (address) => {
  return (dispatch) => {
    dispatch(addressSetSuccess(address));
  };
};

export const setNote = (note) => {
  return (dispatch) => {
    dispatch(noteSetSuccess(note));
  };
};

export const createPriceQuote = (params) => {
  return (dispatch) => {
    dispatch(priceQuoteGetBegin());
    return apiCalls
      .createPriceQuote(params)
      .then((res) => res.data)
      .then((data) => {
        dispatch(priceQuoteGetSuccess(data.priceQuote));
        return data.priceQuote;
      })
      .catch((error) => {
        dispatch(priceQuoteGetFailure(utils.getErrorMessageFromResponse(error, true)));
        throw error;
      });
  };
};
