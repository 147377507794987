import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import LoginForm from './loginForm';
import SignupForm from './signupForm';
import { connect } from 'react-redux';
import { fbLogin } from '../user/currentUserActions';
import { withRouter } from 'react-router-dom';
import fbIcon from '../assets/icons/facebook.svg';

const tabs = [
  {
    key: 'signup',
    title: <FormattedMessage id={'sign.up.header'} />,
    description: <FormattedMessage id={'sign.up.description'} />,
    descriptionLink: <FormattedMessage id={'sign.up.description.link'} />,
    buttonText: <FormattedMessage id={'sign.up.button'} />,
    facebookText: <FormattedMessage id={'sign.up.facebook'} />,
  },
  {
    key: 'login',
    title: <FormattedMessage id={'login.header'} />,
    description: <FormattedMessage id={'login.description'} />,
    descriptionLink: <FormattedMessage id={'login.description.link'} />,
    buttonText: <FormattedMessage id={'login.button'} />,
    facebookText: <FormattedMessage id={'login.facebook'} />,
  },
];

function LoginSignupContent({ history, onSuccess, redirectUrl, errorMessage, fbLogin, defaultTab }) {
  const [selectedTab, setSelectedTab] = useState(tabs.find((tab) => tab.key === defaultTab) || tabs[0]);

  const onFacebookLogin = (response) => {
    fbLogin(response).then(() => {
      history.push({
        pathname: redirectUrl,
      });
      localStorage.setItem('hasLoggedIn', true);
      onSuccess();
    });
  };

  const getPrivacyPolicyTerms = () => {
    return (
      <React.Fragment>
        <p className="m-b-3 text-center m-t-5">
          <small>
            <FormattedMessage
              id={'sign.up.agreement'}
              values={{
                termsOfUse: (
                  <a href="/kasutustingimused" className="link" target="_blank">
                    <FormattedMessage id={'sign.up.agreement.link.terms.of.use'} />
                  </a>
                ),
                privacyPolicy: (
                  <a href="/privaatsuspoliitika" className="link" target="_blank">
                    <FormattedMessage id={'sign.up.agreement.link.privacy.policy'} />
                  </a>
                ),
              }}
            />
          </small>
        </p>
      </React.Fragment>
    );
  };

  const error = errorMessage ? <p className="alert alert-error">{errorMessage}</p> : '';

  const privacyPolicyTermsOfUse = selectedTab.key === 'signup' ? getPrivacyPolicyTerms() : '';

  const form =
    selectedTab.key === 'signup' ? (
      <SignupForm redirectUrl={redirectUrl} onSuccess={onSuccess} />
    ) : (
      <LoginForm redirectUrl={redirectUrl} onSuccess={onSuccess} />
    );

  const isFacebookApp = () => {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
  };

  return (
    <div className="login-signup-content">
      <h2 className="text-center m-b-3">{selectedTab.title}</h2>
      <p className="text-center m-b-5">
        {selectedTab.description}{' '}
        <button
          onClick={() => setSelectedTab(tabs.find((tab) => tab.key !== selectedTab.key))}
          className="btn btn-normal-link login-modal__alternative"
        >
          {selectedTab.descriptionLink}
        </button>
      </p>

      {error}

      <div className="row">
        <div className="col-12">
          {selectedTab.key === 'login' ? (
            <>
              <div>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={false}
                  callback={onFacebookLogin}
                  disableMobileRedirect={!isFacebookApp()}
                  fields="first_name,last_name,name,email,picture,locale"
                  render={(renderProps) => (
                    <div onClick={renderProps.onClick} className="facebook-button facebook-register-button">
                      <img src={fbIcon} alt="Facebook icon" /> {selectedTab.facebookText}
                    </div>
                  )}
                />
              </div>

              <hr className="m-b-6 m-t-6" />
            </>
          ) : (
            ''
          )}

          {form}
        </div>
        <div className="col-12">{privacyPolicyTermsOfUse}</div>
      </div>
    </div>
  );
}

LoginSignupContent.propTypes = {
  defaultTab: PropTypes.string,
  communityId: PropTypes.number,
  redirectUrl: PropTypes.string,
  onSuccess: PropTypes.func,
};

LoginSignupContent.defaultProps = {
  defaultTab: localStorage.getItem('hasLoggedIn') ? 'login' : 'signup',
  redirectUrl: '/',
  onSuccess: () => {},
};

const mapDispatchToProps = {
  fbLogin,
};

function mapStateToProps(state) {
  return {
    loading: state.currentUser.authLoading,
    error: state.currentUser.authError,
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSignupContent));
