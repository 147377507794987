import React from 'react';
import PaymentMethodCard from './paymentMethodCard';
import PaymentMethodInvoice from './paymentMethodInvoice';

const PaymentMethod = ({ paymentMethod, hidePrimaryNotice }) => {
  if (paymentMethod.className === 'PaymentMethodInvoice') {
    return <PaymentMethodInvoice paymentMethod={paymentMethod} hidePrimaryNotice={hidePrimaryNotice} />;
  } else {
    return <PaymentMethodCard paymentMethod={paymentMethod} hidePrimaryNotice={hidePrimaryNotice} />;
  }
};

PaymentMethod.propTypes = {};

PaymentMethod.defaultProps = {};

export default PaymentMethod;
