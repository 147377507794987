import React from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { generatePath, Redirect, Route, Switch } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Navbar from './navbar/navbar';
import WhyPage from './pages/why';
import WinterSwim from './landing/winterSwim';
import ProducerPage from './pages/producer';
import Analytics from 'react-router-ga';
import withTracker from './components/withTracker';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
import { Provider } from 'react-redux';
import MarketplaceViewPage from './marketplace/marketplaceView';
import CtaGroupsPage from './ctaGroup/ctaGroups';
import TopJanuaryPage from './pages/top-january';
import CartPage from './cart/cart';
import AuthenticatedRoute from './components/authenticatedRoute';
import UserForm from './user/userForm';
import LoginSignupPage from './loginSignup/loginSignup';
import OrdersConfirmPage from './order/ordersConfirm';
import OrderSuccessPage from './order/orderSuccess';
import PaymentConfirmPage from './order/paymentConfirm';
import PasswordResetTriggerPage from './password-reset/passwordResetTrigger';
import PasswordResetPage from './password-reset/passwordReset';
import MyOrdersPage from './order/myOrders';
import OrderBatchViewPage from './order/orderBatchView';
import BlogFruitsFromSpain from './pages/fruitsFromSpain';
import ReactPixel from 'react-facebook-pixel';
import HowItWorksPage from './pages/howItWorks';
import Segment from 'react-segment';
import LocaleWrapper from './i18n/localeWrapper';
import BlogFreshFruits from './pages/freshFruits';
import TermsAndConditionsPage from './pages/termsAndConditions';
import PrivacyPolicyPage from './pages/privacyPolicy';
import { QueryClient, QueryClientProvider } from 'react-query';
import GiftCardsPage from './pages/giftCards';
import HelpPage from './pages/help';
import AppDownloadPage from './pages/appDownload';
import BusinessProfilesPage from './businessProfiles/businessProfiles';
import BusinessProfileForm from './businessProfiles/businessProfileForm';
import ShopItemsPage from './shop/shopItems';
import Office from './office/business';
import MaintenancePage from './landing/maintenancePage';
import Gifts from './landing/gifts';
import Landing from './landing/landing';
import SubscriptionPage from './subscription/subscription';
import SubscriptionPaymentConfirm from './subscription/subscriptionPaymentConfirm';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/et');
}
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/et');
}

const store = createStore(rootReducer, applyMiddleware(thunk));
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

ReactPixel.init('224227392399982', undefined, {
  autoConfig: true,
  debug: false,
});
ReactPixel.fbq('track', 'PageView');

Segment.default.load(process.env.REACT_APP_SEGMENT_KEY);

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <LocaleWrapper>
      <QueryClientProvider client={reactQueryClient}>
        <div className="App">
          <BrowserRouter>
            <Provider store={store}>
              <Analytics id="UA-123456789-0">
                <Elements stripe={stripePromise}>
                  <Navbar />
                  <div className="main">
                    <Switch>
                      <Route path="/maintenance" component={MaintenancePage} />
                      {/*<Redirect to="/maintenance"/>*/}
                      <Route path="/login" component={LoginSignupPage} />
                      <Route path="/unustasin-salasona" component={PasswordResetTriggerPage} />
                      <Route path="/uus-salasona" component={PasswordResetPage} />
                      <Route path="/taliujumine" component={withTracker(WinterSwim)} />
                      <Route path="/kuidas-tootab" component={withTracker(HowItWorksPage)} />
                      <Route path="/miks-membo" component={withTracker(WhyPage)} />
                      <Route path="/app" component={withTracker(AppDownloadPage)} />
                      <Route path="/top-jaanuar" component={withTracker(TopJanuaryPage)} />
                      <Route path="/tootjale" component={withTracker(ProducerPage)} />
                      <Route path="/kinkekaardid" component={withTracker(GiftCardsPage)} />
                      <Route path="/kasutustingimused" component={withTracker(TermsAndConditionsPage)} />
                      <Route path="/privaatsuspoliitika" component={withTracker(PrivacyPolicyPage)} />
                      <Route path="/blogi/viljad-hispaaniast" component={withTracker(BlogFruitsFromSpain)} />
                      <Route path="/blogi/membo-puuviljad" component={withTracker(BlogFreshFruits)} />
                      <Route path="/shop/business" component={withTracker(ShopItemsPage)} />
                      <Route path="/shop" render={() => <Redirect to="/tootja/74" />} />
                      <Route
                        path="/pood/:category/:marketplaceId"
                        render={(props) => (
                          <Redirect
                            to={generatePath('/tootja/:marketplaceId', {
                              marketplaceId: props.match.params.marketplaceId,
                            })}
                          />
                        )}
                      />
                      <Route path="/pood/:category?" component={withTracker(CtaGroupsPage)} />
                      <Route path="/tootja/:marketplaceId" component={withTracker(MarketplaceViewPage)} />
                      <Route path="/abi" component={withTracker(HelpPage)} />

                      <AuthenticatedRoute path="/muuda-profiili" render={(props) => <UserForm {...props} />} />
                      <AuthenticatedRoute
                        path="/kohaletoimetamine"
                        render={(props) => <UserForm isOnboarding={true} {...props} />}
                      />
                      <AuthenticatedRoute path="/makse-kinnitus" render={(props) => <OrdersConfirmPage />} />
                      <AuthenticatedRoute path="/minu-tellimused" render={(props) => <MyOrdersPage />} />
                      <AuthenticatedRoute path="/business-accounts" render={(props) => <BusinessProfilesPage />} />
                      <AuthenticatedRoute
                        path="/business-account/new"
                        render={(props) => <BusinessProfileForm action={'create'} {...props} />}
                      />
                      <AuthenticatedRoute
                        path="/business-account/:businessProfileId"
                        render={(props) => <BusinessProfileForm action={'edit'} {...props} />}
                      />
                      <AuthenticatedRoute path="/tellimus/:orderBatchId" render={(props) => <OrderBatchViewPage />} />
                      <AuthenticatedRoute path="/maksa-uuesti/:paymentId" render={(props) => <PaymentConfirmPage />} />
                      <AuthenticatedRoute path="/jee" render={(props) => <OrderSuccessPage />} />
                      <AuthenticatedRoute
                        requiresFullProfile={true}
                        path="/ostukorv"
                        render={(props) => <CartPage {...props} />}
                      />
                      <AuthenticatedRoute
                        requiresFullProfile={true}
                        path="/subscription"
                        render={(props) => <SubscriptionPage {...props} />}
                      />
                      <AuthenticatedRoute
                        path="/subscription-confirm/:subscriptionId"
                        render={(props) => <SubscriptionPaymentConfirm {...props} />}
                      />

                      <Route path="/business" component={withTracker(Office)} />
                      <Route path="/kingid" component={withTracker(Gifts)} />
                      <Route path="/" component={withTracker(Landing)} />
                      <Redirect to="/" />
                    </Switch>
                  </div>
                </Elements>
              </Analytics>
            </Provider>
          </BrowserRouter>
        </div>
      </QueryClientProvider>
    </LocaleWrapper>
  );
}

export default App;
